import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { PageOrigins, RouteLinkConstants } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { NavigationParamsService } from 'src/app/modules/shared/services/navigation-params-service';

@Component({
  selector: 'app-no-access',
  templateUrl: './no-access.component.html',
  styleUrls: ['./no-access.component.scss']
})
export class NoAccessComponent implements OnInit, OnDestroy {

  selectedAccountSubscription: Subscription;
  headerTitle = '';
  origin = '';
  navigationParams: any;

  constructor(private navigationParamsService: NavigationParamsService,
    private appStateService: AppStateService, public router: Router) { }

  ngOnInit(): void {
    this.navigationParamsService.registerNavigationParams();

    this.navigationParams = this.navigationParamsService.getNavigationParam();
    this.headerTitle = this.navigationParams?.title;
    this.origin = this.navigationParams?.origin;

    switch (this.origin) {
      case PageOrigins.monthlyFinancialIndicatorDetails:
        this.router.navigate(['', { outlets: { overlayOutlet: null } }]).then(() => {
          this.router.navigate([RouteLinkConstants.monthlyFinancialIndicatorDetails],
            { queryParams: this.navigationParams });
        });
        break;
    }
  }

  ngOnDestroy(): void {
    if (this.selectedAccountSubscription) { this.selectedAccountSubscription.unsubscribe(); }
  }
}
