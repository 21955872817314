import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { AccountUserActions } from 'src/app/modules/core/user-service/user.model';

@Injectable({
  providedIn: 'root',
})

export class UserPermissionService {

  constructor(private userDataService: UserDataService, private sharedStorageService: SharedStorageService, private appStateService: AppStateService) {
  }

  public loadAccountUserActions(accountId: string): void {
    const actions = this.sharedStorageService.getUserActions(accountId);
    if (actions && actions.length > 0) {
      this.appStateService.publishUserActions(actions);
    } else {
      this.loadUserActions(accountId);
    }
  }

  public loadUserActions(accountId: string): void {
    this.userDataService.getAccountWiseUserActions()
      .subscribe(userPermissions => {
        userPermissions.forEach(userPermission => {
          this.sharedStorageService.addUserActions(userPermission.accountId, userPermission.actionIds);
          this.sharedStorageService.addAccountRole(userPermission.accountId, userPermission.roleId);
          if (userPermission.accountId === accountId) {
            this.appStateService.publishUserActions(userPermission.actionIds);
            this.sharedStorageService.addRoleId(userPermission.roleId);
          }
        });
      });
  }

  public clearActionHistory(onboardedAccountIds: string[]): void {
    onboardedAccountIds.forEach(accountId => {
      this.sharedStorageService.clearActionHistory(accountId);
    });
  }

  public hasAccessToPage(requestedActionIds: number[]): Observable<boolean> {
    const accountId = this.sharedStorageService.getAccountId();
    let retValue = false;

    return this.getUserActionsByAccount(accountId).pipe(
      switchMap((actionIdsByAccount: number[]) => {
        requestedActionIds.forEach(actionId => {
          if (actionIdsByAccount?.indexOf(actionId) >= 0) {
            retValue = true;
          }
        });
        return of(retValue);
      })
    );
  }

  public getUserActionsByAccount(accountId: string): Observable<number[]> {
    let actionIdsByAccount = this.sharedStorageService.getUserActions(accountId);
    if (actionIdsByAccount && actionIdsByAccount.length > 0) {
      return of(actionIdsByAccount);
    } else {
      return this.userDataService.getAccountWiseUserActions().pipe(
        switchMap((userPermissions: AccountUserActions[]) => {
          const selectedUserPermissions = userPermissions.find(userPermission => userPermission.accountId === accountId);
          if (selectedUserPermissions) this.sharedStorageService.addRoleId(selectedUserPermissions.roleId);
          const userAccountActionIds = selectedUserPermissions?.actionIds;
          actionIdsByAccount = (userAccountActionIds) ? userAccountActionIds : [] as number[];
          this.sharedStorageService.addUserActions(accountId, actionIdsByAccount);
          return of(actionIdsByAccount);
        }), catchError(() => {
          return of([] as number[]);
        }));
    }
  }
}
