import { AfterViewInit, Component, NgZone, Inject, Input, OnChanges, OnDestroy, PLATFORM_ID, SimpleChanges } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { CategoryValueItem } from 'src/app/modules/data-visualization/models/category-value-item';
import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';
import { KeyValueColorDataItem } from 'src/app/modules/data-visualization/models/key-value-color-data-item';

@Component({
  selector: 'app-horizontal-bar-chart',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./../chart-base/chart-base.component.scss']
})
export class HorizontalBarChartComponent extends ChartBaseComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() chartData: CategoryValueItem[] = [];
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  renderChart(): void {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      var chart = am4core.create(this.chartId, am4charts.XYChart);

      chart.paddingRight = this.chartOptions.paddingRight;
      chart.paddingLeft = this.chartOptions.paddingLeft;
      chart.paddingBottom = this.chartOptions.paddingBottom;
      chart.paddingTop = this.chartOptions.paddingTop;

      super.chart = chart;
      super.setLocal();
      this.setData(chart);
      this.setChartColors(chart);

      this.setupChartAxes(chart);
      this.createSeries(chart);
    });
  }

  private createSeries(chart: am4charts.XYChart) {
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueX = 'value';
    series.dataFields.categoryY = "key";
    series.name = '';
    series.columns.template.tooltipText = this.chartOptions.toolTipTextFormat;
    if (series.tooltip) {
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
      series.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
      series.tooltipY = am4core.percent(50);
      series.tooltip.tooltipText = this.chartOptions.toolTipTextFormat;
    }

    series.columns.template.height = am4core.percent(100);
    series.sequencedInterpolation = true;
    series.columns.template.column.cornerRadiusTopRight = 5;
    series.columns.template.column.cornerRadiusBottomRight = 5;

    series.columns.template.adapter.add("fill", function (fill, target) {
      const dataItem =  target.dataItem?.dataContext as KeyValueColorDataItem;
      return am4core.color(dataItem.color);
    });

    series.columns.template.adapter.add("stroke", function (fill, target) {
      const dataItem =  target.dataItem?.dataContext as KeyValueColorDataItem;
      return am4core.color(dataItem.color);
    });
  }

  private setupChartAxes(chart: am4charts.XYChart): void {
    var categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "key";
    categoryAxis.renderer.inversed = true;
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.cellStartLocation = 0.1;
    categoryAxis.renderer.cellEndLocation = 0.9;
    categoryAxis.renderer.minGridDistance = 20;
    categoryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);
    if (!this.chartOptions.showGrid) {
      categoryAxis.renderer.grid.template.disabled = true;
    }


    var valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.opposite = false;
    if (!this.chartOptions.showGrid) {
      valueAxis.renderer.grid.template.disabled = true;
    }

    valueAxis.numberFormatter.numberFormat = '#a';
    super.setNumberFormatSuffixes(valueAxis);
    valueAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);
  }

  private setData(chart: am4charts.XYChart): void {
    const chartData = JSON.parse(JSON.stringify(this.chartData)) as KeyValueColorDataItem[];
    chart.data = chartData;
  }

  private setChartColors(chart: am4charts.XYChart): void {
    chart.data.forEach(i => {
      chart.colors.list.push(am4core.color(i.color));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      this.renderChart();
    }
  }

}
