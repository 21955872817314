export enum CfoRecommendationSubType {
    NOTIFICATION = "Notification",
    ADVICE = "Advice",
    MONTHLY_REPORT = "MonthlyReport",
    WELCOME = "WelcomeCard",
    COMPENSATION_TAX = "CompensationTax",
    WITHHOLDING_TAX = "WithHoldingTax",
    NO_ACCOUNTING_SYSTEM = "NoAccountingSystem",
    OTP = "Otp",
    EQUITY_WARNING = "EquityWarning",
    EQUITY_REACHING_ZERO = "EquityReachingZero",
    COMPENSATION_WITHHOLDING_NO_ACCOUNT_SYSTEM = "CompensationWithHoldingNoAccountSys",
    TRIGGER_BASED_ADVICE = "TriggerBasedAdvice",
    ADVANCE_TAX_FIRST_INSTALLMENT_NO_ACCOUNT_SYS = "AdvanceTaxFirstInstallmentNoAccountSys",
    ADVANCE_TAX_SECOND_INSTALLMENT_NO_ACCOUNT_SYS = "AdvanceTaxSecondInstallmentNoAccountSys",
    ADVANCE_TAX_FIRST_INSTALLMENT = "AdvanceTaxFirstInstallment",
    ADVANCE_TAX_SECOND_INSTALLMENT = "AdvanceTaxSecondInstallment",
    HOLIDAY_PAY_NO_ACCOUNT_SYS = "HolidayPayNoAccountSys",
    HOLIDAY_PAY = "HolidayPay",
    CHALLENGE = "Challenge",
    NO_BUDGETING = "NoBudgeting"
}