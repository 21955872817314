import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


import { RegistrationUser, B2CUser, AccountUserActions, IntegrationSystem, CapassaRole, AccountUserSystemRole, UserLoginHistory } from 'src/app/modules/core/user-service/user.model';
import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { environment } from 'src/environments/environment';
import { AgreementTypes } from 'src/app/modules/shared/globals';
import { Agreement } from 'src/app/modules/shared/models/agreement';
import { Invitation } from 'src/app/modules/shared/models/invitation';
import { UserPreference } from 'src/app/modules/shared/models/UserPreference';

@Injectable()
export class UserDataService {

  constructor(private api: CapassaApiService) { }

  getUser(userId: string): Observable<RegistrationUser> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/users/${userId}`);
  }

  saveRegistrationUser(user: RegistrationUser): Observable<RegistrationUser> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/user`, user);
  }

  updateRegistrationUser(user: RegistrationUser): Observable<RegistrationUser> {
    return this.api.getJsonApiService().put(`${environment.RegistrationApi}/v1.0/user`, user);
  }

  getRegistrationB2cUserById(id: string): Observable<B2CUser> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/b2cusers/${id}`);
  }

  saveRegistrationB2cUser(user: B2CUser): Observable<B2CUser> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/b2cuser`, user);
  }

  getRegistrationUser(userIdentifier: string): Observable<RegistrationUser> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/user?userIdentifier=${encodeURIComponent(userIdentifier)}`);
  }

  updateRegistrationB2cUser(user: B2CUser): Observable<B2CUser> {
    return this.api.getJsonApiService().put(`${environment.RegistrationApi}/v1.0/b2cuser`, user);
  }

  getIntegrations(): Observable<IntegrationSystem[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/integrations`);
  }

  getAccountWiseUserActions(): Observable<AccountUserActions[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/users/actions`);
  }

  getLatestAgreementByUser(userId: string, agreementType: AgreementTypes): Observable<Agreement> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/agreements/${userId}/${agreementType}`);
  }

  getCapassaRoles(): Observable<CapassaRole[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/role-permissions/roles`);
  }

  getUserTeam(): Observable<RegistrationUser[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/users`);
  }

  getInvitations(): Observable<Invitation[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/users/invited-list`);
  }

  postInvitation(invitation: Invitation): Observable<string> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/users/portal-invitation`, invitation);
  }

  updateUserRole(accountUserSystemRole: AccountUserSystemRole): Observable<B2CUser> {
    return this.api.getJsonApiService().put(`${environment.RegistrationApi}/v1.0/role-permissions/user-role`, accountUserSystemRole);
  }

  deleteUser(userId: string): Observable<string[]> {
    return this.api.getJsonApiService().delete(`${environment.RegistrationApi}/v1.0/users/${userId}`);
  }

  sendInvitation(invitation: Invitation): Observable<Invitation> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/users/send-invitation`, invitation);
  }

  postUserLoggedInTime(userLoginHistory: UserLoginHistory): Observable<UserLoginHistory> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/user/login-history`, userLoginHistory);
  }

  getUserRoles(userId: string): Observable<RegistrationUser> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/users/${userId}/roles`);
  }

  removeAccountingSystem(integrationId: string): Observable<string[]> {
    return this.api.getJsonApiService().delete(`${environment.RegistrationApi}/v1.0/integrations/${integrationId}`);
  }

  removeAccount(): Observable<string[]> {
    return this.api.getJsonApiService().delete(`${environment.RegistrationApi}/v1.0/account`);
  }

  deleteUserProfileImage(userId: string): Observable<string[]> {
    return this.api.getJsonApiService().delete(`${environment.RegistrationApi}/v1.0/user/user-profile-image/${userId}`);
  }

  updateUserPreference(userPreference: UserPreference): Observable<UserPreference> {
    return this.api.getJsonApiService().put(`${environment.RegistrationApi}/v1.0/user-preference/`, userPreference);
  }

  getAccountUserPreference(userId?: string): Observable<UserPreference[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/user-preference/${userId}`);
  }

  hasAccountIntegrated(): Observable<boolean> {
    return this.getIntegrations()
      .pipe(map((integrations: IntegrationSystem[]) => integrations.length > 0 ? true : false));
  }

  getUserPreferences(userId?: string): Observable<UserPreference[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/user-preferences/${userId}`);
  }
}
