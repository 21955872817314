import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';


@Injectable()
export class BrregDataService {

  constructor(private api: CapassaApiService) { }

  getOrganizationByName(companyName: string, pageNumber: number, pageSize: number): Observable<any> {
    return this.api.getBerrgApiService().get(`/enheter?navn=${companyName}&page=${pageNumber}&size=${pageSize}`);
  }

  getOrganizationByOrganizationNumber(companyNumber: string): Observable<any> {
    return this.api.getBerrgApiService().get(`/enheter/${companyNumber}`);
  }

  getRolesByOrganizationNumber(companyNumber: string): Observable<any> {
    return this.api.getBerrgApiService().get(`/enheter/${companyNumber}/roller`);
  }

}
