import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, interval, Observable, Subscription, timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  preserveWhitespaces: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated
})
export class LoadingBarComponent implements OnInit, OnDestroy {

  constructor(private translate: TranslateService, private appStateService: AppStateService) { }

  @Input() header = '';
  @Input() subHeader = '';
  @Input() showSpinner = true;
  @Input() showProgressBar = true;
  @Input() progress = 0;
  totalProgress = 100;
  progressBarSubscription: Subscription;
  timer$: Observable<number>;
  progressEmitter = new BehaviorSubject<number>(this.progress);

  ngOnInit(): void {
    this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });
    this.startProgressBar();
  }

  startProgressBar(): void {
    let progressBarLoop = interval(200);
    const takeThousandNumbers = progressBarLoop.pipe(take(1000));
    takeThousandNumbers.subscribe(x => this.progressEmitter.next((x % 21) * 5));
  }

  ngOnDestroy(): void {
    if (this.progressBarSubscription) this.progressBarSubscription.unsubscribe();
  }
}
