import { Component, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';
import { MultiLineDataItem } from 'src/app/modules/data-visualization/models/multi-line-data-item';

@Component({
  selector: 'app-line-chart',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./../chart-base/chart-base.component.scss']
})
export class LineChartComponent extends ChartBaseComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() chartData: MultiLineDataItem[];
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  renderChart(): void {
    this.browserOnly(() => {

      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.XYChart);

      chart.paddingRight = this.chartOptions.paddingRight;
      chart.paddingLeft = this.mobileDevice ? 0 : this.chartOptions.paddingLeft;
      chart.paddingBottom = this.chartOptions.paddingBottom;
      chart.paddingTop = this.chartOptions.paddingTop;

      super.chart = chart;
      super.setLocal();
      this.setData(chart);
      this.setChartColors(chart);

      // Create axes
      const primaryValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      this.setupChartAxes(chart, primaryValueAxis);
      this.buildLineSeries(chart, primaryValueAxis);

      if (this.chartOptions.areaSelection) {
        chart.cursor = new am4charts.XYCursor();
      }
      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = this.chartOptions.legendPosition as am4charts.LegendPosition;
      chart.legend.labels.template.fill = am4core.color(this.chartOptions.labelColor);
      if (this.chartOptions.copyToLegendMarker) {
        chart.legend.useDefaultMarker = true;
      }

      if (this.mobileDevice) {
        chart.legend.labels.template.adapter.add('textOutput', function (text) {
          if (text.length > 5) {
            return text.substr(0, 5) + '...';
          }
          return text;
        });
      }

      chart.zoomOutButton.background.fill = chart.colors.getIndex(0);
    });
  }

  private setChartColors(chart: am4charts.XYChart): void {
    this.chartOptions.lineColors.forEach(color => {
      chart.colors.list.push(am4core.color(color));
    });
  }

  private setupChartAxes(chart: am4charts.XYChart, primaryAxis: am4charts.ValueAxis): void {
    // X axis
    const categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    if (!this.chartOptions.showGrid) {
      categoryAxis.renderer.grid.template.disabled = true;
    }

    const that = this;
    categoryAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
      if (text && that.chartOptions.locale === 'nb') {
        text = text.toLowerCase().replace('.', '');
      }

      if (text && text.match(/^[a-zA-Z]+..[0-9]*$/)) {
        text = text.replace(/\d+/g, '');
      }

      return text;
    });

    if (this.mobileDevice) {
      categoryAxis.renderer.maxLabelPosition = 0.98;
    }

    // Left Y axis
    primaryAxis.title.text = this.chartOptions.primaryValueAxisTitle;
    primaryAxis.renderer.line.strokeOpacity = 0.8;
    primaryAxis.renderer.line.strokeWidth = 0.5;
    primaryAxis.renderer.line.stroke = am4core.color(this.chartOptions.labelColor);
    primaryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    primaryAxis.numberFormatter = new am4core.NumberFormatter();
    primaryAxis.numberFormatter.numberFormat = '#a';
    super.setNumberFormatSuffixes(primaryAxis);
    
    primaryAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
      if (text) {
        text = text.match(/m$/) ? '' : text;
      }
      return text;
    });

    if (this.chartOptions.valueAxisTicks) {
      primaryAxis.renderer.labels.template.disabled = true;
    }
    if (!this.chartOptions.showGrid) {
      primaryAxis.renderer.grid.template.disabled = true;
    }
  }

  private buildLineSeries(chart: am4charts.XYChart, primaryAxis: am4charts.ValueAxis): void {
    this.chartOptions.lineFields.forEach((line, i) => {
      const lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = line.field;
      if (this.chartOptions.isKeyFieldDate) {
        lineSeries.dataFields.dateX = this.chartOptions.keyField;
      } else {
        lineSeries.dataFields.valueX = this.chartOptions.keyField;
      }
      lineSeries.name = line.title;
      lineSeries.fill = chart.colors.getIndex(i);
      lineSeries.strokeWidth = this.chartOptions.lineStroke;
      lineSeries.tensionX = 0.7;
      lineSeries.yAxis = primaryAxis;
      lineSeries.tooltipText = this.chartOptions.toolTipTextFormat;

      if (lineSeries.tooltip) {
        lineSeries.tooltip.getFillFromObject = false;
        lineSeries.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
        lineSeries.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
      }

      const bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = this.chartOptions.bulletRadius;
      bullet.circle.strokeWidth = this.chartOptions.lineStroke;
      bullet.circle.fill = am4core.color(line.bulletColor ? line.bulletColor : this.chartOptions.bulletColor);
      bullet.tooltipText = this.chartOptions.toolTipTextFormat;
      bullet.copyToLegendMarker = this.chartOptions.copyToLegendMarker;
    });
  }

  private setData(chart: am4charts.XYChart): void {
    const chartData = JSON.parse(JSON.stringify(this.chartData));
    chart.data = chartData;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      this.renderChart();
    }
  }
}
