import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RouteLinkConstants } from 'src/app/app.constants';
import { NavigationParamsService } from 'src/app/modules/shared/services/navigation-params-service';
import { UserService } from 'src/app/modules/core/user-service/user-service';
import { SyncService } from 'src/app/modules/core/common-services/sync-service.service';
import { AccountSyncServiceStatus } from 'src/app/modules/core/models/account-sync-service-status';
import { AccountSyncStatus } from 'src/app/modules/core/models/account-sync-status';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingSystemGuardService implements CanActivate {

  constructor(private router: Router, private navigationParamsService: NavigationParamsService, private syncService: SyncService, private userService: UserService,
    readonly appStateService: AppStateService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const moduleName = route.data.moduleName;
    const enableCustomHeader = route.data.customHeader;
    let component = '';
    let childComponents = '';

    if (route.data.component.includes('/')) {
      component = route.data.component.split('/')[0];
      childComponents = route.data.component.split('/')[1];
    } else {
      component = route.data.component;
    }
    let navigationParmString = this.navigationParamsService.getNavigationQuery(route.queryParams);

    const isInitialB2cRedirection = this.getB2cRedirectionState();

    const andSign = navigationParmString !== '' ? '&' : '';
    navigationParmString = `${navigationParmString}`;
    const redirectUrl = childComponents !== '' ? `${moduleName}/${component}/${childComponents}${andSign}${navigationParmString}` :
      `${moduleName}/${component}${andSign}${navigationParmString}`;

    const attachCustomHeader = enableCustomHeader ? `&title=${route.data.headerTitle}` : '';

    const noAccountingAccessNavigationUrl = childComponents !== '' ? `${moduleName}/${component}/${RouteLinkConstants.noAccountingSystem}?redirectUrl=${redirectUrl}${attachCustomHeader}` :
      `${moduleName}/${RouteLinkConstants.noAccountingSystem}?redirectUrl=${redirectUrl}${attachCustomHeader}`;

    return this.appStateService
      .subscribeAccountingSystemIntegrationStatus().pipe(switchMap((accountingSystemIntegrated: boolean) => {
        if (!accountingSystemIntegrated) {
          this.router.navigateByUrl(noAccountingAccessNavigationUrl);
          return of(false);
        }

        return this.syncService.getAccountingSystemSyncStatus().pipe(map((syncStatus: AccountSyncServiceStatus) =>
          syncStatus && [AccountSyncStatus.Completed, AccountSyncStatus.PartiallyCompleted,].includes(syncStatus.syncStatus)
            ? true
            : this.router.parseUrl(noAccountingAccessNavigationUrl)
        ),
          catchError((error) => {
            const fallbackUrl = isInitialB2cRedirection ? RouteLinkConstants.landingPage : noAccountingAccessNavigationUrl;
            this.router.navigateByUrl(fallbackUrl);
            throw error;
          })
        );
      })
      );
  }

  getB2cRedirectionState(): boolean {
    if (this.userService.getInitialB2cRedirection()) {
      this.userService.updateInitialB2cRedirection('false');
      return true;
    }
    return false;
  }
}
