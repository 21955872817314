import { Pipe, PipeTransform } from '@angular/core';

import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { Currency } from 'src/app/modules/shared/models/currency';

@Pipe({
  name: 'CurrencyFormat'
})
export class CurrencyFormatPipe implements PipeTransform {

  constructor(private utilityService: UtilityService) { }

  transform(value: any, ...args: any[]): string {
    let formattedValue = '0';
    let currency: Currency | null = null;
    const richFormat = args?.[3] ?? true;

    if (value) {
      if (args[1] && args[2]) {
        currency = { rate: parseFloat(args[1]), symbol: args[2] };
      }

      formattedValue = this.utilityService.formatNumberWithSuffixes(value, args[0], currency, richFormat);
    }
    return formattedValue;
  }
}
