export class ChartOptions {
    paddingTop = 10;
    paddingBottom = 10;
    paddingLeft = 10;
    paddingRight = 100;
    columnWidth = 18;
    minColumnWidth = 12;
    minHeight = 250;
    valueField = 'value';
    tooltipFormat = '{valueY.value}';
    title = '';
    legendPosition = 'bottom';
    columnColors = ['#18c88c', '#2a4365'];
    lineColors = ['#C4C4C4', '#C0C0C0'];
    bulletRadius = 10;
    bulletColor = '#ffffff';
    lineStroke = 3;
    showGrid = false;
    dualAxis = false;
    valueAxisTicks = false;
    areaSelection = true;
    rangeSelection = false;
    locale = 'en';
    labelColor = '#69768F';
    tooltipLabelColor = '#2a4365';
    tooltipFillColor = '#f7fafc';
    primaryValueAxisTitle: '';
    secondaryValueAxisTitle: '';
    negativeColumnField = 'column1';
    columnFields = [{ field: '', title: '', valueAxis: 'primary', legendPosition: 0 }];
    lineFields = [{ field: '', title: '', valueAxis: 'primary', legendPosition: 0, bulletColor: '' }];
    keyField = 'key';
    isKeyFieldDate = false;
    isStacked = true;
    toolTipTextFormat = '{name}\n[font-size: 14]{valueY.formatNumber(\'#,###.S\')} kr[/]';
    columnToolTipTextFormat = '';
    negativeColumnToolTipTextFormat = '';
    lineToolTipTextFormat = '';
    category = 'category';
    maxValue = 'max';
    radarColors = ['#5856D6', '#FA5040', '#18C88C'];
    radarBarWidth = 20;
    showRadarPercentage = false;
    labelTextPrefix = '%';
    labelTextFontSize = 12;
    labelTextFontColor = '#000000';
    showGaugeChartCenterContent = false;
    showGaugeChartDefaultColor = '#2a4365';
    gaugeChartCenterLabelDecimalPlaces = 2;
    gaugeChartWidthRadius = -1;
    gaugeChartMaxValue = 10;
    gaugeChartMinValue = 0;
    gaugeChartCenterLabelFontSize = 32;
    gaugeChartCenterLabelFontWeight = "bold";
    gaugeChartCenterSubLabelFontSize = 12;
    gaugeChartCommonFontSize = 11;
    animate = false;
    circularMarkers = false;
    maxColumns = 4;
    currencySymbol = 'kr';
    legendsOrder = false;
    copyToLegendMarker = true;
    percentageWidth = false;
    primaryAxisNumberFormat = '#a';
    secondaryAxisNumberFormat = '#%';
}
