<div class="capassa-modal capassa-modal-common">
    <div class="capassa-modal-content">
        <div [ngClass]="iconClass" *ngIf="iconEnable"></div>
        <h1 class="h1">{{ messageHeaderKey | translate }}</h1>
        <p class="app-message success-message">
            {{ messageContent | translate }}
        </p>
        <button *ngIf="iSButton1Enable" class="btn btn-primary" (click)="reconnect()">
            <span style="font-size:16px;">{{ buttonContent1 | translate }}</span>
        </button>
        <button class="btn btn-secondary" (click)="skip()">
            <span style="font-size:16px;">{{ buttonContent2 | translate }}</span>
        </button>
    </div>
</div>