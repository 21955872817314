import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { environment } from 'src/environments/environment';
import { AccountingSystem } from '../models/accounting-system';

@Injectable({
  providedIn: 'root'
})
export class AccountingSystemDataService {

  constructor(private api: CapassaApiService) { }

  getAccountingSystem(): Observable<AccountingSystem> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/accounting-system`);
  }

  updateAccountingSystem(accountingSystem: AccountingSystem, headerKey: string, headerValue: string): Observable<any> {
    return this.api.getJsonApiService().putWithHeader(`${environment.clientFinanceApi}/v1.0/accountingSystem/accounting-system`, accountingSystem, headerKey, headerValue);
  }

}
