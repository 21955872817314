export enum FileManagerChangeEventType {
    Edit = 'edit',
    AddFolder = 'folder',
    AddFile = 'file',
    UploadFile = 'upload-file',
    Copy = 'copy',
    Paste = 'paste',
    Download = 'download',
    Delete = 'delete',
    DragDrop = 'drag-drop',
    Rename = 'rename',
    SelectedDocument = 'selected-document',
    Move = 'move',
    ChooseCapassaFile = 'choose-capassa-file',
    ShareToDataRoom = 'share-to-dataroom',
    MaximumStorageWarning = 'maximum-storage-warning',
    ReplaceFile = 'replaceFile',
    DeleteDataRoom = 'delete-data-room',
    EditDataRoom = 'edit-data-room',
}

export enum FileManagerDocumentType {
  Folder = 1,
  Pdf = 2,
  Image = 3,
  Excel = 4,
  Doc = 5,
  PowerPoint = 6,
  Text = 7,
  Other = 8
}

export enum FileManagerReportType {
  PnLReport = 'P&L report',
  BalanceReport = 'Balance report',
  PnLBudget = 'P&L Budget',
  LiquidityBudget = 'Liquidity Budget'
}

