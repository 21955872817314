import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { FormsModule } from '@angular/forms';

import { IvyCarouselModule } from 'angular-responsive-carousel';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MsalRedirectComponent } from '@azure/msal-angular';

import { LocalizationModule } from 'src/app/modules/localization/localization.module';
import { CoreModule } from 'src/app/modules/core/core.module';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { AppComponent } from 'src/app/app.component';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { LeftPanelComponent } from 'src/app/components/left-panel/left-panel.component';
import { ProfileSettingsComponent } from 'src/app/components/profile-settings/profile-settings.component';
import { UserIdleModule } from 'angular-user-idle';
import { Constants } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { NotificationComponent } from 'src/app/components/notification/notification.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LeftPanelComponent,
    ProfileSettingsComponent,
    NotificationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    OverlayModule,
    CoreModule.forRoot(),
    SharedModule,
    LocalizationModule.forRoot(),
    IvyCarouselModule,
    FormsModule,
    UserIdleModule.forRoot({ idle: Constants.userIdleTimeoutInSeconds, timeout: 0, ping: Constants.userIdleTimeoutInSeconds })
  ],
  providers: [AppStateService],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
