import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class QueryParamService {

    constructor() { }

    public getParm(parmName: string): any {
        const url = window.location.href;
        let paramValue;
        if (url.includes('?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            paramValue = httpParams.get(parmName);
            if (paramValue?.includes('#')) {
                paramValue = paramValue.split('#')[0];
            }
        }
        return paramValue;
    }

    public getParmList(parmName: string[]): any[] {
        const url = window.location.href;
        let paramValueList: any[] = [];
        if (url.includes('?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            parmName.forEach(element => {
                let paramValue = httpParams.get(element);
                if (paramValue?.includes('#')) {
                    paramValue = paramValue.split('#')[0];
                }
                paramValueList.push(paramValue)
            });
        }
        return paramValueList;
    }

}
