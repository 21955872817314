import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-file-manager-progress',
  templateUrl: './capassa-file-manager-progress.component.html',
  styleUrls: ['./capassa-file-manager-progress.component.scss']
})
export class CapassaFileManagerProgressComponent {

  @Input() fileName = '';
  @Input() folderName = '';

  constructor() {}
}
