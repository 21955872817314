import { Inject, Injectable } from '@angular/core';

import { MsalService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { RedirectRequest, AccountInfo, PopupRequest } from '@azure/msal-browser';
import { PromptValue } from '@azure/msal-common'

import { authConfig } from 'src/app/modules/core/auth/auth.config';
import { environment } from 'src/environments/environment';
import { DefaultSignUpSignInMethod } from 'src/app/modules/shared/globals';
import { CoreStorageService } from 'src/app/modules/core/common-services/core-storage.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {

  defaultSignUpSignInMethod = DefaultSignUpSignInMethod.EmailSignUpSignIn;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, private coreStorageService: CoreStorageService) {
    this.defaultSignUpSignInMethod = environment.defaultSignUpSignInMethod as DefaultSignUpSignInMethod;
  }

  public signOut(): void {
    this.coreStorageService.resetSessionInfo();
    this.authService.logoutRedirect();
  }

  public signUpSignIn(): void {
    const signUpSignInFlowRequest: RedirectRequest | PopupRequest = {
      authority:
        authConfig.b2cPolicies.authorities.signUpSignIn.authority,
      scopes: [...authConfig.apiConfig.scopes],
      prompt: PromptValue.LOGIN, // force user to reauthenticate with their new password
    };

    this.signIn(signUpSignInFlowRequest);
  }

  public signIn(userFlowRequest?: RedirectRequest): void {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest, ...userFlowRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect(userFlowRequest);
    }
  }

  public signUp(): void {
    let redirectRequest: RedirectRequest;

    switch (this.defaultSignUpSignInMethod) {
      case DefaultSignUpSignInMethod.EmailSignUpSignIn: {
        redirectRequest = {
          authority: authConfig.b2cPolicies.authorities.signUpSignIn.authority,
          scopes: [],
        };
      }
        break;
      case DefaultSignUpSignInMethod.PhoneSignUpSignIn: {
        redirectRequest = {
          authority: authConfig.b2cPolicies.authorities.phoneSignUp.authority,
          scopes: [],
        };
      }
        break;
    }

    this.authService.loginRedirect(redirectRequest);
  }

  public emailSignIn(): void {
    const redirectRequest: RedirectRequest = {
      authority: authConfig.b2cPolicies.authorities.signUpSignIn.authority,
      scopes: [],
    };

    this.authService.loginRedirect(redirectRequest);
  }

  public resetPassword(): void {
    const redirectRequest: RedirectRequest = {
      authority: authConfig.b2cPolicies.authorities.resetPassword.authority,
      scopes: [],
    };

    this.authService.loginRedirect(redirectRequest);
  }

  public isLoggedIn(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  public getAccount(): AccountInfo | null {
    var activeAccount = this.authService.instance.getActiveAccount();

    if ((activeAccount == null || !activeAccount) && this.authService.instance.getAllAccounts().length > 0) {
      activeAccount = this.authService.instance.getAllAccounts()[0];
      const signUpSignInSuffix = '-' + authConfig.b2cPolicies.names.signUpSignIn.toLowerCase();
      const signUpSuffix = '-' + authConfig.b2cPolicies.names.signUp.toLowerCase();
      activeAccount.localAccountId = activeAccount.localAccountId.replace(signUpSignInSuffix, '' ).replace(signUpSuffix, '');
      this.authService.instance.setActiveAccount(activeAccount);
    }

    return activeAccount;
  }

  public editProfile(): void {
    const editProfileFlowRequest: RedirectRequest = {
      authority: authConfig.b2cPolicies.authorities.editProfile.authority,
      scopes: [],
    };

    this.signIn(editProfileFlowRequest);
  }
}
