import { Injectable } from '@angular/core';
import { Constants } from 'src/app/app.constants';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { QueryParamService } from 'src/app/modules/core/common-services/query-param.service';
import { UserFlow } from 'src/app/modules/shared/globals';

@Injectable({
  providedIn: 'root',
})

export class UserFlowService {
  constructor(private sharedStorageService: SharedStorageService, private queryParmService: QueryParamService) { }

  public setFlow(userFlow: UserFlow): void {
    this.sharedStorageService.setUserFlow(userFlow);
  }

  public getFlow(): UserFlow {
    const userFlowFromQueryParam = this.queryParmService.getParm(Constants.userFlow);
    if (userFlowFromQueryParam) {
      this.sharedStorageService.setUserFlow(userFlowFromQueryParam);
      return userFlowFromQueryParam as UserFlow;
    } else {
      return this.sharedStorageService.getUserFlow();
    }
  }
}

