import { Component, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { CategoryValueItem } from 'src/app/modules/data-visualization/models/category-value-item';

@Component({
  selector: 'app-radar-chart',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./radar-chart.component.scss']
})
export class RadarChartComponent extends ChartBaseComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() chartData: CategoryValueItem[] = [];
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  renderChart(): void {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.RadarChart);
      chart.startAngle = -90;
      chart.endAngle = 270;
      chart.innerRadius = am4core.percent(20);
      chart.data = this.chartData;

      this.setChartColors(chart);

      const categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis<am4charts.AxisRendererRadial>());
      categoryAxis.dataFields.category = this.chartOptions?.category;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeOpacity = 0;
      categoryAxis.renderer.labels.template.disabled = true;
      categoryAxis.renderer.labels.template.horizontalCenter = "right";
      categoryAxis.renderer.labels.template.adapter.add("fill", function (fill, target) {
        return (target.dataItem.index >= 0) ? chart.colors.getIndex(target.dataItem.index) : fill;
      });
      categoryAxis.renderer.minGridDistance = 10;

      let valueAxis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
      valueAxis.renderer.grid.template.strokeOpacity = 0;
      valueAxis.renderer.labels.template.disabled = true;
      valueAxis.min = 0;
      valueAxis.max = 100;
      valueAxis.strictMinMax = true;

      // Create series
      let series1 = chart.series.push(new am4charts.RadarColumnSeries());
      series1.dataFields.valueX = this.chartOptions?.maxValue;
      series1.dataFields.categoryY = this.chartOptions?.category;
      series1.clustered = false;
      series1.columns.template.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
      series1.columns.template.fillOpacity = 0.08;
      series1.columns.template.strokeWidth = 0;
      series1.columns.template.width = this.chartOptions?.radarBarWidth;
      series1.columns.template.radarColumn.cornerRadius = 20;
      series1.columns.template.adapter.add("fill", function (fill, target) {
        return chart.colors.getIndex(target.dataItem!.index);
      });


      let series2 = chart.series.push(new am4charts.RadarColumnSeries());
      series2.dataFields.valueX = this.chartOptions?.valueField;
      series2.dataFields.categoryY = this.chartOptions?.category;
      series2.clustered = false;
      series2.columns.template.strokeWidth = 0;
      series2.columns.template.width = this.chartOptions?.radarBarWidth;
      series2.columns.template.tooltipText = "{category}: [bold]{value}[/]";
      series2.columns.template.radarColumn.cornerRadius = 20;
      series2.columns.template.adapter.add("fill", function (fill, target) {
        return chart.colors.getIndex(target.dataItem!.index);
      });

      if (this.chartOptions?.showRadarPercentage) {
        const label = chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = this.chartOptions.labelTextFontSize;
        label.x = am4core.percent(100);
        label.y = am4core.percent(100);
        label.horizontalCenter = "middle";
        label.verticalCenter = "middle";
        label.fill = am4core.color(this.chartOptions.labelTextFontColor);
        label.text = this.chartData ? `${this.chartData[0]!.value.toString()}${this.chartOptions.labelTextPrefix}` : '';
      }

      super.chart = chart;
    });
  }

  private setChartColors(chart: am4charts.XYChart): void {
    this.chartOptions?.radarColors.forEach(color => {
      chart.colors.list.push(am4core.color(color));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      this.renderChart();
    }
  }

}
