import { KeyValue } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { MonthlyReportListItem } from 'src/app/modules/shared/models/monthly-report-list-item';
import { ReportDataService } from 'src/app/modules/shared/services/report-data.service';

@Component({
  selector: 'app-quarter-picker',
  templateUrl: './quarter-picker.component.html',
  styleUrls: ['./quarter-picker.component.scss']
})
export class QuarterPickerComponent implements OnInit, OnDestroy, OnChanges {

  selectedLanguageSubscription: Subscription;
  browserLanguage = '';
  reportYear = '';
  reportQuarter = '';
  selectedQuarter: number;
  yearList: number[] = [];
  minReportDate: Date;
  maxReportDate: Date;
  quarterList: KeyValue<number, string>[] = [];
  reportList: MonthlyReportListItem;
  showQuarterPicker = false;
  @Input() reportDate: Date;
  @Output() selectionChange = new EventEmitter();
  @Input() enableCapassaIndexDateRange = false;
  latestReportDate: Date;
  initialized = false;

  constructor(private utilityService: UtilityService, private appStateService: AppStateService, private translate: TranslateService,
    private reportDataService: ReportDataService) { }

  ngOnInit(): void {
    const currentDate = new Date();
    this.latestReportDate = this.utilityService.getMonthlyReportDate();
    if (this.latestReportDate.getMonth() < 2) {
      this.latestReportDate = new Date(this.latestReportDate.getFullYear() -1, 11, 1);
    }

    this.initialized = true;
    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
      this.translate.use(language);
      this.loadQuarterPicker();
      this.loadQuarterList();
      this.loadYearlyReportList(this.latestReportDate, true);
    });
  }

  loadYearlyReportList(year: Date, reloadYears = false): void {
    if (this.enableCapassaIndexDateRange) {
      this.reportDataService.getCapassaIndexDateList().subscribe((reportList: MonthlyReportListItem) => {
        this.reportList = reportList;
        this.reportList.monthlyReportList = reportList.monthlyReportList.filter((arr, index, self) =>
          index === self.findIndex((t) => (t.month === arr.month) && new Date(arr.month).getFullYear().toString() === this.reportYear));
        this.initializeDates(reloadYears);
      });
    }
    else {
      this.reportDataService.getMonthlyReportList(year).subscribe((reportList: MonthlyReportListItem) => {
        this.reportList = reportList;
        this.reportList.monthlyReportList = reportList.monthlyReportList.filter((arr, index, self) =>
          index === self.findIndex((t) => (t.month === arr.month)));
        this.initializeDates(reloadYears);
      });
    }
  }

  ngOnDestroy(): void {
    this.selectedLanguageSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.initialized) {
      this.loadQuarterPicker();
    }
  }

  initializeDates(reloadYears: boolean): void {
    if (reloadYears) {
      this.minReportDate = new Date(this.reportList.minDate);
      this.maxReportDate = new Date(this.reportList.maxDate);
      this.loadYearList();
    }

    this.loadQuarterList();
    this.selectedQuarter = this.getQuarter(this.reportDate);
    this.reportQuarter = 'Q' + this.selectedQuarter;
    this.selectionChange.emit({ year: parseInt(this.reportYear, 10), quarter: this.selectedQuarter });
  }

  loadYearList(): void {
    this.yearList = [];
    let year = this.maxReportDate.getFullYear();
    const minYear = this.minReportDate.getFullYear();
    while (year >= minYear) {
      this.yearList.push(year);
      year--;
    }
  }

  loadQuarterList(): void {
    if (this.reportList) {
      const reportDates = this.reportList.monthlyReportList.filter(x => new Date(x.month) <= this.maxReportDate).map(i => new Date(i.month)).sort((a, b) => a.getTime() - b.getTime());
      this.quarterList = [];
      reportDates.forEach((currentMonth) => {
        if (currentMonth) {
          const quarterIndex = this.getQuarter(currentMonth);
          if (this.quarterList.findIndex(f => f.key === quarterIndex) === -1) {
            this.quarterList.push({ key: quarterIndex, value: 'Q' + quarterIndex });
          }
        }
      });

      this.quarterList.reverse();
    }
  }

  getQuarter(reportDate: Date): number {
    reportDate = new Date(reportDate.getFullYear(), reportDate.getMonth(), 1) > new Date(this.latestReportDate.getFullYear(), this.latestReportDate.getMonth(), 1)
      ? this.latestReportDate : reportDate;
    this.reportYear = reportDate.getFullYear().toString();

    let monthSegment = Math.floor(reportDate.getMonth() / 3);
    //Latest quarter is set only if all 3 months finance reports are available - check whether current month belongs to latest quarter
    if (this.latestReportDate.getFullYear() === reportDate.getFullYear() &&
      monthSegment === Math.floor(this.latestReportDate.getMonth() / 3)) {
      //to get one out of month indexs of 2,5,8,11
      const cutOffMonthIndex = (monthSegment * 3) + 2;

      if (this.latestReportDate.getMonth() === cutOffMonthIndex) {
        monthSegment++;
      }
    } else {
      monthSegment++;
    }

    return monthSegment === 0 ? 1 : monthSegment;
  }

  loadQuarterPicker(): void {
    if (this.reportDate && this.reportDate.getFullYear() > 2000) {
      this.reportYear = this.reportDate.getFullYear().toString();
      this.loadYearlyReportList(this.reportDate, false);
      this.showQuarterPicker = true;
    } else {
      this.showQuarterPicker = false;
    }
  }

  selectYear(year: number): void {
    this.reportDate.setFullYear(year);
    this.reportYear = year.toString();
    this.loadYearlyReportList(new Date(year, 11, 1));
    this.resetQuarterForCurrentYear();
    this.selectionChange.emit({ year: year, quarter: this.selectedQuarter });
  }

  selectQuarter(quarter: number): void {
    this.selectedQuarter = quarter;
    this.reportQuarter = 'Q' + quarter;
    this.selectionChange.emit({ year: parseInt(this.reportYear, 10), quarter: this.selectedQuarter });
  }

  resetQuarterForCurrentYear(): void {
    const today = new Date();
    if (this.reportDate.getFullYear() === today.getFullYear()) {
      const currentQuarter = this.getQuarter(today);
      if (this.selectedQuarter > currentQuarter) {
        this.selectedQuarter = currentQuarter;
        this.reportQuarter = 'Q' + currentQuarter;
      }
    }
  }
}

