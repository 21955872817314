import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import * as moment from 'moment';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { environment } from 'src/environments/environment';
import { MonthlyReport } from 'src/app/modules/shared/models/monthly-report';
import { ReportSummary } from 'src/app/modules/analytics/models/report-summary';
import { CFORecommendation } from 'src/app/modules/shared/models/cfo-recommendation';
import { AccountPayableReceivable } from 'src/app/modules/analytics/models/account-payable-receivable';
import { MonthlyReportListItem } from 'src/app/modules/analytics/models/monthly-report-list-item';
import { ReportShareEmailTemplate } from 'src/app/modules/analytics/models/report-share-email-template';
import { CapassaIndex } from 'src/app/modules/shared/models/capassa-index';
import { CompanyHighlights } from 'src/app/modules/shared/models/company-highlights';
import { CompanyReportHighlightsTypes } from 'src/app/modules/analytics/models/company-report-highlights-type';
import { BalanceSheetReport } from 'src/app/modules/analytics/models/balanceSheet-report';
import { YearlyReportItem } from 'src/app/modules/analytics/models/yearly-report';
import { UserNotificationStatusUpdate } from 'src/app/modules/shared/models/user-notification-status-update';
import { LiquidityReport } from 'src/app/modules/analytics/models/liquidity-report';

@Injectable()
export class AnalyticsDataService {

  constructor(private api: CapassaApiService) { }

  getYearlySummaryData(fromDate: Date, toDate: Date): Observable<ReportSummary> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/yearlyReport/summary?fromDate=${from}&toDate=${to}`);
  }

  getYearlyReport(fromDate: Date, toDate: Date): Observable<MonthlyReport> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/yearlyReport?fromDate=${from}&toDate=${to}`);
  }

  getYearlyReportDates(): Observable<MonthlyReportListItem> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/yearlyReportDates`);
  }

  getMonthlySummaryData(fromDate: Date, toDate: Date): Observable<ReportSummary> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/summary?fromDate=${from}&toDate=${to}`);
  }

  getMonthlyReport(fromDate: Date): Observable<MonthlyReport> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport?fromDate=${from}`);
  }

  getMonthlyBalanceSheetReport(fromDate: Date): Observable<BalanceSheetReport> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/balance-sheet?fromDate=${from}`);
  }

  getQuarterlyReport(year: number, quarter: number): Observable<MonthlyReport> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/quarterlyreport?year=${year}&quarter=${quarter}`);
  }

  getQuarterlyBalanceSheetReport(year: number, quarter: number): Observable<BalanceSheetReport> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/quarterlyreport/balance-sheet?year=${year}&quarter=${quarter}`);
  }

  getQuarterlyReportSummary(fromYear: number, fromQuarter: number, toYear: number, toQuarter: number): Observable<ReportSummary> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/quarterlyreport/summary?fromYear=${fromYear}&fromQuarter=${fromQuarter}&toYear=${toYear}&toQuarter=${toQuarter}`);
  }

  getLatestMonthlyReportData(): Observable<MonthlyReport> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport`);
  }

  getLatestMonthlyBalanceSheetReportData(): Observable<BalanceSheetReport> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/balance-sheet`);
  }

  getCFORecommendations(category: string, userId?: string): Observable<CFORecommendation[]> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/cfo-recommendations-list?userId=${userId}&category=${category}`);
  }

  getAccountPayableReceivable(): Observable<AccountPayableReceivable[]> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/account-payable-receivable`);
  }

  getMonthlyReportList(fromDate: Date): Observable<MonthlyReportListItem> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreports?fromDate=${from}`);
  }

  shareMonthlyReport(reportShareEmailTemplate: ReportShareEmailTemplate): Observable<ReportShareEmailTemplate> {
    return this.api.getJsonApiService().post(`${environment.clientFinanceApi}/v1.0/monthlyreport/share-report`, reportShareEmailTemplate);
  }

  UpdateCFORecommendationsDoneStatus(cardID: string, status: boolean): Observable<string[]> {
    return this.api.getJsonApiService().put(`${environment.clientFinanceApi}/v1.0/capassa-notification/update-status?notificationCardId=${cardID}&status=${status}`, null);
  }

  getPreviousMonthlyReportDateList(reportDate: Date, reportCount: number): Observable<Date[]> {
    const toDate = moment(reportDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreports/previous-report-dates?reportDate=${toDate}&reportCount=${reportCount}`);
  }

  shareQuarterlyReport(reportShareEmailTemplate: ReportShareEmailTemplate): Observable<ReportShareEmailTemplate> {
    return this.api.getJsonApiService().post(`${environment.clientFinanceApi}/v1.0/quarterlyreport/share-report`, reportShareEmailTemplate);
  }

  getLatestCapssaIndex(): Observable<CapassaIndex> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/account/capassa-index`);
  }

  getCapassaIndexList(fromDate: Date, toDate: Date): Observable<CapassaIndex[]> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/capassa-index-list?fromDate=${from}&toDate=${to}`);
  }

  getQuarterlyCapassaIndexList(quarter: number, quarterYear: number): Observable<CapassaIndex[]> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/quarterlyreport/capassa-index-list?quarter=${quarter}&quarterYear=${quarterYear}`);
  }

  getCompanyHighlightsForMonthlyReport(reportDate: Date, type: CompanyReportHighlightsTypes): Observable<CompanyHighlights> {
    const date = moment(reportDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/company-report-highlights?date=${date}&type=${type}`);
  }

  getCompanyHighlightsForQuarterlyReport(quarter: number, quarterYear: number, type: CompanyReportHighlightsTypes): Observable<CompanyHighlights> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/quarterlyreport/company-report-highlights?quarter=${quarter}&quarterYear=${quarterYear}&type=${type}`);
  }

  getCapassaIndexDateList(): Observable<MonthlyReportListItem> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/capassa-index-date-list`);
  }

  shareMonthlyBalanceSheetReport(reportShareEmailTemplate: ReportShareEmailTemplate): Observable<ReportShareEmailTemplate> {
    return this.api.getJsonApiService().post(`${environment.clientFinanceApi}/v1.0/monthlyreport/share-balance-sheet-report`, reportShareEmailTemplate);
  }

  shareQuarterlyBalanceSheetReport(reportShareEmailTemplate: ReportShareEmailTemplate): Observable<ReportShareEmailTemplate> {
    return this.api.getJsonApiService().post(`${environment.clientFinanceApi}/v1.0/quarterlyreport/share-balance-sheet-report`, reportShareEmailTemplate);
  }

  getYearlyMonthOnMonthReport(fromDate: Date, toDate: Date): Observable<YearlyReportItem> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    const to = moment(toDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/yearlyReport/month-on-month-report?fromDate=${from}&toDate=${to}`);
  }

  shareYearlyReport(reportShareEmailTemplate: ReportShareEmailTemplate): Observable<ReportShareEmailTemplate> {
    return this.api.getJsonApiService().post(`${environment.clientFinanceApi}/v1.0/yearlyReport/share-report`, reportShareEmailTemplate);
  }

  UpdateNotificationStatus(userNotificationStatusUpdate: UserNotificationStatusUpdate): Observable<UserNotificationStatusUpdate> {
    return this.api.getJsonApiService().put(`${environment.clientFinanceApi}/v1.0/capassa-notification/notifications/update-status`, userNotificationStatusUpdate);
  }

  getNotifications(userId?: string, isRead?: boolean, isArchived?: boolean, offset?: number, limit?: number): Observable<CFORecommendation[]> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/capassa-notification/notifications?userId=${userId}&isRead=${isRead}&isArchived=${isArchived}&offset=${offset}&limit=${limit}`);
  }

  getLatestMonthlyLiquidityReportData(): Observable<LiquidityReport> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/liquidity`);
  }

  getMonthlyLiquidityReport(fromDate: Date): Observable<LiquidityReport> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/liquidity?fromDate=${from}`);
  }

  shareMonthlyLiquidityReport(reportShareEmailTemplate: ReportShareEmailTemplate): Observable<ReportShareEmailTemplate> {
    return this.api.getJsonApiService().post(`${environment.clientFinanceApi}/v1.0/monthlyreport/share-liquidity-report`, reportShareEmailTemplate);
  }
}
