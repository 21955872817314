import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-capassa-tab',
  templateUrl: './capassa-tab.component.html',
  styleUrls: ['./capassa-tab.component.scss']
})
export class CapassaTabComponent implements OnInit {
  @Input() tabs: any;
  @Output() tabOnChange = new EventEmitter();
  @Input() cssClass: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  openTab(tab: any): void {
    this.tabOnChange.emit(tab);
  }
}
