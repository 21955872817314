import { InvitationStatus } from "../globals";

export class Invitation {
    accountId: string;
    roleId?: string;
    role: string;
    createdBy: string;
    token?: string;
    expiryDate?: string;
    createdOn: Date;
    userEmail: string;
    isUsed: boolean;
    usedBy?: string;
    inviter: string;
    companyName: string;
    reminderDays: string;
    status: InvitationStatus;
    invitee: string;
    inviteAnotherCompany: boolean;
}
