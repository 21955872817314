import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';

import { environment } from 'src/environments/environment';
import { OnboardingCompany } from 'src/app/modules/shared/models/onboarding-company';
import { Account } from 'src/app/modules/shared/models/account';
import { Agreement } from '../models/agreement';
import { AgreementTypes } from '../globals';

@Injectable({
  providedIn: 'root',
})

export class CompanyDataService {
  constructor(private api: CapassaApiService) { }

  getUserCompanies(userId: string): Observable<OnboardingCompany[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/CompanyRoleData/user/${userId}/true`);
  }

  getUserOnBoardedAccounts(userId: string): Observable<Account[]> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/accounts/users/${userId}`);
  }

  getAccountById(): Observable<Account> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/account`);
  }

  saveAccountData(account: Account): Observable<Account> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/account`, account);
  }

  updateAccountData(account: Account): Observable<Account> {
    return this.api.getJsonApiService().put(`${environment.RegistrationApi}/v1.0/account`, account);
  }

  deleteCompanyImage(accountId: string): Observable<string[]> {
    return this.api.getJsonApiService().delete(`${environment.RegistrationApi}/v1.0/account/company-image/${accountId}`);
  }

  getLatestTermsAndConditions(agreementType: AgreementTypes): Observable<Agreement> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/agreements/latest/${agreementType}`);
  }

  sendReminderToPrimaryOwner(): Observable<Account> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/account/send-mail-to-primary-owner`, '');
  }
}
