import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
  selector: '[dynamic-template]'
})
export class DynamicTemplateDirective {
  @Input() id: number;
  constructor(public template: TemplateRef<any>) { }

}
