import { Injectable } from '@angular/core';
import { CanActivate, UrlTree, Router } from '@angular/router';

import { Observable, of} from 'rxjs';
import { map } from 'rxjs/operators';

import { RouteLinkConstants } from 'src/app/app.constants';
import { UserFlow } from 'src/app/modules/shared/globals';
import { UserFlowService } from 'src/app/modules/shared/services/user-flow.service';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultPageGuardService implements CanActivate {

  userFlow: UserFlow = UserFlow.SignIn;

  constructor(private authService: AuthService, private router: Router,
    private userFlowService: UserFlowService, private accountDataService: AccountDataService,
    private appStateService: AppStateService) { }

  canActivate(): Observable<boolean | UrlTree> {

    this.userFlow = this.userFlowService.getFlow();
    if (this.userFlow == UserFlow.Invitation || this.userFlow == UserFlow.MobileSignUp
        || this.userFlow == UserFlow.SignUp) {
      return of(true);
    } else {
      this.router.onSameUrlNavigation = 'reload';
      return this.appStateService.subscribeCanLoadHome().pipe(map((canLoad: boolean) => {
        if(canLoad) {
          return this.accountDataService.getSelectedAccount()?.hasAccountingIntegrated ? this.router.parseUrl(RouteLinkConstants.reloadDashboard) : true;
        } else {
          return false;
        }
      }));
    }
  }
}
