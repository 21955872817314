<div class="block-report-share" *ngIf="showShareWindow && !shareToDataRoom">
    <div class="block-header">
        <h2 class="h2">{{title | translate}}</h2>
        <div class="btn-close" (click)='closePopup()'></div>
    </div>
    <div class="block-body">
        <div class="block-email-body">
            <div class="block-detailed-report">
                <div class="highlights-trigger detailed-report-trigger">
                    <div class="bl-left">
                        <h3 class="h3">{{'Detailed report' | translate}}</h3>
                    </div>

                    <div class="ctrl">
                        <div class="radio" [ngClass]="{'on': detailedReport}"
                             (click)="toggleDetailedReportSection(detailedReport)">
                            <div class="radio-btn"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-distribution-list">
                <div class="highlights-trigger distribution-list-trigger">
                    <div class="bl-left">
                        <h3 class="h3">{{'Distribution list' | translate}}</h3>
                    </div>

                    <div class="ctrl">
                        <div class="radio" [ngClass]="{'on': distributionListExpand}"
                            (click)="distributionlListToggleSection(distributionListExpand)">
                            <div class="radio-btn"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block-add-email">
                <div class="col1">{{'To' | translate}}</div>
                <div class="col2">
                    <span class="email" *ngFor="let emailRecipient of emailList"
                        [ngClass]="{'invalid':!emailRecipient.isValid}"> {{emailRecipient.email}} <span
                            class="btn-close" (click)="removeEmail(emailRecipient.id)"></span></span>
                    <input class="enter-email" placeholder="{{ emailPlaceHolder | translate}}" type="text"
                        list="distributionList" [(ngModel)]="recipient" (keyup.enter)="addEmail()"
                        (keydown.tab)="addEmail()" (focusout)="addEmail()">
                    <datalist id="distributionList" *ngIf="distributionListExpand">
                        <option *ngFor="let email of recentEmailDistributionListValues" [value]="email">{{email}}
                        </option>
                    </datalist>
                </div>
                <div class="button-wrapper">
                    <button *ngIf="distributionListExpand"
                        [disabled]="disableSubmit() || showLoader || disableDistributionList"
                        [ngClass]="{'loading-on':showLoader, 'disabled':disableDistributionList}"
                        class="btn btn-secondary btn-action save-report"
                        (click)="updateEmailDistributionListPreference()">{{
                        'Save'| translate}} <span class="icon"></span></button>
                </div>
            </div>

            <div class="block-email-body">
                <textarea placeholder="{{'Type your message here.' | translate}}" [(ngModel)]="message"
                    class="email-text" name="" id="" cols="30" rows="4"></textarea>
            </div>
            <div class="block-company-highlights">
                <div class="highlights-trigger">
                    <div class="bl-left">
                        <h3 class="h3">{{'Company highlights' | translate}}</h3>
                    </div>

                    <div class="ctrl">
                        <div class="radio" [ngClass]="{'on': companyHighlightsExpand}"
                            (click)="toggleSection(companyHighlightsExpand)">
                            <div class="radio-btn"></div>
                        </div>
                    </div>
                </div>
                <ckeditor class="block-ckeditor-editor" [editor]="editor" [(ngModel)]="companyHighlights"
                    [data]="companyHighlightsData" [config]="editorConfigs" *ngIf="companyHighlightsExpand"></ckeditor>
            </div>
        </div>
        <p class="p">
            {{description | translate}}
        </p>
        <button [disabled]="disableSend() || showLoader" [ngClass]="{'loading-on':showLoader}"
            class="btn btn-primary btn-block btn-share upload-icon-outline-white" (click)="shareReport()">{{
            'Send report'| translate}} <span class="icon"></span></button>

    </div>
</div>

<div class="block-report-share" [ngClass]="!shareToDataRoom || showErrorMessage ? 'hide-grid-container' : ''">
    <div class="block-header">
        <h2 class="h2">{{title | translate}}</h2>
        <div class="btn-close" (click)='closePopup()'></div>
    </div>
    <div class="block-body">
        <div class="block-detailed-report-to-data-room" *ngIf="!isBudget && pdfReportType === pdfReportTypeEnum.profitAndLoss || pdfReportType === pdfReportTypeEnum.balanceSheet || pdfReportType === pdfReportTypeEnum.liquidity">
            <div class="highlights-trigger detailed-report-trigger">
                <div class="bl-left">
                    <h3 class="h3">{{'Detailed report' | translate}}</h3>
                </div>

                <div class="ctrl">
                    <div class="radio" [ngClass]="{'on': detailedReport}"
                         (click)="toggleDetailedReportSection(detailedReport)">
                        <div class="radio-btn"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="share-to-data-room-pop-up">
            <div id="dataRoomTree"></div>
        </div>
        <p class="p">
            {{description | translate}}
        </p>
        <button [disabled]="showLoader" [ngClass]="{'loading-on':showLoader}"
            class="btn btn-primary btn-block btn-share upload-to-data-room-icon-outline-white"
            (click)="!isBudget ? shareReport() : shareBudget()">{{'Send report'| translate}} </button>
    </div>
</div>

<div class="capassa-modal" *ngIf="showErrorMessage">
    <div class="capassa-modal-content">
        <div class="icon-share-unsuccess"></div>
        <h1 class="h1">{{ 'Sharing unsuccessful!' | translate }}</h1>
        <div class="app-message error-message">
            {{ 'Unfortunately, the sharing was unsuccessful. Please try again.' | translate }}
        </div>
        <button class="btn btn-primary" (click)="shareAgain()">
            {{ 'Share again' | translate }}
        </button>
        <button class="btn btn-secondary" (click)="closeErrorModal()">
            {{ 'Close' | translate }}
        </button>
    </div>
</div>
