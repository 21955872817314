import { Component, OnDestroy, OnInit, ViewChild, ElementRef, HostListener, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, fromEvent } from 'rxjs';
import { debounceTime, map, distinctUntilChanged, filter } from 'rxjs/operators';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { LoadingBarStatus, RoleCode } from 'src/app/modules/shared/globals';
import { BrregDataService } from 'src/app/modules/shared/services/brreg-data-service';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { B2CUser } from 'src/app/modules/core/user-service/user.model';
import { Invitation } from 'src/app/modules/shared/models/invitation';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { BrregCompany } from 'src/app/modules/shared/models/brreg-company';
import { PopupMessageService } from 'src/app/modules/shared/services/popup-message-service';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { UserInterfaceService } from '../../services/user-interface.service';

@Component({
  selector: 'app-invite-company-ceo',
  templateUrl: './invite-company-ceo.component.html',
  styleUrls: ['./invite-company-ceo.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InviteCompanyCEOComponent implements OnInit, OnDestroy {
  @ViewChild('companyNameSearch', { static: true }) companyNameSearch: ElementRef;
  private invitationModel: Invitation;
  registerForm: FormGroup;
  submitted = false;
  selectedLanguageSubscription: Subscription;
  browserLanguage: string;
  showLoaderPrimary = false;
  showLoaderSecondary = false;
  showSuccessMessage = false;
  showErrorMessage = false;
  organizationNumber: string;
  companyName: string;
  chairman: string;
  email: string;
  loggedInB2CUserSubscription: Subscription;
  selectedAccountSubscription: Subscription;
  inviterEmail: string;
  filteredCompanies: BrregCompany[];
  pageCount = 0;
  totalPages = 0;
  pageSize = 20;
  showList = false;
  stopSearchByName = false;
  showLoader = false;
  accountId = '';
  constructor(private formBuilder: FormBuilder, private appStateService: AppStateService, private brregDataService: BrregDataService,
    private translate: TranslateService, private userDataService: UserDataService, private authService: AuthService,
    private accountDataService: AccountDataService, private popupMessageService: PopupMessageService) { }

  ngOnInit(): void {
    this.accountId = this.accountDataService.getSelectedAccount().id;
    fromEvent(this.companyNameSearch.nativeElement, 'keyup').pipe(
      map((event: any) => {
        return event.target.value;
      })
      , filter(res => res.length > 2)
      , debounceTime(1000)
      , distinctUntilChanged()
    ).subscribe((text: string) => {
      this.searchCompanyByName(text, 0, false);
    });

    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
      this.translate.setDefaultLang(language);
      this.translate.use(this.browserLanguage);
    });


    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);

    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser()
      .subscribe((registrationB2CUser: B2CUser) => {
        if (registrationB2CUser && registrationB2CUser.id) {
          this.inviterEmail = registrationB2CUser.email;
        }
      });

    this.registerForm = this.formBuilder.group({
      company: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      companyNumber: [''],
      ceoName: ''
    });
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) { this.selectedLanguageSubscription.unsubscribe(); }
    if (this.loggedInB2CUserSubscription) { this.loggedInB2CUserSubscription.unsubscribe(); }
  }

  get formFields() {
    return this.registerForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.registerForm.invalid) { return; }
    this.showLoaderPrimary = true;
    this.invitationModel = new Invitation();
    this.invitationModel.invitee = this.registerForm.value.ceoName;
    this.invitationModel.companyName = this.registerForm.value.company;
    this.invitationModel.userEmail = this.registerForm.value.email;
    this.invitationModel.inviter = this.inviterEmail;
    this.invitationModel.accountId = this.accountId;
    this.invitationModel.inviteAnotherCompany = true;

    this.userDataService.postInvitation(this.invitationModel).subscribe(() => {
      this.showLoaderPrimary = false;
      this.showErrorMessage = false;
      this.showSuccessMessage = true;
      this.onReset();
    }, error => {
      this.showLoaderPrimary = false;
      this.showSuccessMessage = false;
      this.showErrorMessage = true;
    });
  }

  onReset(): void {
    this.submitted = false;
    this.registerForm.reset();
  }

  keyPressAlphanumeric(event: { keyCode: number; preventDefault: () => void; }): boolean {
    const searchText = String.fromCharCode(event.keyCode);
    if (/[0-9]/.test(searchText)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  onPaste(event: ClipboardEvent): boolean {
    const pastedText = event.clipboardData?.getData('text');
    if ((pastedText) && (/[0-9]/.test(pastedText))) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  logout(): void {
    this.showLoaderSecondary = true;
    this.authService.signOut();
  }

  resetFieldsExceptOrgNo(): void {
    this.companyName = '';
    this.chairman = '';
    this.email = '';
  }

  resetFieldsExceptCompanyName(): void {
    this.organizationNumber = '';
    this.chairman = '';
    this.email = '';
  }

  resetFields(): void {
    this.organizationNumber = '';
    this.companyName = '';
    this.chairman = '';
    this.email = '';
  }
  showMore(): void {
    this.pageCount++;
    this.searchCompanyByName(this.companyName, this.pageCount, true);
  }

  goToSelectedCompany(organizationNumber: string): void {
    this.pageCount = 0;
    this.stopSearchByName = true;
    this.showList = false;
    this.organizationNumber = organizationNumber;
  }

  hideCompanyList(): void {
    this.showList = false;
  }

  searchCompanyByName(organizationName: string, pageNumber: number, showMoreResult: boolean): void {
    if (!this.stopSearchByName) {
      this.showLoader = true;
      this.brregDataService.getOrganizationByName(organizationName, pageNumber, this.pageSize).subscribe((organization: any) => {
        this.totalPages = organization.page.totalPages;
        if (organization && organization._embedded && organization._embedded.enheter) {
          if (showMoreResult) {
            this.filteredCompanies = this.filteredCompanies.concat(organization._embedded.enheter);
            this.showList = true;
            this.showLoader = false;
          } else {
            this.filteredCompanies = organization._embedded.enheter;
            this.showList = true;
            this.showLoader = false;
          }
        } else {
          this.showList = false;
          this.totalPages = 0;
          this.pageCount = 0;
          this.filteredCompanies = [];
          this.showLoader = false;
        }
      }, error => {
        this.resetFieldsExceptCompanyName();
      });
    }
  }

  searchCompanyByNumber(organizationNumber: string): void {
    if ((organizationNumber) && (organizationNumber.length === 9)) {
      this.stopSearchByName = true;
      this.brregDataService.getOrganizationByOrganizationNumber(organizationNumber).subscribe((organization: any) => {
        this.companyName = organization.navn;
        this.brregDataService.getRolesByOrganizationNumber(organizationNumber).subscribe((roleInfo: any) => {
          if (roleInfo.rollegrupper) {
            for (const role of roleInfo.rollegrupper) {
              for (const personInfor of role.roller) {
                if ((personInfor.type.kode === RoleCode.CEO) ||
                  (personInfor.type.kode === RoleCode.Chairperson)) {
                  this.chairman = personInfor.person.navn.fornavn
                    + ' ' + personInfor.person.navn.etternavn;
                  break;
                }
              }
            }
          } else {
            this.resetFieldsExceptOrgNo();
          }
        }, error => {
          this.resetFieldsExceptOrgNo();
        });
      }, error => {
        this.resetFieldsExceptOrgNo();
      });
    }
  }

  hidePopup(): void {
    this.popupMessageService.hide();
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    if (this.showList && event.srcElement.id !== 'companySearch') {
      this.pageCount = 0;
      this.stopSearchByName = true;
      this.showList = false;
      event.stopPropagation();
    }
  }
}
