import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Constants } from 'src/app/app.constants';
import { OnboardingContainerState } from 'src/app/modules/shared/globals';
import { BrowserState } from 'src/app/modules/shared/models/browser-state';

@Injectable({
    providedIn: 'root',
})

export class BrowserStateService {
    routeTarget = '';

    constructor(private router: Router) {
    }

    setBrowserBackButtonForState(nextState: OnboardingContainerState, currentState: OnboardingContainerState, navigationQuery: string = ''): void {
        this.routeTarget = '';
        if (currentState === OnboardingContainerState.AccountingSystemSelection) {
            const browserState = new BrowserState();
            browserState.state = currentState;
            browserState.module = Constants.onboarding;

            const routerUrl = navigationQuery ? `${this.router.url}?${navigationQuery}` : this.router.url;
            // need two entries in history to trigger to work
            window.history.pushState(browserState, currentState.toString(), routerUrl);
            window.history.pushState(browserState, currentState.toString(), routerUrl);
        }
    }

    setBrowserBackButtonForPage(route: string, module: string): void {
        this.routeTarget = '/' + route;
        const browserState = new BrowserState();
        browserState.state = 1;
        browserState.module = module;
        // need two entries in history to trigger to work
        window.history.pushState(browserState, route, this.router.url);
        window.history.pushState(browserState, route, this.router.url);

    }
}
