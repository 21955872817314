import { Component, Inject, Input, NgZone, PLATFORM_ID, SimpleChanges } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { Guid } from 'guid-typescript';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4lang_nb_NO from '@amcharts/amcharts4/lang/nb_NO';
import am4lang_en_US from '@amcharts/amcharts4/lang/en_US';
import { ChartOptions } from 'src/app/modules/data-visualization/models/chart-options';

@Component({
  selector: 'app-chart-base',
  templateUrl: './chart-base.component.html',
  styleUrls: ['./chart-base.component.scss']
})

export class ChartBaseComponent {
  private basePlatformId: string;
  private ngZone: NgZone;
  protected chart: am4charts.Chart;
  protected mobileDevice = false;
  private numberFormatSuffixes = ['k', 'M', 'B'];
  public chartId: string;
  public locale: string;

  @Input() chartOptions = new ChartOptions();

  constructor(@Inject(PLATFORM_ID) platformId: string, zone: NgZone) {
    this.basePlatformId = platformId;
    this.ngZone = zone;
    this.chartId = Guid.create().toString();
    const mediaChange = window.matchMedia('(max-width: 575px)');
    this.onMediaChange(mediaChange);
    mediaChange.addEventListener('change', this.onMediaChange);
    am4core.addLicense('ch-custom-attribution');
  }

  private onMediaChange(event: any): void {
    this.mobileDevice = event.matches;
  }

  protected onDestroy(): void {
    this.browserOnly(() => {
      if (this.chart) {
        this.chart.dispose();
      }
    });
  }

  protected browserOnly(f: () => void): void {
    if (isPlatformBrowser(this.basePlatformId)) {
      this.ngZone.runOutsideAngular(() => {
        f();
      });
    }
  }

  protected setLocal(): void {
    this.locale = this.chartOptions.locale;
    if (this.chartOptions.locale === 'nb') {
      this.chart.language.locale = am4lang_nb_NO;
      this.numberFormatSuffixes[0] = 't';
    } else {
      this.chart.language.locale = am4lang_en_US;
      this.numberFormatSuffixes[0] = 'k';
    }

    this.chart.language.locale['_thousandSeparator'] = ' ';
  }

  protected setNumberFormatSuffixes(valueAxis: am4charts.ValueAxis): void {
    if (valueAxis.numberFormatter.bigNumberPrefixes) {
      this.numberFormatSuffixes.forEach((item, i) => {
        valueAxis.numberFormatter.bigNumberPrefixes[i].suffix = item;
      });
    }
  }
}
