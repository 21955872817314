export enum NotificationType {
    AccountingData = 1001,
    OnBoardingData = 1002,
    DailySyncData = 1003,
    TransformSyncData = 1004,
    Error = 1004,
    YearlySyncData = 1006,
    MonthlySyncData = 1007,
    ApiAccess = 1008,
    AppNotification = 1009
}