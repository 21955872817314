import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";

import { Observable } from 'rxjs';

import { AppStateService } from './app-state.service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';


@Injectable({
  providedIn: 'root'
})
export class CapassaApiInterceptorService implements HttpInterceptor {

  constructor(private appStateService: AppStateService, private sharedStorageService: SharedStorageService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setHeaders: {
        'Accept-Language': this.getLanguageCode(this.appStateService.getSelectedLanguage()),
        AccountId: String(this.sharedStorageService.getAccountId())
      }
    });

    return next.handle(request);
  }

  private getLanguageCode(browserLanguage: string): string {
    let languageCode = 'en-US';
    switch (browserLanguage) {
      case 'en':
      case 'en-us':
        languageCode = 'en-US';
        break;
      case 'nb':
      case 'no':
      case 'nb-no':
        languageCode = 'nb-NO';
        break;
    }
    return languageCode;
  }
}
