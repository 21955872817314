import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injector } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

interface HttpMethods {

  get(endPoint: string): any;

  head(endPoint: string): any;

  post(endPoint: string, payload: any): any;

  put(endPoint: string, payload: any): any;

  delete(endPoint: string): any;

  connect(endPoint: string): any;

  options(endPoint: string): any;

  trace(endPoint: string): any;

  patch(endPoint: string, payload: any): any;

}

export abstract class BaseApiService implements HttpMethods {

  protected constructor(
    private baseUrl: string,
    private client: HttpClient,
    private injector: Injector) { }

  get<T>(endPoint: string): Observable<T> {
    return this.client.get<T>(`${this.baseUrl}${endPoint}`)
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  getWithHeader<T>(endPoint: string, key: string, value: string): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set(key, value);
    return this.client.get<T>(`${this.baseUrl}${endPoint}`, { headers: headers })
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );

  }

  post<T>(endPoint: string, payload: any): Observable<T> {
    return this.client.post<T>(`${this.baseUrl}${endPoint}`, payload)
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  post1<T>(endPoint: string, payload: any): Observable<T> {
    return this.client.post<T>(`${endPoint}`, payload)
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  postWithHeader<T>(endPoint: string, payload: any, key: string, value: string): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set(key, value);
    return this.client.post<T>(`${this.baseUrl}${endPoint}`, payload, { headers: headers })
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  postWithResponseType<T>(endPoint: string, payload: any, responseType: any): Observable<T> {
    return this.client.post<T>(`${this.baseUrl}${endPoint}`, payload, { responseType: responseType })
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  put<T>(endPoint: string, payload: any): Observable<T> {
    return this.client.put<T>(`${this.baseUrl}${endPoint}`, payload)
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  putWithHeader<T>(endPoint: string, payload: any, key: string, value: string): Observable<T> {
    let headers = new HttpHeaders();
    headers = headers.set(key, value);
    return this.client.put<T>(`${this.baseUrl}${endPoint}`, payload, { headers: headers })
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  delete<T>(endPoint: string): Observable<T> {
    return this.client.delete<T>(`${this.baseUrl}${endPoint}`)
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  patch<T>(endPoint: string, payload: any): Observable<T> {
    return this.client.put<T>(`${this.baseUrl}${endPoint}`, null)
      .pipe(
        catchError(err => {
          this.handleError(err, `${this.baseUrl}/${endPoint}`);
          return throwError(err);
        })
      );
  }

  head() {
    throw new Error('Method not implemented.');
  }

  connect() {
    throw new Error('Method not implemented.');
  }

  options() {
    throw new Error('Method not implemented.');
  }

  trace() {
    throw new Error('Method not implemented.');
  }

  handleError(error: any, url: string): void {
    if (error instanceof InteractionRequiredAuthError) {
      this.injector.get(MsalService).acquireTokenRedirect({
        scopes: []
      });
    }

    if (error.status === '401') {
      console.error('Session expired');
      this.injector.get(MsalService).acquireTokenSilent({
        scopes:[]
      }).subscribe(() => {
        console.info('acquireTokenSilent was called successfully');
      });
    }
    else {
      console.error(error);
    }
  }
}
