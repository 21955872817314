import { Component, Output, Input, OnInit, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-list',
  templateUrl: './button-list.component.html',
  styleUrls: ['./button-list.component.scss']
})
export class ButtonListComponent implements OnInit {
  @Output() button1Click = new EventEmitter();
  @Input() buttonLabel: string;

  showLoaderPrimary = false;
  constructor() { }

  ngOnInit(): void {
  }

  buttonClick(): void {
    this.showLoaderPrimary = true;
    this.button1Click.emit();
  }

}
