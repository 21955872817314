import { Injectable } from '@angular/core';

import * as signalR from '@microsoft/signalr';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { GlobalNotification } from 'src/app/modules/core/models/notification.model';
import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service'

@Injectable({
  providedIn: 'root'
})
export class SignalRMessageSubscriberService {
  connection: signalR.HubConnection;
  notificationMessages = new BehaviorSubject<GlobalNotification>(new GlobalNotification());

  constructor(private api: CapassaApiService) { }

  initializeHubConnection(userId: string, accountId: string): void {
    const url =
      `${environment.ApiBaseUrl}${environment.signalRHub}/globalnotificationhub?userId=${userId}&jobId=${accountId}`;
    this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(url)
      .withAutomaticReconnect()
      .build();

    this.connection.serverTimeoutInMilliseconds = environment.signalRServerTimeout;
    this.connection.start().then(() => {
      console.log('SignalR Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });
  }

  stopConnection(): void {
    if (this.connection) {
      this.connection.stop().then(() => {
        console.log('SignalR Stopped!');
      });
    }
  }

  initializeBudgetingHubConnection(jobId: string): any {
    const url =
      `${environment.ApiBaseUrl}${environment.signalRHub}/globalnotificationhub?jobId=${jobId}`;
    return this.connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(url)
      .withAutomaticReconnect()
      .build();
  }

  receiveMessages(): void {
    this.connection?.on("updateProgress", (data: any) => {
      this.notificationMessages.next(data);
    });
  }

  getConnectionCount(jobId: string): Observable<number> {
    return this.api.getJsonApiService().get(`${environment.signalRHub}/v1.0/global-notification/connections-count?jobId=${jobId}`);
  }

  broadcastMessage(notification: GlobalNotification): Observable<number> {
    return this.api.getJsonApiService().post(`${environment.signalRHub}/v1.0/global-notification`, notification);
  }

  subscribeNotificationChannel(): Observable<GlobalNotification> {
    return this.notificationMessages.asObservable();
  }
}
