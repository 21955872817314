import { AccountSyncStatus } from 'src/app/modules/core/models/account-sync-status';

export class OnboardingCompany {
    company: Company = new Company;
    roles: CompanyRole[] = [];
}

export class Company {
    ssn: string = '';
    organizationNo: number = 0;
    companyName: string = '';
    dateOfBirth?: Date;
    establishedDate?: Date;
    employeeName: string = '';
    address: string = '';
    postalNumber: string = '';
    city: string = '';
    countryCode: string = '';
    category: string = '';
    registrationDate?: Date;
    chosenBy: string = '';
    organizationalForm: string = '';
    industryCode: string = '';
    vATRegistered: boolean = false;
}

export class CompanyRole {
    code: string = '';
    definition: string = '';
    definitionNo: string = '';
    roleId: number = 0;
}

export class AccountUserRole {
    id: string;
    roleId: number;
    roleCode: string;
    registeredAs: boolean;
}

export class AgreementUser {
    id: string;
    agreementId: string;
    userId: string;
    accountId: string;
    date?: Date;
}

export enum AccountingSystemEnum {
    PowerOffice = 'PowerOffice',
    TripleTex = 'Tripletex',
    Visma = 'VISMA',
    Fiken = 'Fiken',
    TwentyFourSevenOffice = 'TwentyFourSevenOffice',
    Duett = 'Duett'
}

export class AccountingSystem {
    id: string;
    name: string;
    apiurl: string;
    accessTokenUrl: string;
    authenticationType: string;
    logo: string;
    isEnable: boolean;
    type: string;
    clientId: string;
    systemId: string;
    displayName: string;
}

export class IntegrationAccountingSystem {
    id: string;
    accountId: string;
    accountName: string;
    accountingSystemId: string;
    clientId: string;
    clientSecret: string;
    accountingSystemName: string;
    accessToken: string;
    refreshToken: string;
    accountIdentifier: string;
    capassaSyncStatus: AccountSyncStatus;
}

export class Integration {
    id: string;
    accountId: string;
    accountingSystemId: string;
    integrationSystemId: string;
    type: string;
    bankSystemId: string;
    createdOn: Date;
    createdBy?: string;
    agreementId: string;
}

export enum IntegrationTypes {
    AccountingSystem = 'AccountingSystem',
    Bank = ' Bank'
}

export class IntegrationAgreement {
    id: string;
    agreementId: string;
    accountId: string;
    userId?: string;
    date: string;
    integrationId: string;
}

export class AccountUserSystemRole {
    userId: string;
    accountId: string;
    invitationId: string;
}

export enum InvitationError {
    UserAssignedToCompany = 403,
    InvitationAlreadyUsed = 410,
    InvitationExpired = 419,
    InvitationNoFound = 498,
    InternalError = 500
}

export class Identity {
    id: string;
    name: string;
}

export class AccountCredential {
    username: string;
    password: string;
}

export class FikenOrganization {
    name = '';
    slug = '0';
    organizationNumber = '';
    hasApiAccess = false;
}



