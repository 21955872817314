import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  @Input() messageHeaderKey: string = '';
  @Input() messageContent: string = '';
  @Input() buttonContent: string = '';
  @Output() onClickChange = new EventEmitter();
    iconEnable: boolean;
    iconClass: string ;

  constructor(private appStateService: AppStateService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });
  }

  logout() {
    this.onClickChange.emit(true);
  }

  close() {
    this.onClickChange.emit({ button: 'close' });
  }
}
