import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { EventEmitter, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { LoadingBarComponent } from 'src/app/modules/shared/components/loading-bar/loading-bar.component';
import { ErrorModalComponent } from 'src/app/modules/shared/components/error-modal/error-modal.component';
import { SuccessModalComponent } from 'src/app/modules/shared/components/success-modal/success-modal.component';

@Injectable({
    providedIn: 'root'
})
export class UserInterfaceService {
    private overlayRef: OverlayRef;
    private onCloseClickChange = new EventEmitter();

    constructor(private overlay: Overlay, private appStateService: AppStateService) { }

    public showLoader(header = '', subHeader = '', includeSpinner = true, includeProgressBar = true, progress = 0): void {
        this.initOverlay();
        const spinnerOverlayPortal = new ComponentPortal(LoadingBarComponent);
        const component = this.overlayRef.attach(spinnerOverlayPortal);
        component.instance.header = header;
        component.instance.subHeader = subHeader;
        component.instance.showProgressBar = includeProgressBar;
        component.instance.showSpinner = includeSpinner;
        component.instance.progress = progress;
    }

    public showCommonErrorMessage(header = '', content = '', buttonContent = '', iconClass = ''): void {
        this.initOverlay();
        this.appStateService.publishOverlayState(true);
        const commonErrorComponent = new ComponentPortal(ErrorModalComponent);
        const component = this.overlayRef.attach(commonErrorComponent);
        component.instance.messageHeaderKey = header == '' ? 'An error has occurred' : header;
        component.instance.messageContent = content == '' ? 'Something went wrong. Please try again later.' : content;
        component.instance.buttonContent = buttonContent == '' ? 'I will try again later' : buttonContent;
        if (iconClass !== '') {
            component.instance.iconEnable = true;
            component.instance.iconClass = iconClass;
        }
        component.instance.onClickChange.subscribe((data: any) => {
            this.hide();
            this.appStateService.publishOverlayState(false);
            this.onCloseClickChange.emit(true);
        });
    }

    public showCommonSuccessMessage(iconClass = 'icon-update-successful', header = '', content = '', buttonContent = ''): void {
        const positionStrategy = this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically();
        const overlayConfig = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-dark-backdrop',
            panelClass: 'tm-dialog-panel',
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });
        this.initOverlay(overlayConfig);
        this.appStateService.publishOverlayState(true);
        const commonSuccessComponent = new ComponentPortal(SuccessModalComponent);
        const component = this.overlayRef.attach(commonSuccessComponent);
        component.instance.messageHeaderKey = header == '' ? 'Updated successfully!' : header;
        component.instance.messageContent = content == '' ? 'All changes have been updated successfully.' : content;
        component.instance.buttonContent = buttonContent == '' ? 'Close' : buttonContent;
        component.instance.iconEnable = true;
        component.instance.iconClass = iconClass;
        component.instance.onClickChange.subscribe(() => {
            this.hide();
            this.appStateService.publishOverlayState(false);
            this.onCloseClickChange.emit(true);
        });
        this.overlayRef.backdropClick().subscribe(() => {
            this.hide();
            this.appStateService.publishOverlayState(false);
        });
    }

    public hide(): void {
        if (this.overlayRef) {
            this.overlayRef.detach();
        }
    }

    private initOverlay(config: any = null): void {
        if (this.overlayRef) {
            this.overlayRef.detach();
        } else {
            this.overlayRef = config ? this.overlay.create(config) : this.overlay.create();
        }
    }

    public subscribeOnCloseClickChange(): Observable<boolean> {
        return this.onCloseClickChange.asObservable();
    }
}
