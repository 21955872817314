import { Currency } from './modules/shared/models/currency';

export const Constants = {
    accountId: 'AccountId',
    registrationUserId: 'registrationUserId',
    b2cUserId: 'B2CUserId',
    ceoCode: 'DAGL',
    cobCode: 'LEDE',
    accountingSystemId: 'AccountingSystemId',
    selectedLanguage: 'SelectedLanguage',
    invitationId: 'InvitationId',
    userFlow: 'userFlow',
    invitationAccountId: 'InvitationAccountId',
    inviter: 'Inviter',
    mobileSignUp: 'MobileSignUp',
    Email: 'email',
    userIdentifier: 'userIdentifier',
    signIn: 'SignIn',
    iosDownloadPage: 'https://apps.apple.com/no/app/digital-cfo/id1479812049',
    androidDownloadPage: 'https://play.google.com/store/apps/details?id=com.capassa.digcfo&hl',
    androidDownloadIntentUrl: 'intent://scan/#Intent;scheme=invites://capassa.com;package=com.capassa.digcfo;end',
    norwegian: 'Norwegian',
    english: 'English',
    privacyPolicyPage: 'https://www.capassa.com/personvern/',
    home: 'home',
    contactUs: 'contactUs',
    companyName: 'companyName',
    language: 'language',
    settings: 'settings',
    manageTeam: 'manageTeam',
    myTeam: 'my-team',
    invitation: 'invitation',
    onboarding: 'onboarding',
    userActions: 'userActions',
    allUsers: 'All users',
    allInvitations: 'All invitations',
    sendInvitation: 'Send invitation',
    addNewMember: 'Add new member',
    myProfile: 'my-profile',
    companyProfile: 'company-profile',
    manageIntegration: 'integration',
    analytics: 'analytics',
    reports: 'reports',
    dashboard: 'dashboard',
    userIdleTimeoutInSeconds: 14400,
    notificationUrl: 'https://www.skatteetaten.no/bedrift-og-organisasjon/starte-og-drive/frister-gebyrer-og-tilleggsskatt/frister-og-oppgaver/',
    deviceToken: 'deviceToken',
    investorMatrix: 'investorMatrix',
    investor: 'investor',
    reportingCenter: 'reporting-center',
    prediction: 'prediction',
    account: 'Account',
    marketplace: 'marketplace',
    monthly: 'MonthlyTab',
    quarterly: 'Quarterly',
    yearly: 'Yearly',
    keyFinancial: 'key-financial',
    isInitialB2cRedirection: 'isInitialB2cRedirection',
    capassIndex: 'https://www.capassa.com/laer-om-capassa-indeksen/',
    capassaIndexMinRange: 2,
    enableCosmosAllYearData: false,
    pricingPage: 'https://www.capassa.com/#Pricing',
    academyPage: 'https://www.capassa.com/akademi',
    budget: 'budget',
    reporting: 'reporting',
    report: 'report',
    budgeting: 'budgeting',
    profitAndLoss: 'profitAndLoss',
    changeBudget: 'change-budget',
    marketplaceServices: 'marketplaceServices',
    marketplaceProducts: 'marketplaceProducts',
    marketplacePartner: 'marketplacePartner',
    balanceSheet: 'balance-sheet',
    liquidityReport: 'liquidity-report',
    signout: 'signout',
    selectedYear: 'selected-year',
    translation: 'translation',
    budgetAccount: 'budgetAccount',
    exitingBudgeting: 'exitingBudgeting',
    inviteAnotherCompany: 'invite-another-company',
    addCompany: 'add-company',
    budgetComparison: 'budget-comparison',
    default: 'default',
    profitAndLossBudgeting: 'P&L Budget',
    liquidityBudgeting: 'Liquidity Budget',
    liquidityBudget: 'liquidity-budget',
    dataRoom: 'data-room',
    dataRoomDetails: 'data-room-details',
    createDataRoom: 'create-data-room',
    googleTagManagerUrl: 'https://www.googletagmanager.com/gtag/js?id=',
    dashboardOverview: 'dashboard-overview',
    dashboardProfitability: 'dashboard-profitability',
    dashboardBalanceSheet: 'dashboard-balancesheet',
    dashboardLiquidity: 'dashboard-liquidity',
    leftPanel: 'left-panel',
    monthlyReport: 'monthlyReport',
    fikenAccountingSystemId: '1d865431-67c2-41ec-9e04-b60979c468e1',
    subscription: 'subscription',
    trialBannerVisibility: 'TrialBannerVisibility',
    trialExpiryMessageVisibility: 'trialExpiryMessageVisibility',
    subscriptionId: 'subscriptionId',
    roleId: 'roleId',
    ownerRole: '302c99e3-e66c-4bca-b2c9-47d70d8d55c8',
    trialSubscription: 'ba8e5e8e-3a50-4761-288a-e9139c4e7a99',
    defaultSubscription: 'ba8e3e8e-3a50-4761-788a-e9139c477a45',
    accountRole: 'accountRole',
};

export const RouteLinkConstants = {
    landingPage: 'home',
    landingPageContainer: 'home/home-container',
    onboardingCompany: 'onboarding',
    onboardingContainer: 'onboarding-container',
    informCEO: 'home/inform-ceo',
    contactUs: 'home/contact-us',
    myTeam: 'home/my-team',
    invitation: 'home/invitation',
    addNewMember: 'home/add-new-member',
    myProfile: 'home/my-profile',
    companyProfile: 'home/company-profile',
    manageIntegration: 'home/integration',
    reloadHome: 'home/reload-home',
    dashboard: 'analytics/dashboard',
    reloadDashboard: 'analytics/reload',
    keyFinancial: 'analytics/key-financial',
    reportingCenter: 'analytics/reporting-center',
    investorMatrix: 'investor/dashboard',
    monthlyFinancialIndicatorDetails: 'analytics/monthly-financial-indicator-details',
    prediction: 'analytics/prediction',
    capassaIndexDetails: 'analytics/capassa-index-details',
    noAccess: 'no-access',
    noAccountingSystem: 'no-accounting-system-access',
    marketplace: 'marketplace',
    marketplaceServices: 'marketplace/services',
    analyticsReload: 'analytics/reload',
    marketplaceProducts: 'marketplace/products',
    marketplacePartner: 'marketplace/partners',
    budgeting: 'budget/budgeting',
    reports: 'analytics/reports',
    profitAndLoss: 'analytics/reports',
    changeBudget: 'budget/change-budget',
    balanceSheet: 'analytics/balance-sheet',
    liquidityReport: 'analytics/liquidity-report',
    budgetComparison: 'budget/budget-comparison',
    liquidityBudget: 'budget/liquidity-budget',
    dataRoom: 'data-room',
    dataRoomDetails: 'data-room/data-room-details',
    createDataRoom: 'data-room/create-data-room',
    inactiveCompany: 'home/inactive-company',
    dashboardOverview: 'analytics/dashboard/overview',
    dashboardProfitability: 'analytics/dashboard/profitability',
    dashboardBalancesheet: 'analytics/dashboard/balance-sheet',
    dashboardLiquidity: 'analytics/dashboard/liquidity',
    subscription: 'subscription'
};


export const InvitationConstants = {
    messageHeaderKeyUserAlreadyAssignedToCompany: 'User is already assigned to company',
    messageHeaderKeyInvitationAlreadyUsed: 'Invitation is already used',
    messageHeaderKeyInvitationExpired: 'Invitation has expired',
    messageHeaderKeyInvitationNotFound: 'Invitation cannot be found',
    messageHeaderKeyError: 'An error has occurred',
    messageHeaderKeySuccess: 'Great',
    messageContentKeyUserAlreadyAssignedToCompany: 'User is already assigned to company.',
    messageContentKeyInvitationNotFound: 'We are unable to find the invitation',
    messageContentKeyServerError: 'Internal server error.',
    messageContentKeySuccess: "We've informed your inviter successfully",
    InvitationTryAginButtonContent: 'I will try again later',
    InvitationJoinLaterButtonContent: 'I will join later'
};

export const InvitationStatusCodes = {
    Success: 200,
    UserAlreadyAssignedToCompany: 403,
    InvitationAlreadyUsed: 410,
    SomethingWentWrong: 415,
    UnSuccessFul: 416,
    InvitationExpired: 419,
    InvitationNotFound: 498,
    InternalServerError: 500
};

export const Literals = {
    empty: '',
    space: ' ',
    comma: ','
};

export const CapassaIndexColorConstants = {
    indexEmptyColor: 'none',
    indexVeyLowColor: 'dark-red',
    indexLowColor: 'red',
    indexOkColor: 'orange',
    indexHighColor: 'green',
    indexVeryHighColor: 'dark-green',
};

export const CapassaIndexColorCodeConstants = {
    indexVeyLowColor: '#ed868b',
    indexLowColor: '#ffaaaa',
    indexOkColor: '#f5bd7c',
    indexHighColor: '#88e6c3',
    indexVeryHighColor: '#4dc4b1',
};

export const CapassaIndexValueConstants = {
    indexMax: 10,
    indexVeryHigh: 7.99,
    indexHigh: 5.99,
    indexLow: 3.99,
    indexVeryLow: 1.99,
    indexZero: 0
};


export const MonthlyReportConstants = {
    reportReleaseDate: 5
};

export const MaxChartValue = {
    maxChartValue: 100
};

export const ReportColors = {
    Revenue: '#18c88c',
    Expense: '#FF5A5F',
    Result: '#2A4365',
    GrossProfitMargin: '#007AFF',
    OperatingProfitMargin: '#4C6DD9',
    NetProfitMargin: '#5856D6',
    EquityRatio: '#6260F0',
    QuickRatio: '#1CCAE6',
    CurrentRatio: '#2E372F',
    Assets: '#34c759',
    Equity: '#2ac39f',
    Liability: '#ff9f0a',
    WorkingCapital: '#4C88D9',
    FreeCashFlow: '#3F75BE',
    Ebitda: '#3C5BC3',
    Nibd: '#3A5098',
    NibdEbitda: '#161DC1',
    UndistributedProfitLoss: '#CE7D0F',
    EbitdaMargin: '#8B2B98',
    Ebit: '#2b3098',
    EbitMargin: '#001256',
    GrossProfit: '#2F0022',
    BurnRate: '#D60042',
    CashRunway: '#FAA00A',
    CashAndCashEquivalents: '#436436',
    NibdCash: '#C06E52',
    Roe: '#436436',
    Roce: '#4E6151',
    InterestCoverageRatio: '#228b22',
    DebtServicingCoverageRatio: '#663399',
    Roa: '#2F6F71',
};

export const PageOrigins = {
    landingPage: 'home',
    onboardingCompany: 'onboarding',
    onboardingContainer: 'onboarding-container',
    informCEO: 'inform-ceo',
    contactUs: 'contact-us',
    myTeam: 'my-team',
    invitation: 'invitation',
    addNewMember: 'add-new-member',
    myProfile: 'my-profile',
    companyProfile: 'company-profile',
    manageIntegration: 'integration',
    dashboard: 'dashboard',
    reports: 'reports',
    investorMatrix: 'dashboard',
    monthlyFinancialIndicatorDetails: 'monthly-financial-indicator-details',
    quarterlyFinancialIndicatorDetails: 'quarterly-financial-indicator-details',
    yearlyFinancialIndicatorDetails: 'yearly-financial-indicator-details',
    capassaIndexDetails: 'capassa-index-details',
    noAccess: 'no-access',
    prediction: 'prediction',
    dataRoom: 'data-room'
};

export const ReportType = {
    monthly: 'monthly',
    quarterly: 'quarterly',
    yearly: 'yearly'
};

export const PdfReportType = {
    profitAndLoss: 'Profit and loss',
    balanceSheet: 'Balance sheet',
    liquidity: 'Liquidity'
};

export const ActionIds = {
    viewPredictions: 5037,
    viewKeyFinancial: 5031,
    viewMonthlyReportCard: 3004,
    viewProfitAndLossGraph: 2003,
    viewDashboard: 5040
};

export const DefaultCurrency: Currency = {
    rate: 1, symbol: 'NOK'
};

export const Currencies = {
    USD: "USD",
    NOK: "NOK"
};

export const NumberTypes = {
    Integer: 'Integer',
    Decimal: 'Decimal',
    Percentage: 'Percentage',
    NumberWithSuffixes: 'NumberWithSuffixes'
};

export const GeneralSubscriptionType = {
    Essential: '8a646ca6-4f0f-417d-a578-4d1c69833d83',
    Advanced: 'bdbbab65-eacf-41b2-adef-04f89ccaebc1',
    Premium: 'ba8e7e8e-3a50-4761-888a-e9139c4e7a80'
};

