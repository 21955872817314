import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { Constants, DefaultCurrency, RouteLinkConstants, Currencies } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { B2CUser, RegistrationUser } from 'src/app/modules/core/user-service/user.model';
import { CurrencyDataService } from 'src/app/modules/shared/services/currency-data.service';
import { Currency } from 'src/app/modules/shared/models/currency';
import { ExchangeRate } from 'src/app/modules/shared/models/exchange-rate';
import { UserAction } from 'src/app/modules/shared/globals';

@Component({
  selector: 'app-profile-settings',
  templateUrl: './profile-settings.component.html',
  styleUrls: ['./profile-settings.component.scss']
})
export class ProfileSettingsComponent implements OnInit, OnDestroy {

  showLanguagePanel = false;
  showCurrencyPanel = false;
  showCurrency = false;
  showProfile = false;
  userName = '';
  userAlias = '';
  profileImageUrl = '';
  selectedLanguageLabel: string;
  selectedCurrencyLabel = 'NOK';
  languageCode: string;
  constants = Constants;
  loggedInB2CUserSubscription: Subscription;
  languageSubscription: Subscription;
  profileImageUrlSubscription: Subscription;
  registrationUserSubscription: Subscription;
  translateSubscription: Subscription;
  userActionSubscription: Subscription;
  mouseEntered = false;
  currencyList: Currency[] = [DefaultCurrency];
  conversion = 'B.USD.NOK.SP';
  translations: any;
  currencyPanelText = '';
  canViewUserProfile = false;
  isInteractingWithPanel = false;

  constructor(private translate: TranslateService, private authService: AuthService, private appStateService: AppStateService,
    private sharedStorageService: SharedStorageService, private router: Router, private elementRef: ElementRef,
    private currencyDataService: CurrencyDataService) { }

  ngOnInit(): void {
    this.languageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.languageCode = language;
      this.translate.use(language);
      this.setSelectedLanguageLabel(language);
      this.translateSubscription = this.translate.getTranslation(this.languageCode).subscribe((translationObj) => {
        this.translations = translationObj;
      });
    });

    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe((user: B2CUser) => {
      if (user && user.id) {
        this.registrationUserSubscription = this.appStateService.subscribeRegistrationUser().subscribe((registrationUser: RegistrationUser) => {
          if (registrationUser && registrationUser.id) {
            this.setDisplayName(registrationUser.firstName!, registrationUser.lastName!, registrationUser.profileImageUrl);
          } else {
            this.setDisplayName(user.firstName!, user.lastName!);
          }
        });
      }
    });

    this.profileImageUrlSubscription = this.appStateService.subscribeProfileImage().subscribe((profileImageUrl: string) => {
      this.profileImageUrl = profileImageUrl;
    });

    this.currencyDataService.getCurrencyExchangeRates(this.conversion).subscribe((currencyRates: ExchangeRate) => {
      const currencyData = currencyRates?.data?.dataSets[0].series['0:0:0:0'].observations;
      if (currencyData) {
        const rate =  parseFloat(currencyData[0][0]);
        this.currencyList.push({ rate: rate, symbol: Currencies.USD });
        this.showCurrency = true;
      }
    });

    this.userActionSubscription = this.appStateService.subscribeUserActions().subscribe(actions => {
      this.canViewUserProfile = actions.find(action => action === UserAction.ViewUser) ? true : false;
    });
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) { this.languageSubscription.unsubscribe(); }
    if (this.translateSubscription) { this.translateSubscription.unsubscribe(); }
    if (this.loggedInB2CUserSubscription) { this.loggedInB2CUserSubscription.unsubscribe(); }
    if (this.profileImageUrlSubscription) { this.profileImageUrlSubscription.unsubscribe(); }
    if (this.registrationUserSubscription) { this.registrationUserSubscription.unsubscribe(); }
    if (this.userActionSubscription) { this.userActionSubscription.unsubscribe(); }
  }

  setDisplayName(firstName: string, lastName: string, profileImage = ''): void {
    if (profileImage && profileImage !== '') {
      this.profileImageUrl = profileImage;
    }

    if (firstName) {
      this.userName = firstName + ' ';
      this.userAlias = firstName.substring(0, 1).toUpperCase();
    }

    if (lastName) {
      this.userName += lastName;
      this.userAlias += lastName.substring(0, 1).toUpperCase();
    }
  }


  selectedLanguage(language: string): void {
    this.setSelectedLanguageLabel(language);
    this.sharedStorageService.addLanguage(language);
    this.appStateService.publishSelectedLanguage(language);
  }

  selectCurrency(currency: string): void {
    this.selectedCurrencyLabel = currency;
    const selection = this.currencyList.find(f => f.symbol === currency);
    if (selection) {
      this.appStateService.publishSelectedCurrency(selection);
    }
  }

  private setSelectedLanguageLabel(language: string): void {
    this.languageCode = language;
    this.selectedLanguageLabel = language === 'nb' ? Constants.norwegian : Constants.english;
  }

  showUserProfile(): void {
    this.showProfile = true;
    this.isInteractingWithPanel = true;
  }

  hideUserProfile(): void {
    this.showProfile = false;
    this.showLanguagePanel = false;
    this.showCurrencyPanel = false;
    this.mouseEntered = false;
    this.isInteractingWithPanel = false;
  }

  showLanguageSection(): void {
    this.showLanguagePanel = true;
  }

  hideLanguageSection(): void {
    this.showLanguagePanel = false;
    this.isInteractingWithPanel = true;
  }

  showCurrencySection(): void {
    this.showCurrencyPanel = true;
    this.currencyPanelText = this.translations['Choose currency'];
  }

  hideCurrencySection(): void {
    this.showCurrencyPanel = false;
    this.isInteractingWithPanel = true;
  }

  signOut(): void {
    if (this.router?.url?.includes('budgeting')) { return; }
    this.sharedStorageService.clearStorage();
    this.authService.signOut();
  }

  goToLink(url: string): void {
    switch (url) {
      case this.constants.myProfile:
        this.router.navigate([RouteLinkConstants.myProfile]);
        break;
    }
  }

  setFocus(): void {
    this.mouseEntered = true;
    this.isInteractingWithPanel = true;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event: any) {
    if (this.showProfile && !this.elementRef.nativeElement.contains(event.target) && !this.isInteractingWithPanel) {
      this.showProfile = false;
      this.mouseEntered = false;
      event.stopPropagation();
    }

    this.isInteractingWithPanel = false;
  }
}
