import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';

@Injectable()
export class CurrencyDataService {

  constructor(private api: CapassaApiService) { }

  getCurrencyExchangeRates(currencyCodes: string): Observable<any> {
    return this.api.getCurrencyApiService().get(`/data/EXR/${currencyCodes}?format=sdmx-json&lastNObservations=1&locale=en`);
  }
}
