import { Component, Input, OnInit } from '@angular/core';
import { KpiCircularChartOptions } from 'src/app/modules/analytics/models/kpi-circular-chart-options';

@Component({
  selector: 'app-circular-chart',
  templateUrl: './circular-chart.component.html',
  styleUrls: ['./circular-chart.component.scss']
})
export class CircularChartComponent implements OnInit {

  @Input() chartOptions = new KpiCircularChartOptions();
  defaultColor = "#eeeeee";
  chartColor = "#18c88c";
  chartValue = 0;
  constructor() { }

  ngOnInit(): void {
    if (this.chartOptions.percent > 0) {
      this.chartValue = this.chartOptions.percent;
    }
  }
}
