<div class="progress-bar-container" *ngIf="showProgressBar">
    <div class="progress-bar" [ngStyle]="{'width': (progressEmitter | async)  + '%'}">
    </div>
</div>
<div class="spinner-container" *ngIf="showSpinner">
    <div class="spinner-content">
        <div class="spinner-icon-container">

        </div>

        <div *ngIf="header" class="spinner-message">{{ header | translate }}</div>
        <div *ngIf="subHeader" class="spinner-sub-message">{{ subHeader | translate }}</div>
    </div>
</div>