import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { CfoRecommendationSubType } from 'src/app/modules/analytics/models/cfo-recommendation-sub-type';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { B2CUser } from 'src/app/modules/core/user-service/user.model';
import { CFORecommendation } from 'src/app/modules/shared/models/cfo-recommendation';
import { AnalyticsDataService } from 'src/app/modules/shared/services/analytics-data.service';
import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { Constants, Currencies, DefaultCurrency, RouteLinkConstants } from 'src/app/app.constants';
import { Currency } from 'src/app/modules/shared/models/currency';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { NotificationStatus } from 'src/app/modules/analytics/models/notification-status';
import { UserNotificationStatusUpdate } from 'src/app/modules/shared/models/user-notification-status-update';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, AfterViewInit {
  @ViewChild('notificationsList', { static: false })
  notificationsList: ElementRef;
  @Output() visibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() notificationsAvailableVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  selectedTab: NotificationStatus = NotificationStatus.UNREAD;
  registrationB2cUser: B2CUser;
  subscribeLoggedInB2CUser: Subscription;
  notifications: CFORecommendation[] = [];
  subscribeSelectedLanguage: Subscription;
  translationSubscription: Subscription;
  browserLanguage = '';
  cfoRecommendationSubType = CfoRecommendationSubType;
  notificationUrl = Constants.notificationUrl;
  currencySubscription: Subscription;
  selectedCurrency: Currency = DefaultCurrency;
  notificationStatus = NotificationStatus;
  noNewNotificationsMessage: string = '';
  buttonLabel: string;
  showReadButton: boolean = false;
  showArchiveButton: boolean = false;
  buttonsVisible: boolean = false;
  selectedNotifications: string[] = [];
  translations: any;
  offset: number = 0;
  limit: number = 5;
  previouslySelectedTab: NotificationStatus = NotificationStatus.UNREAD;
  isFirstLoad: boolean = true;
  readButtonClicked: boolean = false;
  archiveButtonClicked: boolean = false;
  isAvailableUnreadNotifications: boolean = false;
  isCheckedAll: boolean = false;
  isFetching: boolean = true;
  readTooltip: string = 'Mark as read';
  archivetooltip: string = 'Mark as archived';
  isLastNotificationPartialViewModeSet = false;

  constructor(private analyticsDataService: AnalyticsDataService, private appStateService: AppStateService, private utilityService: UtilityService,
    private router: Router, private accountDataService: AccountDataService, private translationService: TranslateService, private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscribeLoggedInB2CUser = this.appStateService.subscribeLoggedInB2CUser().subscribe((user: B2CUser) => {
      if (user && user.id) {
        this.registrationB2cUser = user;
      }
    });

    this.subscribeSelectedLanguage = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
      this.offset = 0;
      this.notifications = [];
      this.archiveButtonClicked = false;
      this.readButtonClicked = false;
      this.isCheckedAll = false;
      this.isFetching = true;
      if (!this.isFirstLoad) {
        this.resetNotificationsListHeight();
      }
      this.translationSubscription = this.translationService.getTranslation(this.browserLanguage).subscribe((obj: any) => {
        this.translations = obj;
        this.selectTab(this.selectedTab);
      });
    });

    this.currencySubscription = this.appStateService.subscribeSelectedCurrency().subscribe(currency => {
      this.selectedCurrency = currency;
    });
  }

  ngOnDestroy(): void {
    this.subscribeLoggedInB2CUser.unsubscribe();
    this.subscribeSelectedLanguage.unsubscribe();
    this.currencySubscription.unsubscribe();
    this.translationSubscription.unsubscribe();
    this.notificationsList.nativeElement.removeEventListener('scroll', this.onScroll.bind(this));
  }

  getNotifications(isRead: boolean, isArchived: boolean): void {
    this.analyticsDataService.getNotifications(this.registrationB2cUser.registrationUserId, isRead, isArchived, this.offset, this.limit).subscribe((cfoRecommendationsList: CFORecommendation[]) => {
      this.notifications = [...this.notifications, ...cfoRecommendationsList];
      if (this.selectedTab === this.notificationStatus.UNREAD && this.notifications.length > 0) {
        this.isAvailableUnreadNotifications = true;
      } else if (this.selectedTab === this.notificationStatus.UNREAD && this.notifications.length === 0) {
        this.isAvailableUnreadNotifications = false;
      }
      this.notificationsAvailableVisibilityChange.emit(this.isAvailableUnreadNotifications);
      this.setButtonVisibility();
      this.cdRef.detectChanges();
      this.setLastNotificationPartialViewMode();
      this.noNewNotificationsMessage = this.setNoNewNotificationMessage();
      if (this.isFetching) {
        this.offset += this.limit;
      }
      if (this.isCheckedAll) {
        this.checkAllNotifications();
      }
    });
  }

  isRelevantRecommendation(recommendation: any): boolean {
    const relevantSubTypes = [
      this.cfoRecommendationSubType.WITHHOLDING_TAX,
      this.cfoRecommendationSubType.COMPENSATION_TAX,
      this.cfoRecommendationSubType.COMPENSATION_WITHHOLDING_NO_ACCOUNT_SYSTEM,
      this.cfoRecommendationSubType.HOLIDAY_PAY,
      this.cfoRecommendationSubType.HOLIDAY_PAY_NO_ACCOUNT_SYS,
      this.cfoRecommendationSubType.ADVANCE_TAX_FIRST_INSTALLMENT_NO_ACCOUNT_SYS,
      this.cfoRecommendationSubType.ADVANCE_TAX_SECOND_INSTALLMENT_NO_ACCOUNT_SYS,
      this.cfoRecommendationSubType.ADVANCE_TAX_SECOND_INSTALLMENT,
      this.cfoRecommendationSubType.ADVANCE_TAX_FIRST_INSTALLMENT,
      this.cfoRecommendationSubType.ADVICE,
      this.cfoRecommendationSubType.WITHHOLDING_TAX,
      this.cfoRecommendationSubType.EQUITY_REACHING_ZERO,
      this.cfoRecommendationSubType.EQUITY_WARNING,
      this.cfoRecommendationSubType.OTP,
      this.cfoRecommendationSubType.NO_ACCOUNTING_SYSTEM,
      this.cfoRecommendationSubType.NO_BUDGETING,
    ];
    return recommendation.aditionalInfo != null && relevantSubTypes.includes(recommendation.subType);
  }

  shouldShowLink(notification: CFORecommendation): boolean {
    const noLinkSubTypes = [
      this.cfoRecommendationSubType.WELCOME,
      this.cfoRecommendationSubType.TRIGGER_BASED_ADVICE,
      this.cfoRecommendationSubType.ADVICE,
      this.cfoRecommendationSubType.NO_ACCOUNTING_SYSTEM
    ];
    return !noLinkSubTypes.includes(notification.subType as CfoRecommendationSubType);
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
    this.notificationsList.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
    this.isFirstLoad = false;
    if (this.isCheckedAll) {
      this.checkAllNotifications();
    }
  }

  onScroll(): void {
    const notificationsList = this.notificationsList.nativeElement;
    const hasScrollbar = notificationsList.scrollHeight > notificationsList.clientHeight;

    if (hasScrollbar && notificationsList.scrollTop + notificationsList.clientHeight >= notificationsList.scrollHeight) {
      this.selectTab(this.selectedTab);
    }
  }

  setLastNotificationPartialViewMode(): void {
    if (!this.isLastNotificationPartialViewModeSet) {
      const notificationsList = this.notificationsList.nativeElement as HTMLElement;
      const notifications = notificationsList.querySelectorAll('.notification') as NodeListOf<HTMLElement>;

      if (this.isFetching) {
        const lastNotification = notifications[notifications.length - 1];
        const lastNotificationHeight = lastNotification.offsetHeight;
        const notificationsListHeight = notificationsList.offsetHeight;
        const additionalPadding = lastNotificationHeight * 0.4;
        notificationsList.style.height = `${notificationsListHeight - additionalPadding}px`;
        this.isLastNotificationPartialViewModeSet = true;
      } else if (notifications.length <= this.limit) {
        notificationsList.style.height = 'auto';
      }
    }
  }

  getLocalDate(date: Date): string {
    let dateFormat = this.browserLanguage === 'nb' ? 'DD. MMMM' : 'MMMM DD';
    return this.utilityService.getLocalDate(date, this.browserLanguage, dateFormat);
  }

  navigateToLink(notification: CFORecommendation): string {
    return notification.aditionalInfo["ActionLink"];
  }

  navigateToBudgeting(): void {
    this.router.navigateByUrl(RouteLinkConstants.budgeting);
  }

  navigateToReport(date: Date): void {
    const lastMonthDate = new Date(date);
    lastMonthDate.setMonth(lastMonthDate.getMonth() - 1);
    const reportDate = this.utilityService.getLocalDate(lastMonthDate, 'en', 'YYYY-MM-DD');
    this.router.navigateByUrl('analytics/reports?reportDate=' + reportDate);
  }

  selectTab(tab: string): void {
    if (this.previouslySelectedTab !== tab) {
      this.buttonsVisible = false;
      this.notifications = [];
      this.offset = 0;
      this.archiveButtonClicked = false;
      this.readButtonClicked = false;
      this.showReadButton = false;
      this.showArchiveButton = false;
      this.isCheckedAll = false;
      this.isFetching = true;
      this.resetNotificationsListHeight();
    }
    this.previouslySelectedTab = tab as NotificationStatus;
    this.selectedTab = tab as NotificationStatus;
    if (this.isFetching) {
      const { isRead, isArchived } = this.getNotificationStatus(tab);
      this.checkMoreNotificationsAvailability(isRead, isArchived);
    }
  }

  setButtonVisibility(): void {
    if (this.notifications.length > 0 && (this.selectedTab === this.notificationStatus.UNREAD || this.selectedTab === this.notificationStatus.READ)) {
      this.showReadButton = true;
      this.showArchiveButton = true;
      this.readTooltip = this.translations[this.selectedTab === this.notificationStatus.UNREAD ? "Mark as read" : "Mark as unread"];
      this.archivetooltip = this.translations["Mark as archived"];
    } else if (this.notifications.length > 0 && (this.selectedTab === this.notificationStatus.ARCHIVED)) {
      this.showReadButton = false;
      this.showArchiveButton = true;
      this.archivetooltip = this.translations["Unarchive"];
    } else {
      this.showReadButton = false;
      this.showArchiveButton = false;
    }
  }

  setNoNewNotificationMessage(): string {
    const messages = {
      [this.notificationStatus.UNREAD]: 'You have no new unread notifications.',
      [this.notificationStatus.READ]: 'You have no read notifications.',
      [this.notificationStatus.ARCHIVED]: 'You have no archived notifications.'
    };

    return this.notifications.length === 0 ? messages[this.selectedTab] || '' : '';
  }

  getNotificationStatus(tab: string): { isRead: boolean; isArchived: boolean } {
    switch (tab as NotificationStatus) {
      case this.notificationStatus.READ:
        return { isRead: true, isArchived: false };
      case this.notificationStatus.UNREAD:
        return { isRead: false, isArchived: false };
      case this.notificationStatus.ARCHIVED:
        return { isRead: true, isArchived: true };
      default:
        return { isRead: true, isArchived: false };
    }
  }

  initiateNotificationMessage(notification: CFORecommendation): string {
    const currencySymbol = this.selectedCurrency.symbol === Currencies.NOK ? 'kr' : '$';
    let dateFormat = this.browserLanguage === 'nb' ? 'DD. MMMM' : 'MMMM Do';
    const selectedCompanyName = this.accountDataService.getSelectedAccount().name;

    switch (notification.subType as CfoRecommendationSubType) {
      case this.cfoRecommendationSubType.WITHHOLDING_TAX:
      case this.cfoRecommendationSubType.COMPENSATION_TAX:
        if (notification && notification.aditionalInfo) {
          const accountBalancePreviousMonth = `${this.utilityService.getLocalDate(notification.aditionalInfo["AccountBalanceList"][1]["Date"], this.browserLanguage, 'MMMM')}`;
          const accountBalanceCurrentMonth = `${this.utilityService.getLocalDate(notification.aditionalInfo["AccountBalanceList"][0]["Date"], this.browserLanguage, 'MMMM')}`;
          notification.message = notification.message.replace('{Date}', this.utilityService.getLocalDate(notification.date, this.browserLanguage, dateFormat))
            .replace('{Month2}', accountBalanceCurrentMonth)
            .replace('{Month1}', accountBalancePreviousMonth);
        }
        break;

      case this.cfoRecommendationSubType.COMPENSATION_WITHHOLDING_NO_ACCOUNT_SYSTEM:
      case this.cfoRecommendationSubType.ADVANCE_TAX_FIRST_INSTALLMENT_NO_ACCOUNT_SYS:
      case this.cfoRecommendationSubType.ADVANCE_TAX_SECOND_INSTALLMENT_NO_ACCOUNT_SYS:
        notification.message = notification.message.replace('{Date}', this.utilityService.getLocalDate(notification.date, this.browserLanguage, 'MMMM Do'));
        break;

      case this.cfoRecommendationSubType.HOLIDAY_PAY:
      case this.cfoRecommendationSubType.HOLIDAY_PAY_NO_ACCOUNT_SYS:
        if (notification && notification.aditionalInfo) {
          notification.message = notification.message.replace("{Amount}",
            `${this.utilityService.formatNumber(notification.aditionalInfo["Amount"], this.selectedCurrency)} ${currencySymbol}`);
        }
        break;

      case this.cfoRecommendationSubType.NOTIFICATION:
      case this.cfoRecommendationSubType.ADVICE:
      case this.cfoRecommendationSubType.WITHHOLDING_TAX:
      case this.cfoRecommendationSubType.EQUITY_REACHING_ZERO:
      case this.cfoRecommendationSubType.EQUITY_WARNING:
      case this.cfoRecommendationSubType.OTP:
      case this.cfoRecommendationSubType.NO_ACCOUNTING_SYSTEM:
      case this.cfoRecommendationSubType.NO_BUDGETING:
        notification.message = notification.message.replace('{Date}', this.utilityService.getLocalDate(notification.date, this.browserLanguage, dateFormat))
          .replace('{company}', selectedCompanyName);
        if (notification && notification.aditionalInfo) {
          notification.message = notification.message.replace("{Amount}",
            `${this.utilityService.formatNumber(notification.aditionalInfo["Amount"], this.selectedCurrency)} ${currencySymbol}`);
        }
        break;

      case this.cfoRecommendationSubType.ADVANCE_TAX_SECOND_INSTALLMENT:
      case this.cfoRecommendationSubType.ADVANCE_TAX_FIRST_INSTALLMENT:
        if (notification && notification.aditionalInfo) {
          notification.message = notification.message.replace('{Date}', this.utilityService.getLocalDate(notification.date, this.browserLanguage, 'MMMM Do'))
            .replace("{Amount}", `${this.utilityService.formatNumber(notification.aditionalInfo["Amount"], this.selectedCurrency)} ${currencySymbol}`);
        }
        break;

      case this.cfoRecommendationSubType.WELCOME:
        notification.message = notification.message.replace('{company}', selectedCompanyName);
        break;

      case this.cfoRecommendationSubType.TRIGGER_BASED_ADVICE:
      case this.cfoRecommendationSubType.ADVICE:
        notification.message = notification.message.replace('{Date}', this.utilityService.getLocalDate(notification.date, 'MMMM Do'));
        break;

      default:
        notification.message = notification.message;
        break;
    }

    return notification.message;
  }

  setButtonLabel(type: 'read' | 'archive'): void {
    this.clearAllNotifications();
    let newLabel = '';
    if (type === 'read') {
      this.readButtonClicked = !this.readButtonClicked;
      this.archiveButtonClicked = false;
      if (this.selectedTab === this.notificationStatus.UNREAD) {
        newLabel = "Mark as read";
      } else if (this.selectedTab === this.notificationStatus.READ) {
        newLabel = "Mark as unread";
      }
    } else if (type === 'archive') {
      this.archiveButtonClicked = !this.archiveButtonClicked;
      this.readButtonClicked = false;
      if (this.selectedTab === this.notificationStatus.UNREAD || this.selectedTab === this.notificationStatus.READ) {
        newLabel = "Mark as archived";
      } else if (this.selectedTab === this.notificationStatus.ARCHIVED) {
        newLabel = "Unarchived";
      }
    }

    if (this.buttonLabel !== newLabel) {
      this.buttonLabel = newLabel;
      this.buttonsVisible = true;
    } else {
      this.buttonsVisible = !this.buttonsVisible;
    }
  }

  onCheckboxChange(event: Event, notificationId: string): void {
    this.isCheckedAll = false;
    const checkbox = event.target as HTMLInputElement;
    if (checkbox.checked) {
      this.selectedNotifications.push(notificationId);
    } else {
      const index = this.selectedNotifications.indexOf(notificationId);
      if (index > -1) {
        this.selectedNotifications.splice(index, 1);
      }
    }
  }

  checkAllNotifications(): void {
    this.isCheckedAll = true;
    this.selectedNotifications = this.notifications.map(notification => notification.id);
    this.notifications.forEach(notification => {
      const checkbox = document.getElementById(notification.id) as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = true;
      }
    });
  }

  clearAllNotifications(): void {
    this.isCheckedAll = false;
    this.selectedNotifications = [];
    this.notifications.forEach(notification => {
      const checkbox = document.getElementById(notification.id) as HTMLInputElement;
      if (checkbox) {
        checkbox.checked = false;
      }
    });
  }

  updateNotificationStatus(id: string): void {
    if (!this.buttonsVisible && this.selectedTab === this.notificationStatus.UNREAD) {
      const userNotificationStatusUpdate = this.createUserNotificationStatusUpdate([id], 'Mark as read');
      this.updateStatus(userNotificationStatusUpdate);
    }
  }

  updateMultipleNotificationStatus(): void {
    const userNotificationStatusUpdate = this.createUserNotificationStatusUpdate(this.selectedNotifications, this.buttonLabel);
    this.updateStatus(userNotificationStatusUpdate);
  }

  createUserNotificationStatusUpdate(notificationIds: string[], buttonLabel: string): UserNotificationStatusUpdate {
    const userNotificationStatusUpdate: UserNotificationStatusUpdate = {
      userId: this.registrationB2cUser.registrationUserId,
      notificationIds: notificationIds,
      isMarkRead: false,
      isArchive: false,
      isUnarchive: false
    };

    switch (buttonLabel) {
      case 'Mark as read':
        userNotificationStatusUpdate.isMarkRead = true;
        break;
      case 'Mark as unread':
        userNotificationStatusUpdate.isMarkRead = false;
        break;
      case 'Mark as archived':
        userNotificationStatusUpdate.isArchive = true;
        break;
      case 'Unarchived':
        userNotificationStatusUpdate.isUnarchive = true;
        break;
    }
    return userNotificationStatusUpdate;
  }

  checkUnreadNotificationsAvailability(): void {
    if (this.buttonLabel === 'Mark as unread' || this.buttonLabel === 'Unarchived') {
      this.analyticsDataService.getNotifications(this.registrationB2cUser.registrationUserId, false, false, 0, this.limit).subscribe((cfoRecommendationsList: CFORecommendation[]) => {
        if (cfoRecommendationsList.length > 0) {
          this.isAvailableUnreadNotifications = true;
          this.notificationsAvailableVisibilityChange.emit(this.isAvailableUnreadNotifications);
        }
      });
    }
  }

  changeVisibility(): void {
    this.visibilityChange.emit(false);
  }

  resetNotificationsListHeight(): void {
    const notificationsList = this.notificationsList.nativeElement as HTMLElement;
    notificationsList.style.height = 'auto';
  }

  updateStatus(userNotificationStatusUpdate: UserNotificationStatusUpdate): void {
    this.analyticsDataService.UpdateNotificationStatus(userNotificationStatusUpdate).subscribe(() => {
      this.buttonsVisible = false;
      this.isFetching = true;
      this.offset = 0;
      this.notifications = [];
      this.archiveButtonClicked = false;
      this.readButtonClicked = false;
      this.isCheckedAll = false;
      this.resetNotificationsListHeight();
      this.selectTab(this.selectedTab);
      this.checkUnreadNotificationsAvailability();
    });
  }

  checkMoreNotificationsAvailability(isRead: boolean, isArchived: boolean): void {
    const offset = this.offset + this.limit;
    this.analyticsDataService.getNotifications(this.registrationB2cUser.registrationUserId, isRead, isArchived, offset, this.limit).subscribe((cfoRecommendationsList: CFORecommendation[]) => {
      if (cfoRecommendationsList.length > 0) {
        this.isFetching = true;
      } else {
        this.isFetching = false;
      }
      this.getNotifications(isRead, isArchived);
    });
  }
}