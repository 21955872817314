<div class="profile-block">
  <div class="mobile-header">
    <div class="btn-menu-close" (click)="hideLeftMenu()"></div>
    <div class="app-menu-logo"></div>
  </div>
  <div class="user-details" (click)="showUserProfileOptions($event)">
    <div class="user-image">
      <img alt="{{companyAlias}}" *ngIf="companyImageUrl" class="profile-image" src={{companyImageUrl}} width="100%">
      <span *ngIf="!companyImageUrl">{{companyAlias}}</span>
      <div class="btn-action" *ngIf="!isReadonly">
        <label>
          <span>{{'Edit'| translate}}</span>
          <input #imageInput type="file" accept="image/*" (change)="uploadCompanyImage(imageInput)">
        </label>
      </div>
    </div>
    <div class="user-name"></div>
  </div>
</div>

<div class="conx-menu"  (mouseleave)="hideUserProfileOptions()">
  <div class="conx-menu-1" *ngIf="showProfileImageOptions">
    <div class="conx-btn change-role" (click)="chooseImage($event)">
      {{'Choose picture' | translate}}
    </div>
    <div class="conx-btn remove-user" (click)="deleteImage()">{{'Remove picture' |translate }}</div>
  </div>
</div>
