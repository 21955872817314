import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Constants } from 'src/app/app.constants';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { QueryParamService } from 'src/app/modules/core/common-services/query-param.service';

@Injectable({
    providedIn: 'root',
})

export class LanguageDataService {
    constructor(private sharedStorageService: SharedStorageService, private translate: TranslateService,
        private queryParmService: QueryParamService) { }

    public setLanguage(language: string): void {
        this.sharedStorageService.addLanguage(language);
    }

    public getLanguage(): string {
        const userLanguage = this.queryParmService.getParm(Constants.language);
        const languageFromStorage = this.sharedStorageService.getLanguage();
        if (userLanguage) {
            return userLanguage;
        } else if (languageFromStorage !== 'null') {
            return languageFromStorage;
        }
        return this.translate.getBrowserLang().match(/en/) ? 'en':'nb';
    }
}