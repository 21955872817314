import { Pipe, PipeTransform } from '@angular/core';

import { NumberFormats } from 'src/app/modules/shared/models/number-format.enum';
import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { Currency } from 'src/app/modules/shared/models/currency';

@Pipe({
  name: 'NumberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  constructor(private utilityService: UtilityService) { }

  transform(value: any, ...args: any[]): string {
    let formattedValue = '0';
    let setMinusPrefix = false;
    let setNegateValueMinusSuffix = false;
    let removeNegativeSuffix = false;
    let currency: Currency | null = null;

    switch (args[0]) {
      case NumberFormats.negativeValuesInMinusSuffix:
        setMinusPrefix = value < 0;
        break;
      case NumberFormats.nonZeroValuesInMinusSuffix:
        setMinusPrefix = value > 0;
        break;
      case NumberFormats.negateValueInMinusSuffix:
        setNegateValueMinusSuffix = true;
        break;
    }


    if (value) {
      if ((setNegateValueMinusSuffix || (args[0] && args[0] === NumberFormats.nonZeroValuesInMinusSuffix)) && args[2] && args[3]) {
        currency = { rate: parseFloat(args[2]), symbol: args[3] };
      }
      else if (args[1] && args[2]) {
        currency = { rate: parseFloat(args[1]), symbol: args[2] };
      }

      if (removeNegativeSuffix) {
        value = value * -1;
      }

      formattedValue = this.utilityService.formatNumber(value, currency);
    }

    if (setMinusPrefix) {
      formattedValue = `-${formattedValue}`;
    }

    if (setNegateValueMinusSuffix) {
      value = value * -1;
      return value ? this.utilityService.formatNumber(value, currency) : '0';
    }

    return formattedValue;
  }
}
