import { DOCUMENT } from '@angular/common';
import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { RouteLinkConstants } from 'src/app/app.constants';
import { environment } from 'src/environments/environment';
import { OnboardingStateService } from 'src/app/modules/onboarding/services/onboarding-state.service';
import { AccountingSystemEnum, AgreementTypes, UserFlow } from 'src/app/modules/shared/globals';
import { AccountingSystem } from 'src/app/modules/shared/models/accounting-system';
import { Agreement } from 'src/app/modules/shared/models/agreement';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { CompanyDataService } from 'src/app/modules/shared/services/company-data.service';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AccountingSystemNavigationService {

  showLoader = false;
  accountingTermCondition: Agreement;

  constructor(private onboardingStateService: OnboardingStateService, private appStateService: AppStateService,
    private accountDataService: AccountDataService, @Inject(DOCUMENT) private document: Document,
    private companyDataService: CompanyDataService, private router: Router) { }

  integrateAccountingSystem(accountingSystem: AccountingSystem): void {

    this.showLoader = true;
    const onboardCompanyAccount = this.accountDataService.getSelectedAccount();
    this.companyDataService.getLatestTermsAndConditions(AgreementTypes.AccountingSystemAgreementWebPortal)
      .subscribe((agreement: Agreement) => {
        this.accountingTermCondition = agreement;
        this.onboardingStateService.publishAccountingTermCondition(this.accountingTermCondition);
        this.appStateService.publishAccountingSystemReconnectionStatus(true);
        switch (accountingSystem.accountingSystemName) {
          case AccountingSystemEnum.PowerOffice:
            this.router.navigate([{ outlets: { overlayOutlet: RouteLinkConstants.onboardingCompany } }],
              { queryParams: { userFlow: UserFlow.ManageIntegration, accountingSystem: AccountingSystemEnum.PowerOffice } }).then(() => {
              });
            break;
          case AccountingSystemEnum.TripleTex:
            this.router.navigate([{ outlets: { overlayOutlet: RouteLinkConstants.onboardingCompany } }],
              { queryParams: { userFlow: UserFlow.ManageIntegration, accountingSystem: AccountingSystemEnum.TripleTex } }).then(() => {
              });
            break;
          case AccountingSystemEnum.TwentyFourSevenOffice:
            this.router.navigate([{ outlets: { overlayOutlet: RouteLinkConstants.onboardingCompany } }],
              { queryParams: { userFlow: UserFlow.ManageIntegration, accountingSystem: AccountingSystemEnum.TwentyFourSevenOffice } }).then(() => {
              });
            break;
          case AccountingSystemEnum.Fiken:
            this.document.location.href = `${environment.ApiBaseUrl}${environment.clientFinanceApi}/v1.0/fiken?AccountId=${onboardCompanyAccount.id}&AccountName=
          ${encodeURIComponent(accountingSystem.accountingSystemName)}&AccountingSystemId=${accountingSystem.accountingSystemId}&AccountingSystemName=${accountingSystem.accountingSystemName}
          &DisplayName=${accountingSystem.accountingSystemName}&OrganizationNumber=${onboardCompanyAccount.organizationNumber}&isFromWebPortal=true
          &accountingAgreementId=${this.accountingTermCondition.id}&version=${environment.version}&isUpdate=true`;
            break;
          case AccountingSystemEnum.Visma:
            this.document.location.href = `${environment.ApiBaseUrl}${environment.clientFinanceApi}/v1.0/visma?AccountId=${onboardCompanyAccount.id}&AccountName=
          ${encodeURIComponent(onboardCompanyAccount.name)}&AccountingSystemId=${accountingSystem.accountingSystemId}&AccountingSystemName=${accountingSystem.accountingSystemName}
          &DisplayName=${accountingSystem.accountingSystemName}&isFromWebPortal=true&accountingAgreementId=${this.accountingTermCondition.id}&version=${environment.version}&isUpdate=true`;
            break;
        }
      });
  }
}
