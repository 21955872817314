import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router';

import { filter } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsTagsManagerService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router
  ) {
    this.initializeGtm();
    this.trackPageViews();
  }

  /**
   * Initializes GTM by injecting the script and noscript tags based on the environment.
   */
  private initializeGtm(): void {
    if (!environment.googleAnalyticsTabManagerKey) {
      console.warn('GTM Container ID is not defined in the environment configuration.');
      return;
    }

    // Initialize dataLayer if it doesn't exist
    window.dataLayer = window.dataLayer || [];

    // Inject GTM Script
    const gtmScript = this.document.createElement('script');
    gtmScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${environment.googleAnalyticsTabManagerKey}');
    `;
    this.document.head.appendChild(gtmScript);

    // Inject GTM NoScript iframe
    const gtmNoScript = this.document.createElement('noscript');
    gtmNoScript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${environment.googleAnalyticsTabManagerKey}"
                                height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    this.document.body.insertBefore(gtmNoScript, this.document.body.firstChild);
  }

  /**
   * Subscribes to Angular Router events to trigger page view events in GTM.
   */
  private trackPageViews(): void {
    this.router.events.pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      window.dataLayer.push({
        'event': 'pageview',
        'pagePath': event.urlAfterRedirects
      });
    });
  }
}
