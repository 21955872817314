import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { BreadcrumbNodeData } from 'src/app/modules/shared/models/breadcrumb-data';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  @Input() selectedId: string;
  @Input() breadcrumbList: BreadcrumbNodeData[] = [];
  @Output() changeSelection: EventEmitter<string> = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {}

  onSelection(key: string): void {
    this.changeSelection.emit(key);
  }
}
