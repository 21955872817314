import { Component, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';
import { MultiFieldDataItem } from 'src/app/modules/data-visualization/models/multi-field-data-item';

@Component({
  selector: 'app-multi-chart',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./../chart-base/chart-base.component.scss']
})
export class MultiChartComponent extends ChartBaseComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() chartData: MultiFieldDataItem[];
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  renderChart(): void {
    this.browserOnly(() => {

      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.XYChart);

      chart.paddingRight = this.chartOptions.paddingRight;
      chart.paddingLeft = this.chartOptions.paddingLeft;
      chart.paddingBottom = this.chartOptions.paddingBottom;
      chart.paddingTop = this.chartOptions.paddingTop;

      super.chart = chart;
      super.setLocal();
      this.setData(chart);
      this.setChartColors(chart);

      // Create axes
      const primaryValueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      const secondaryValueAxis = this.chartOptions.dualAxis ? chart.yAxes.push(new am4charts.ValueAxis()) : new am4charts.ValueAxis();
      this.setupChartAxes(chart, primaryValueAxis, secondaryValueAxis);

      // Build chart  series
      this.buildColumnSeries(chart, primaryValueAxis, secondaryValueAxis);
      this.buildLineSeries(chart, primaryValueAxis, secondaryValueAxis);

      if (this.chartOptions.areaSelection) {
        chart.cursor = new am4charts.XYCursor();
      }
      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.paddingTop = 20;
      chart.legend.position = this.chartOptions.legendPosition as am4charts.LegendPosition;
      chart.legend.labels.template.fill = am4core.color(this.chartOptions.labelColor);
      chart.legend.maxColumns = this.chartOptions.maxColumns;

      if (this.chartOptions.copyToLegendMarker) {
        chart.legend.useDefaultMarker = true;
      }

      if (this.chartOptions.circularMarkers) {
        const marker: any = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
      }

      if (this.chartOptions.legendsOrder) {
        chart.legend.events.on('ready', (e) => {
          e.target.itemContainers.each((item: any) => {
            item.zIndex = item.dataItem.dataContext.dummyData.position;
            e.target.children.moveValue(item, item.zIndex);
          });
        });
      }

      if (this.mobileDevice) {
        chart.legend.labels.template.adapter.add('textOutput', function (text) {
          if (text.length > 5) {
            return text.substr(0, 5) + '...';
          }
          return text;
        });
      }

      chart.zoomOutButton.background.fill = chart.colors.getIndex(0);
      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 14;
      markerTemplate.height = 14;
    });
  }

  private setChartColors(chart: am4charts.XYChart): void {
    this.chartOptions.columnColors.forEach(color => {
      chart.colors.list.push(am4core.color(color));
    });

    this.chartOptions.lineColors.forEach(color => {
      chart.colors.list.push(am4core.color(color));
    });
  }

  private setupChartAxes(chart: am4charts.XYChart, primaryAxis: am4charts.ValueAxis, secondaryAxis: am4charts.ValueAxis): void {
    // X axis
    let chartAxis;

    if (this.chartOptions.isKeyFieldDate) {
      chartAxis = chart.xAxes.push(new am4charts.DateAxis());

    } else {
      chartAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      chartAxis.dataFields.category = this.chartOptions.keyField;

    }

    chartAxis.renderer.minGridDistance = 30;
    chartAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);
    chartAxis.renderer.cellStartLocation = 0.1;
    chartAxis.renderer.cellEndLocation = 0.9;
    if (!this.chartOptions.showGrid) {
      chartAxis.renderer.grid.template.disabled = true;
    }

    const that = this;
    chartAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
      if (text && that.chartOptions.locale === 'nb') {
        text = text.toLowerCase().replace('.', '');
      }

      if (text && text.match(/^[a-zA-Z]+..[0-9]*$/)) {
        text = text.replace(/\d+/g, '');
      }

      return text;
    });

    if (this.mobileDevice) {
      chartAxis.renderer.maxLabelPosition = 0.98;
    }

    // Left Y axis
    primaryAxis.title.text = this.chartOptions.primaryValueAxisTitle;
    if (this.chartOptions.valueAxisTicks) {
      primaryAxis.renderer.labels.template.disabled = true;
    }
    if (!this.chartOptions.showGrid) {
      primaryAxis.renderer.grid.template.disabled = true;
    }

    primaryAxis.renderer.line.strokeOpacity = 0.8;
    primaryAxis.renderer.line.strokeWidth = 0.5;
    primaryAxis.renderer.line.stroke = am4core.color(this.chartOptions.labelColor);
    primaryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    primaryAxis.numberFormatter = new am4core.NumberFormatter();
    primaryAxis.numberFormatter.numberFormat = this.chartOptions.primaryAxisNumberFormat;
    super.setNumberFormatSuffixes(primaryAxis);
    
    primaryAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
      if (text) {
        text = text.match(/m$/) ? '' : text;
      }
      return text;
    });

    // Right Axis
    if (this.chartOptions.dualAxis) {
      secondaryAxis.title.text = this.chartOptions.secondaryValueAxisTitle;
      secondaryAxis.renderer.opposite = true;
      secondaryAxis.renderer.grid.template.disabled = true;
      if (this.chartOptions.valueAxisTicks) {
        secondaryAxis.renderer.labels.template.disabled = true;
      }

      secondaryAxis.renderer.line.strokeOpacity = 0.8;
      secondaryAxis.renderer.line.strokeWidth = 0.5;
      secondaryAxis.renderer.line.stroke = am4core.color(this.chartOptions.labelColor);
      secondaryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);
      secondaryAxis.numberFormatter = new am4core.NumberFormatter();
      secondaryAxis.numberFormatter.numberFormat = this.chartOptions.secondaryAxisNumberFormat;
      super.setNumberFormatSuffixes(secondaryAxis);    
    }
  }

  private buildColumnSeries(chart: am4charts.XYChart, primaryAxis: am4charts.ValueAxis, secondaryAxis: am4charts.ValueAxis): void {
    this.chartOptions.columnFields.forEach((column, i) => {
      const columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.dataFields.valueY = column.field;
      if (this.chartOptions.legendsOrder) {
        columnSeries.dummyData = { position: column.legendPosition };
      }

      if (this.chartOptions.isKeyFieldDate) {
        columnSeries.dataFields.dateX = this.chartOptions.keyField;
      } else {
        columnSeries.dataFields.categoryX = this.chartOptions.keyField;
        columnSeries.name = column.field;
      }

      if (column.valueAxis !== 'primary' && this.chartOptions.dualAxis) {
        columnSeries.yAxis = secondaryAxis;
      } else {
        columnSeries.yAxis = primaryAxis;
      }

      columnSeries.name = column.title;
      columnSeries.tooltipText = this.chartOptions.columnToolTipTextFormat !== '' ? this.chartOptions.columnToolTipTextFormat : this.chartOptions.toolTipTextFormat;

      if (columnSeries.tooltip) {
        columnSeries.tooltip.getFillFromObject = false;
        columnSeries.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
        columnSeries.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
      }

      columnSeries.strokeWidth = 0;
      columnSeries.stacked = this.chartOptions.isStacked;

      let radius = 0;
      if (this.chartOptions.percentageWidth) {
        columnSeries.columns.template.width = this.mobileDevice ? am4core.percent(80) : am4core.percent(90);
        radius = this.mobileDevice ? this.chartOptions.minColumnWidth : this.chartOptions.columnWidth;
      } else {
        columnSeries.columns.template.width = this.mobileDevice ? this.chartOptions.minColumnWidth : this.chartOptions.columnWidth;
        radius = columnSeries.columns.template.width / 2;
      }
      const topRadius = this.getRadiusFunction(radius, true);
      const bottomRadius = this.getRadiusFunction(radius, false);

      columnSeries.columns.template.column.adapter.add("cornerRadiusTopLeft", topRadius);
      columnSeries.columns.template.column.adapter.add("cornerRadiusTopRight", topRadius);
      columnSeries.columns.template.column.adapter.add("cornerRadiusBottomLeft", bottomRadius);
      columnSeries.columns.template.column.adapter.add("cornerRadiusBottomRight", bottomRadius);
    });
  }

  private getRadiusFunction(radiusWidth: number, isTop: boolean) {
    if (isTop) {
      return function (radius: any, target: any) {
        return (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : radiusWidth;
      }
    } else {
      return function (radius: any, target: any) {
        return (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : radiusWidth;
      }
    }
  }

  private buildLineSeries(chart: am4charts.XYChart, primaryAxis: am4charts.ValueAxis, secondaryAxis: am4charts.ValueAxis): void {
    this.chartOptions.lineFields.forEach((line, i) => {
      const lineSeries = chart.series.push(new am4charts.LineSeries());
      lineSeries.dataFields.valueY = line.field;
      if (this.chartOptions.legendsOrder) {
        lineSeries.dummyData = { position: line.legendPosition };
      }

      if (this.chartOptions.isKeyFieldDate) {
        lineSeries.dataFields.dateX = this.chartOptions.keyField;
      } else {
        lineSeries.dataFields.valueX = this.chartOptions.keyField;
      }
      lineSeries.name = line.title;
      lineSeries.fill = chart.colors.getIndex(i);
      lineSeries.strokeWidth = this.chartOptions.lineStroke;
      lineSeries.tensionX = 0.7;
      if (line.valueAxis !== 'primary' && this.chartOptions.dualAxis) {
        lineSeries.yAxis = secondaryAxis;
      } else {
        lineSeries.yAxis = primaryAxis;
      }


      lineSeries.tooltipText = this.chartOptions.lineToolTipTextFormat !== '' ? this.chartOptions.lineToolTipTextFormat : this.chartOptions.toolTipTextFormat;

      if (lineSeries.tooltip) {
        lineSeries.tooltip.getFillFromObject = false;
        lineSeries.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
        lineSeries.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
      }

      const bullet = lineSeries.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = this.chartOptions.bulletRadius;
      bullet.circle.strokeWidth = this.chartOptions.lineStroke;
      bullet.circle.fill = am4core.color(line.bulletColor ? line.bulletColor : this.chartOptions.bulletColor);
      bullet.tooltipText = this.chartOptions.toolTipTextFormat;
      bullet.copyToLegendMarker = this.chartOptions.copyToLegendMarker;
    });
  }

  private setData(chart: am4charts.XYChart): void {
    const chartData = JSON.parse(JSON.stringify(this.chartData)) as MultiFieldDataItem[];
    if (this.chartOptions.negativeColumnField) {
      chartData.forEach(item => {
        item.secondColumn = item.secondColumn * -1;
      });
    }
    chart.data = chartData;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      this.renderChart();
    }
  }
}
