<div class="profile-block">
    <div class="mobile-header">
        <div class="btn-menu-close" (click)="hideLeftMenu()"></div>
        <a class="navbar-brand" (click)="goToLink()"><div class="app-menu-logo"></div></a>
    </div>
    <div class="user-details"   (click)="showUserProfileOptions($event)">
        <div class="user-image">
            <img alt="{{userAlias}}" *ngIf="profileImageUrl" class="profile-image" src={{profileImageUrl}} width="100%">
            <span *ngIf="!profileImageUrl">{{userAlias}}</span>
            <div class="btn-action" *ngIf="!isReadonly">
                <label>
                    <span>{{'Edit'| translate}}</span>
                    <input #imageInput type="file" accept="image/*" (change)="uploadProfileImage(imageInput)">
                </label>
            </div>
        </div>
        <div class="user-name">{{userName}}</div>
        <!-- <div class="user-titl">{{'Your profile'| translate}}</div> -->
    </div>
</div>

<div class="conx-menu"  (mouseleave)="hideUserProfileOptions()">
    <div class="conx-menu-1" *ngIf="showProfileImageOptions">
        <div class="conx-btn change-role" (click)="chooseImage($event)">
            {{'Choose picture' | translate}}
        </div>
        <div class="conx-btn remove-user" (click)="deleteImage()">{{'Remove picture' |translate }}
        </div>
    </div>
</div>
