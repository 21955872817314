import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class PopupMessageService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private appStateService: AppStateService) { }

  public showComponentInPopup(component: any, isBackdropEnable = false): void {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-dark-backdrop',
      panelClass: 'tm-dialog-panel',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
    this.initOverlay(overlayConfig);
    this.appStateService.publishOverlayState(true);
    const popupMessageComponent = new ComponentPortal(component);
    const popupComponent = this.overlayRef.attach(popupMessageComponent);
    if (isBackdropEnable) {
      this.overlayRef.backdropClick().subscribe(() => {
        this.hide();
        this.appStateService.publishOverlayState(false);
      });
    }
  }

  public hide(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  private initOverlay(config: any = null): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    } else {
      this.overlayRef = config ? this.overlay.create(config) : this.overlay.create();
    }
  }
}
