import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { JsonApiService } from './json-api-service';
import { BaseApiService } from './base-api.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class CapassaApiService {

  private jsonApiService: JsonApiService;
  private berrgApiService: JsonApiService;
  private currencyApiService: JsonApiService;

  constructor(
    private httpClient: HttpClient,
    private injector: Injector) {
    this.jsonApiService = new JsonApiService(environment.ApiBaseUrl, this.httpClient, this.injector);
    this.berrgApiService = new JsonApiService(environment.brregBaseUrl, this.httpClient, this.injector);
    this.currencyApiService = new JsonApiService(environment.currencyApiBaseUrl, this.httpClient, this.injector);
  }

  getJsonApiService(): BaseApiService {
    return this.jsonApiService;
  }

  getBerrgApiService(): BaseApiService {
    return this.berrgApiService;
  }

  getCurrencyApiService(): BaseApiService {
    return this.currencyApiService;
  }
}
