<div class="col-b" *ngIf="showQuarterPicker">
    <div ngbDropdown>
        <div class="btn-c btn-yer" id="reportYear" ngbDropdownToggle>{{reportYear}}</div>
        <div ngbDropdownMenu="reportYear">
            <button ngbDropdownItem (click)="selectYear(year)" *ngFor="let year of yearList">{{year}}</button>
        </div>
    </div>
    <div ngbDropdown>
        <div class="btn-c btn-quarterly" id="reportMonth" ngbDropdownToggle>{{reportQuarter}}</div>
        <div ngbDropdownMenu="reportMonth">
            <button ngbDropdownItem (click)="selectQuarter(quarter.key)"
                *ngFor="let quarter of quarterList">{{quarter.value}}</button>
        </div>
    </div>
</div>
