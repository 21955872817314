import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'onboarding',
    outlet: 'overlayOutlet',
    loadChildren: () => import('./modules/onboarding/onboarding.module').then(m => m.OnboardingModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('./modules/analytics/analytics.module').then(m => m.AnalyticsModule)
  },
  {
    path: 'investor',
    loadChildren: () => import('./modules/investor/investor.module').then(m => m.InvestorModule)
  },
  {
    path: 'marketplace',
    loadChildren: () => import('./modules/marketplace/marketplace.module').then(m => m.MarketPlaceModule)
  },
  {
    path: 'budget',
    loadChildren: () => import('./modules/budget/budget.module').then(m => m.BudgetModule)
  },
  {
    path: 'capassa-score',
    loadChildren: () => import('./modules/capassa-score/capassa-score.module').then(m => m.CapassaScoreModule)
  },
  {
    path: 'data-room',
    loadChildren: () => import('./modules/data-room/data-room.module').then(m => m.DataRoomModule)
  },
  {
    path: 'subscription',
    loadChildren: () => import('./modules/subscription/subscription.module').then(m => m.SubscriptionModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
