<div #dataContainer *ngIf="chartOptions.hasDemarcateCircle" class="block-mkc_circle-chart">
    <svg class="mkc_circle-chart" viewbox="0 0 36 36" width="50" height="50" xmlns="http://www.w3.org/2000/svg">
        <path class="mkc_circle-bg" [style.stroke]="defaultColor" [attr.stroke-width]="2.5" fill="none" d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831" />
        <path class="mkc_circle" [style.stroke]="chartColor" [attr.stroke-width]="chartValue == 0 ? 0 : 3"
            [style.strokeDasharray]="chartValue+ ' ' + (100 - chartValue)" stroke-linecap="round" fill="none" d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831" />
    </svg>
</div>