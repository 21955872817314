import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { B2CUser, RegistrationUser } from 'src/app/modules/core/user-service/user.model';

@Component({
  selector: 'app-welcome-user',
  templateUrl: './welcome-user.component.html',
  styleUrls: ['./welcome-user.component.scss']
})
export class WelcomeUserComponent implements OnInit, OnDestroy {

  firstName: string;
  loggedInB2CUserSubscription: Subscription;
  registrationUserSubscription: Subscription;

  constructor(private appStateService: AppStateService) { }

  ngOnInit(): void {
    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe((user: B2CUser) => {
      if (user && user.id) {
        this.firstName = user.firstName ? user.firstName : '';
        this.registrationUserSubscription = this.appStateService.subscribeRegistrationUser().subscribe((registrationUser: RegistrationUser) => {
          if (registrationUser && registrationUser.id) {
            this.firstName = registrationUser.firstName ? registrationUser.firstName : '';
          }
        });
      }
    });
  }

  ngOnDestroy(): void {
    if (this.loggedInB2CUserSubscription) { this.loggedInB2CUserSubscription.unsubscribe() };
    if (this.registrationUserSubscription) { this.registrationUserSubscription.unsubscribe() };
  }
}