import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { OnboardingCompany, AccountingSystem } from 'src/app/modules/onboarding/models/onboarding.model';
import { OnboardingContainerState } from 'src/app/modules/shared/globals';
import { Agreement } from 'src/app/modules/shared/models/agreement';

@Injectable({
  providedIn: 'root'
})
export class OnboardingStateService {

  private currentOnboardingContainerState = new BehaviorSubject(OnboardingContainerState.Welcome);
  private previousOnboardingContainerState = new BehaviorSubject(OnboardingContainerState.None);
  private selectedOrganization = new BehaviorSubject<OnboardingCompany>(new OnboardingCompany());
  private selectedCompanyTermCondition = new BehaviorSubject<Agreement>(new Agreement());
  private selectedAccountingTermCondition = new BehaviorSubject<Agreement>(new Agreement());
  private selectedAccountingSystem = new BehaviorSubject<AccountingSystem>(new AccountingSystem());
  private accountingSystemIntegrationSuccess = new BehaviorSubject<boolean>(true);
  private ssnExistEmail = new BehaviorSubject<string>('');
  private SsnExistIdp = new BehaviorSubject<string>('');
  private userIdentifier = new BehaviorSubject<string>('');
  constructor() {
  }

  setOnboardingContainerState(nextOnboardingContainerState: OnboardingContainerState, currentOnboardingContainerState: OnboardingContainerState): void {
    this.currentOnboardingContainerState.next(nextOnboardingContainerState);
    this.previousOnboardingContainerState.next(currentOnboardingContainerState);
  }

  subscribeCurrentOnboardingContainerState(): Observable<OnboardingContainerState> {
    return this.currentOnboardingContainerState.asObservable();
  }

  subscribePreviousOnboardingContainerState(): Observable<OnboardingContainerState> {
    return this.previousOnboardingContainerState.asObservable();
  }

  publishSelectedOrganization(selectedCompany: OnboardingCompany): void {
    this.selectedOrganization.next(selectedCompany);
  }

  subscribeSelectedOrganization(): Observable<OnboardingCompany> {
    return this.selectedOrganization.asObservable();
  }

  publishCompanyTermCondition(companyTermCondition: Agreement): void {
    this.selectedCompanyTermCondition.next(companyTermCondition);
  }

  subscribeCompanyTermCondition(): Observable<Agreement> {
    return this.selectedCompanyTermCondition.asObservable();
  }

  publishSelectedAccountingSystem(selectedAccountingSystem: AccountingSystem): void {
    this.selectedAccountingSystem.next(selectedAccountingSystem);
  }

  subscribeSelectedAccountingSystem(): Observable<AccountingSystem> {
    return this.selectedAccountingSystem.asObservable();
  }

  publishAccountingTermCondition(accountingTermCondition: Agreement): void {
    this.selectedAccountingTermCondition.next(accountingTermCondition);
  }

  subscribeAccountingTermCondition(): Observable<Agreement> {
    return this.selectedAccountingTermCondition.asObservable();
  }

  publishAccountingSystemIntegrationSuccess(status: boolean): void {
    this.accountingSystemIntegrationSuccess.next(status);
  }

  subscribeAccountingSystemIntegrationSuccess(): Observable<boolean> {
    return this.accountingSystemIntegrationSuccess.asObservable();
  }

  publishSsnExistEmail(email: string): void {
    this.ssnExistEmail.next(email);
  }

  subscribeSsnExistEmail(): Observable<string> {
    return this.ssnExistEmail.asObservable();
  }

  publishSsnExistIdp(idp: string): void {
    this.SsnExistIdp.next(idp);
  }

  subscribeSsnExistIdp(): Observable<string> {
    return this.SsnExistIdp.asObservable();
  }

  publishUserIdentifier(identifier: string): void {
    this.userIdentifier.next(identifier);
  }

  subscribeUserIdentifier(): Observable<string> {
    return this.userIdentifier.asObservable();
  }
}
