<main role="main" class="container-fluid company-selection-dashboard"
    [ngClass]="{' investor-matrix-fullscreen': showFullScreen , ' widget': isWidget}" *ngIf="!isLoading">
    <div class="sticky-top panel-header d-flex justify-content-center" *ngIf='!isWidget'>
        <div class="col-sm-12 col-md-12 col-lg-12 header-container">
            <app-header></app-header>
        </div>
    </div>
    <div class="app-body">
        <div class="panel-left" [ngClass]="{'show-menu': showResponsiveLeftMenu, 'collapsed': leftMenuCollapsed}"
            *ngIf='!isWidget'>
            <app-left-panel [showAccounts]='showAccounts' [onBoardedAccounts]='onBoardedAccounts'
                (menuChanged)='onMenuChanged($event)' (companyChanged)='onSelectedCompanyChanged($event)'
                (menuCollapsed)="onMenuCollapsed($event)">
            </app-left-panel>
        </div>
        <div class="panel-center">
            <router-outlet></router-outlet>
            <router-outlet name="overlayOutlet" (activate)='onActivateOverlayOutlet($event)'
                (deactivate)='onDeactivateOverlayOutlet($event)'></router-outlet>
        </div>
    </div>
</main>
<div id="copilot" *ngIf="displayChatBox" [ngClass]="{'copilot-minimized': isCopilotMinimized}">
    <div class="copilot-toggle copilot-toggle-icon" (click)="toggleCopilot()"></div>
    <iframe [src]="copilotUrl" frameborder="0" style="width: 100%; height: 100%;"></iframe>
</div>
<div id='overlay' *ngIf='isOverlay'>
</div>
<ng-template #modalTrialExpiryMessage let-modal>
    <div class="modal-header">
        <h5 class="modal-title">{{ 'Your trial has ended!' | translate }}</h5>
        <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()">&times;</button>
    </div>
    <div class="modal-body">
        <p>{{ 'Your trial period with Capassa has ended. If you do not choose a subscription, you will be automatically transitioned into our most popular subscription - "Advanced". If you would like to modify your subscription plan, please do so via the Subscriptions page.' | translate }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="goToSubscription()">{{ 'Select subscriptions' | translate }}</button>
        <button type="button" class="btn btn-secondary" (click)="closeModal()">{{ 'Select subscriptions later' | translate }}</button>
    </div>
</ng-template>
