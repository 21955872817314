import { Component, OnDestroy, OnInit } from '@angular/core';

import { Subscription } from 'rxjs';

import { Currencies, DefaultCurrency } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { Currency } from 'src/app/modules/shared/models/currency';

@Component({
  selector: 'app-currency-info',
  templateUrl: './currency-info.component.html',
  styleUrls: ['./currency-info.component.scss']
})
export class CurrencyInfoComponent implements OnInit, OnDestroy {

  currencySubscription: Subscription;
  selectedCurrency: Currency = DefaultCurrency;
  currencyText = 'Norwegian Kroner';
  currencies = Currencies;
  
  constructor(private appStateService: AppStateService) { }

  ngOnInit(): void {
    this.currencySubscription = this.appStateService.subscribeSelectedCurrency().subscribe(currency => {
      this.selectedCurrency = currency;
      this.currencyText = this.selectedCurrency.symbol === Currencies.NOK ? 'Norwegian Kroner' : 'US Dollar';
    });
  }

  ngOnDestroy(): void {
    if (this.currencySubscription) { this.currencySubscription.unsubscribe(); }
  }
}
