import { Component, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';
import { KeyValueDataItem } from 'src/app/modules/data-visualization/models/key-value-data-item';
import { DateRange } from 'src/app/modules/data-visualization/models/date-range';

@Component({
  selector: 'app-prediction-chart',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./../chart-base/chart-base.component.scss']
})
export class PredictionChartComponent extends ChartBaseComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() chartData: KeyValueDataItem[];
  @Input() predictionRange: DateRange | null;
  @Input() fromCategory: string | null;
  @Input() endCategory: string | null;
  @Input() isQuarterlyReport = false;
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    if (this.isQuarterlyReport) {
      this.renderCategoryAxisChart();
    }
    else {
      this.renderChart();
    }
  }

  renderChart(): void {
    this.browserOnly(() => {

      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.XYChart);
      chart.paddingRight = this.chartOptions.paddingRight;
      chart.paddingLeft = this.chartOptions.paddingLeft;
      chart.paddingBottom = this.chartOptions.paddingBottom;
      chart.paddingTop = this.chartOptions.paddingTop;

      am4core.useTheme(am4themes_animated);
      super.chart = chart;
      super.setLocal();
      chart.data = this.chartData;
      this.setChartColors(chart);

      // Create axis
      const primaryAxis = chart.yAxes.push(new am4charts.ValueAxis());
      const categoryAxis = chart.xAxes.push(new am4charts.DateAxis());
      this.setupChartAxes(chart, categoryAxis, primaryAxis);
      primaryAxis.baseValue = 0;

      // Create series
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = this.chartOptions.valueField;
      series.dataFields.dateX = this.chartOptions.keyField;
      series.strokeWidth = this.chartOptions.lineStroke;
      series.fill = chart.colors.getIndex(0);
      series.tensionX = 0.65;

      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
        series.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
      }

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = this.chartOptions.bulletRadius;
      bullet.circle.strokeWidth = this.chartOptions.lineStroke;
      bullet.circle.fill = am4core.color(this.chartOptions.bulletColor);
      bullet.strokeWidth = 0;
      bullet.tooltipText = this.chartOptions.toolTipTextFormat;

      if (this.predictionRange) {
        const range = categoryAxis.createSeriesRange(series);
        range.date = this.predictionRange.fromDate;
        range.endDate = this.predictionRange.toDate;
        range.contents.fill = chart.colors.getIndex(1);
        range.contents.strokeDasharray = '2,3';
      }
    });
  }

  private setupChartAxes(chart: am4charts.XYChart, categoryAxis: am4charts.DateAxis, primaryAxis: am4charts.ValueAxis): void {
    // X axis
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    if (!this.chartOptions.showGrid) {
      categoryAxis.renderer.grid.template.disabled = true;
    }

    const that = this;
    categoryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
      if (text && that.chartOptions.locale === 'nb') {
        text = text.toLowerCase().replace('.', '');
      }

      if (text && text.match(/^[a-zA-Z]+..[0-9]*$/)) {
        text = text.replace(/\d+/g, '');
      }

      return text;
    });

    if (this.mobileDevice) {
      categoryAxis.renderer.maxLabelPosition = 0.98;
    }

    // Left Y axis
    primaryAxis.title.text = this.chartOptions.primaryValueAxisTitle;
    primaryAxis.renderer.line.strokeOpacity = 0.8;
    primaryAxis.renderer.line.strokeWidth = 0.5;
    primaryAxis.renderer.line.stroke = am4core.color(this.chartOptions.labelColor);
    primaryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    primaryAxis.numberFormatter = new am4core.NumberFormatter();
    primaryAxis.numberFormatter.numberFormat = '#a';
    super.setNumberFormatSuffixes(primaryAxis); 
    const hasNegatives = chart.data && chart.data.length ? chart.data.some(v => v.value < 0) : true;
    if (!hasNegatives) {
      primaryAxis.min = 0;
    }

    primaryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
      if (text) {
        text = text.match(/m$/) ? '' : text;
      }
      return text;
    });

    if (this.chartOptions.valueAxisTicks) {
      primaryAxis.renderer.labels.template.disabled = true;
    }
    if (!this.chartOptions.showGrid) {
      primaryAxis.renderer.grid.template.disabled = true;
    }
  }

  private setChartColors(chart: am4charts.XYChart): void {
    this.chartOptions.lineColors.forEach(color => {
      chart.colors.list.push(am4core.color(color));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      if (this.isQuarterlyReport) {
        this.renderCategoryAxisChart();
      }
      else {
        this.renderChart();
      }
    }
  }

  renderCategoryAxisChart(): void {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.XYChart);
      chart.paddingRight = this.chartOptions.paddingRight;
      chart.paddingLeft = this.chartOptions.paddingLeft;
      chart.paddingBottom = this.chartOptions.paddingBottom;
      chart.paddingTop = this.chartOptions.paddingTop;

      am4core.useTheme(am4themes_animated);
      chart.data = this.chartData;
      super.chart = chart;
      super.setLocal();
      this.setChartColors(chart);

      // Create axis
      const primaryAxis = chart.yAxes.push(new am4charts.ValueAxis());
      const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
      categoryAxis.dataFields.category = this.chartOptions.keyField;
      this.setupCategoryAxisChartAxes(chart, categoryAxis, primaryAxis);
      primaryAxis.baseValue = 0;

      // Create series
      const series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = this.chartOptions.valueField;
      series.dataFields.categoryX = this.chartOptions.keyField;
      series.strokeWidth = this.chartOptions.lineStroke;
      series.fill = chart.colors.getIndex(0);
      series.tensionX = 0.65;

      if (series.tooltip) {
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
        series.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
      }

      const bullet = series.bullets.push(new am4charts.CircleBullet());
      bullet.circle.radius = this.chartOptions.bulletRadius;
      bullet.circle.strokeWidth = this.chartOptions.lineStroke;
      bullet.circle.fill = am4core.color(this.chartOptions.bulletColor);
      bullet.strokeWidth = 0;
      bullet.tooltipText = this.chartOptions.toolTipTextFormat;

      if (this.fromCategory && this.endCategory) {
        const range = categoryAxis.createSeriesRange(series);
        range.category = this.endCategory!;
        range.endCategory = this.endCategory!;        
        range.contents.fill = chart.colors.getIndex(1);
        range.contents.strokeDasharray = '2,3';
      } 
    });
  }

  private setupCategoryAxisChartAxes(chart: am4charts.XYChart, categoryAxis: am4charts.CategoryAxis, primaryAxis: am4charts.ValueAxis): void {
    // X axis
    categoryAxis.renderer.minGridDistance = 30;
    categoryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    if (!this.chartOptions.showGrid) {
      categoryAxis.renderer.grid.template.disabled = true;
    }

    if (this.mobileDevice) {
      categoryAxis.renderer.maxLabelPosition = 0.98;
    }

    // Left Y axis
    primaryAxis.title.text = this.chartOptions.primaryValueAxisTitle;
    primaryAxis.renderer.line.strokeOpacity = 0.8;
    primaryAxis.renderer.line.strokeWidth = 0.5;
    primaryAxis.renderer.line.stroke = am4core.color(this.chartOptions.labelColor);
    primaryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    primaryAxis.numberFormatter = new am4core.NumberFormatter();
    primaryAxis.numberFormatter.numberFormat = '#a';
    super.setNumberFormatSuffixes(primaryAxis); 
    const hasNegatives = chart.data && chart.data.length ? chart.data.some(v => v.value < 0) : true;
    if (!hasNegatives) {
      primaryAxis.min = 0;
    }

    primaryAxis.renderer.labels.template.adapter.add('textOutput', (text) => {
      if (text) {
        text = text.match(/m$/) ? '' : text;
      }
      return text;
    });

    if (this.chartOptions.valueAxisTicks) {
      primaryAxis.renderer.labels.template.disabled = true;
    }
    if (!this.chartOptions.showGrid) {
      primaryAxis.renderer.grid.template.disabled = true;
    }
  }
}
