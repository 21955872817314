import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injector } from '@angular/core';

import { BaseApiService } from './base-api.service';

export class JsonApiService extends BaseApiService {

  constructor(baseUrl: string, httpClient: HttpClient,
    injector: Injector) {
    super(baseUrl, httpClient, injector);
  }
}
