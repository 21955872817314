export const environment = {
  production: false,
  ApiBaseAuthUrl: 'https://digitalcfo-dev.capassa.com',
  ApiBaseUrl: 'https://digitalcfo-dev.capassa.com',
  customerWebPortalUrl: 'https://digitalcfo-dev.capassa.com',
  //customerWebPortalUrl: 'https://ti-nb-321.local/customer-web-portal',
  RegistrationApi: '/api/registration',
  clientFinanceApi: '/api/clientFinance',
  syncDataApi: '/api/syncdata',
  administrationApi: '/api/administration',
  scorecardApi: '/api/scorecard',
  accountingAgreementId: '3F316D5D-0AF7-415A-8992-03A01AE3A073',
  mobileSchema: 'https://com.capassa.digcfo.dev://oauth/redirect',
  capassaHomePage: 'https://capassastorage.z6.web.core.windows.net/dev',
  mobileUniversalLink: 'https://capassa.com',
  downloadPackage: 'https://digital-cfo-dev.capassa.com/deep-link-customer-web-portal/home/download-package',
  redirectToMobile: 'https://digital-cfo-dev.capassa.com/deep-link-customer-web-portal/home/redirect-to-mobile',
  deepLinkingToHome: 'https://digital-cfo-dev.capassa.com/deep-link-customer-web-portal/home',
  faqPage: 'https://digitalcfo-dev.capassa.com/admin-portal/en-US/faqview',
  hubspotPortalId: '145136693',
  hubspotContactUsFormId: 'cd326388-4202-4916-8b5e-5a93e48f2c74',
  fikenAPIActivation: ' https://fiken.no/til/abonnement',
  brregBaseUrl: 'https://data.brreg.no/enhetsregisteret/api',
  firebase: {
    apiKey: "AIzaSyDGA4IAOCAMAdGGZ0MIwohwdqTV1Qg0ih0",
    authDomain: "digital-cfo-dev.firebaseapp.com",
    databaseURL: "https://digital-cfo-dev.firebaseio.com",
    projectId: "digital-cfo-dev",
    storageBucket: "digital-cfo-dev.appspot.com",
    messagingSenderId: "325421352282",
    appId: "1:325421352282:web:b88f71731328ad5112df63",
    measurementId: "G-EWTSWEVV5Z"
  },
  signalRHub: '/api/notificationhub',
  signalRServerTimeout: 86400000,
  version: '1.0',
  defaultSignUpSignInMethod: 'EmailSignUpSignIn',
  marketplaceEmail: 'capassaone@gmail.com',
  learnMorePage: 'https://www.capassa.com/markedsplassen/',
  currencyApiBaseUrl:'https://data.norges-bank.no/api',
  budgetQueryInterval: 60000,
  MaxFileSizeAllowed:26214400,
  FileExtensionAllowed:['jpg','png','jpeg','gif','bmp','xlsx','xls','docx','doc','pdf','ppt','pptx'],
  googleAnalyticsKey: 'G-4DFHXEY3YV',
  googleAnalyticsTabManagerKey: 'GTM-MKCML86G',
  clientType:'CustomerWebPortal',
  autoBudgetCalcDate:'2024-08-11',
  copilotUrl: 'https://capassastorage.blob.core.windows.net/microsoft-copilot/index.html'
};