import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { environment } from 'src/environments/environment';
import { AccountSyncServiceStatus } from 'src/app/modules/core/models/account-sync-service-status';


@Injectable()
export class SyncService {

    constructor(private api: CapassaApiService) { }

    getAccountingSystemSyncStatus(): Observable<AccountSyncServiceStatus> {
        return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/account/accounting-system/sync-status`);
    }
}
