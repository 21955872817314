import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';

import { environment } from 'src/environments/environment';
import { KeywordResult } from 'src/app/modules/shared/models/keyword-result';
import { SearchKeyword } from 'src/app/modules/shared/models/search-keyword';

@Injectable({
  providedIn: 'root',
})

export class SearchService {
  constructor(private api: CapassaApiService) { }

  getResultByKeyWord(searchKeyword: SearchKeyword): Observable<KeywordResult[]> {

    return this.api.getJsonApiService().post(environment.administrationApi + `/v1.0/search-keyword`, searchKeyword);
  }
}
