<div class="capassa-modal">
    <div class="capassa-modal-content">
        <div [ngClass]="iconClass" *ngIf="iconEnable"></div>
        <h1 class="h1">{{ messageHeaderKey | translate }}</h1>
        <div class="app-message error-message">
            {{ messageContent | translate }}
        </div>
        <button class="btn btn-primary" (click)="logout()">
            {{ buttonContent | translate }}
        </button>
    </div>
</div>