import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { FileManagerDocumentFormData, FileManagerPopupData } from 'src/app/modules/shared/models/capassa-file-manager.model';
import { FileManagerChangeEventType, FileManagerReportType } from 'src/app/modules/shared/models/capassa-file-manager.enum';

@Component({
  selector: 'app-capassa-file-manager-modal',
  templateUrl: './capassa-file-manager-modal.component.html',
  styleUrls: ['./capassa-file-manager-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CapassaFileManagerModalComponent implements OnInit {

  @Input() data: FileManagerPopupData = new FileManagerPopupData();
  @Output() clickChange = new EventEmitter<FileManagerDocumentFormData>();
  @Output() clickExit = new EventEmitter<FileManagerDocumentFormData | null>();
  @Output() clickClose = new EventEmitter();
  @Output() clickUpdateSuccess = new EventEmitter();
  @Output() clickSubmit = new EventEmitter<FileManagerDocumentFormData>();
  changeEventType = FileManagerChangeEventType;
  form: FormGroup;
  editForm: FormGroup;
  submitted = false;
  fileManagerReportType = FileManagerReportType;
  selectedReportType: FileManagerReportType = FileManagerReportType.PnLReport;
  browserLanguage: string;
  translations: any;
  description: string = '';

  constructor(private appStateService: AppStateService, private translate: TranslateService, private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      title: [this.data?.messageContent ?? '', [Validators.required]]
    });
    this.editForm = this.formBuilder.group({
      dataRoomTitle: [this.data?.key ?? '', [Validators.required]],
      dataRoomDescription: [this.data?.messageContent ?? '', []]
    });

    this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });

    this.translate.getTranslation(this.browserLanguage).subscribe((translationObj) => {
      this.translations = translationObj;
    });
  }

  get formFields(): any {
    return this.form.controls;
  }

  get editFormFields(): any {
    return this.editForm.controls;
  }

  onFormSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) { return; }
    const formData: FileManagerDocumentFormData = { title: this.form.value.title, type: this.data.type, parentId: this.data.parentId, key: this.data.key, selected: '' };
    this.clickSubmit.emit(formData);
  }

  onEditFormSubmit(): void {
    this.submitted = true;
    if (this.editForm.invalid) { return; }
    const formData: FileManagerDocumentFormData = { ...new FileManagerDocumentFormData(), title: this.editForm.value.dataRoomTitle,
      type: this.data.type, key: this.data.key, selected: this.editForm.value.dataRoomDescription };
    this.clickSubmit.emit(formData);
  }

  save(): void {
    let result: FileManagerDocumentFormData;
    switch (this.data.type) {
      case FileManagerChangeEventType.ChooseCapassaFile:
        result = { ...new FileManagerDocumentFormData(), type: this.data.type, selected: this.selectedReportType };
        break;
      case FileManagerChangeEventType.MaximumStorageWarning:
        result = { ...new FileManagerDocumentFormData() };
        break;
      case FileManagerChangeEventType.DeleteDataRoom:
        result = { ...new FileManagerDocumentFormData(), type: this.data.type, key: this.data.key };
        break;
      default:
        result = { ...new FileManagerDocumentFormData(), parentId: this.data.parentId, title: '', type: this.data.type, key: this.data.key };
        break;
    }
    this.clickChange.emit(result);
  }

  exit(): void {
    let formData = { ...new FileManagerDocumentFormData(), type: this.data?.type };
    switch (this.data.type) {
      case FileManagerChangeEventType.DeleteDataRoom:
        formData = { ...new FileManagerDocumentFormData(), type: this.data.type, key: this.data.key };
        break;
      case FileManagerChangeEventType.MaximumStorageWarning:
        return;
      default:
        break;
    }
    this.clickExit.emit(formData);
  }

  close(): void {
    this.clickClose.emit(true);
  }

  updateSuccess(): void {
    this.clickUpdateSuccess.emit(true);
  }

  selectOption(type: FileManagerReportType): void {
    this.selectedReportType = type;
    this.save();
    const reportTypeElements = document.querySelectorAll('.report-type');

    reportTypeElements.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();

        reportTypeElements.forEach((el) => {
          el.classList.remove('selected');
        });

        element.classList.add('selected');
      });
    });
  }
}
