import { Component, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';
import { GaugeValueItem } from 'src/app/modules/data-visualization/models/gauge-value-item';
import { GaugeCategoryValueItem } from 'src/app/modules/data-visualization/models/gauge-category-value-item';

@Component({
  selector: 'app-gauge-chart-v2',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./gauge-chart-v2.component.scss']
})
export class GaugeChartComponentV2 extends ChartBaseComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() chartData: GaugeValueItem;
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  renderChart(): void {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.GaugeChart);
      chart.hiddenState.properties.opacity = 0;
      chart.fontSize = 11;
      chart.innerRadius = this.chartOptions?.gaugeChartWidthRadius;
      chart.resizable = true;
      chart.startAngle = -200;
      chart.endAngle = 20;
      /**
      * Normal axis
      */
      const axis = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
      axis.min = this.chartOptions?.gaugeChartMinValue;
      axis.max = this.chartOptions?.gaugeChartMaxValue;
      axis.renderer.labels.template.disabled = true;
      axis.renderer.inside = true;
      axis.renderer.labels.template.radius = 45;

      /**
     * Axis for ranges
     */
      const axis2 = chart.xAxes.push(new am4charts.ValueAxis<am4charts.AxisRendererCircular>());
      axis2.min = 0;
      axis2.max = 10;
      axis2.strictMinMax = true;
      axis2.renderer.labels.template.disabled = true;
      axis2.renderer.ticks.template.disabled = true;
      axis2.renderer.grid.template.disabled = true;
      axis2.renderer.grid.template.opacity = 0;
      axis2.renderer.labels.template.fill = am4core.color(this.chartOptions?.showGaugeChartDefaultColor);
      axis2.renderer.labels.template.fontWeight = "bold";
      axis2.renderer.labels.template.fillOpacity = 0;

      /**
      Ranges
      */
      this.chartData?.categories.forEach((grading: GaugeCategoryValueItem) => {
        const range = axis2.axisRanges.create();
        range.axisFill.fill = am4core.color(grading.color);
        range.axisFill.fillOpacity = 0;
        range.axisFill.zIndex = -1;
        range.value = grading.min > 0 ? grading.min : 0;
        range.endValue = grading.max < 10 ? grading.max : 10;
        range.grid.strokeOpacity = 0;
        range.grid.opacity = 0;
        range.label.inside = false;
        range.label.location = 0.5;
        range.label.paddingBottom = -5; // ~half font size
      });

      if (this.chartOptions?.showGaugeChartCenterContent) {
        /**
         * Label 1
        */
        var label = chart.radarContainer.createChild(am4core.Label);
        label.isMeasured = false;
        label.fontSize = this.chartOptions?.gaugeChartCenterLabelFontSize;
        label.fontWeight = "bold";
        label.paddingTop = -40;
        label.horizontalCenter = "middle";
        label.text = this.chartData?.valueText;
        label.fill = am4core.color(this.chartOptions?.showGaugeChartDefaultColor);

        /**
         * Label 2
         */

        var label2 = chart.radarContainer.createChild(am4core.Label);
        label2.isMeasured = false;
        label2.fontSize = this.chartOptions?.gaugeChartCenterSubLabelFontSize;
        label2.horizontalCenter = "middle";
        label2.text = this.chartData?.label;
        label2.fill = am4core.color(this.chartOptions?.showGaugeChartDefaultColor);

        if (this.chartData?.subLabel) {
          var label3 = chart.radarContainer.createChild(am4core.Label);
          label3.isMeasured = false;
          label3.fontSize = this.chartOptions?.gaugeChartCenterSubLabelFontSize;
          label3.horizontalCenter = "middle";
          label3.text = this.chartData?.subLabel;
          label3.paddingTop = 20;
          label3.fill = am4core.color(this.chartOptions?.showGaugeChartDefaultColor);
        }
        /**
         * Hand
         */
        var hand = chart.hands.push(new am4charts.ClockHand());
        var gaugeValue = this.chartData?.value;
        hand.axis = axis2;
        hand.innerRadius = am4core.percent(55);
        hand.startWidth = 3;
        hand.pin.disabled = true;
        hand.value = this.chartOptions.animate ? 0 : gaugeValue;
        hand.fill = am4core.color(this.chartOptions?.showGaugeChartDefaultColor);
        hand.stroke = am4core.color(this.chartOptions?.showGaugeChartDefaultColor);

        if (this.chartOptions.animate) {
          setTimeout(function () {
            hand.showValue(gaugeValue, 1500, am4core.ease.cubicOut);
          }, 2000);
        }
      }

      super.chart = chart;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      this.renderChart();
    }
  }

}
