<div class="data-room-folders block-report-home">
  <section class="header-report-home">
    <div class="aa">
      <div class="col-a">
        <app-back-button>
          <div class="action-back">{{'Back'| translate}}</div>
        </app-back-button>
        <h1 class="h1">{{'Data room'| translate}}</h1>
      </div>

      <div class="col-b">
        <section class="header-page-top">
          <div class="buttons-wrapper">
            <button class="btn btn-primary upload-icon-solid" id="btnOpenPopup">
              <span>{{'Upload from desktop'| translate}}</span>
            </button>
            <input #fileInput id="fileInput" type="file" style="display:none" (change)="uploadFile(fileInput)"
              accept=".jpg, .jpeg, .png, .gif, .bmp, .xlsx, .xls, .docx, .doc, .pdf">

            <button *ngIf="showUploadCapassaButton && canUploadFiles" class="btn btn-primary upload-icon-solid btn-upload-capassa" (click)="showChooseCapassaFilePopup()">
              <span>{{'Upload from Capassa'| translate}}</span>
            </button>
          </div>
        </section>
      </div>
    </div>
  </section>

  <section class="overflow-box doc-fullscreen" [ngClass]="downloadInProgress ? 'hide-grid-container' : ''">
    <app-breadcrumb *ngIf="breadcrumbDocumentList && breadcrumbDocumentList.length && selectedDocumentId"
      [selectedId]="selectedDocumentId" [breadcrumbList]="breadcrumbDocumentList"
      (changeSelection)="changeSelection($event)"></app-breadcrumb>
    <div class="data-rooms-list table-container">
      <table id="table">
      </table>

      <div class="fancytree-grid-container flexbox-item-grow">
        <table id="documentTable" class="fancytree-grid-container flexbox-item-grow">
          <thead>
            <tr>
              <th></th>
              <th class="document-name parent-path">{{'Name'| translate}}</th>
              <th class="updated-on">{{'Date changed'| translate}}</th>
              <th class="changed-by">{{'Changed by'| translate}}</th>
              <th class="document-size">{{'File size'| translate}}</th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </section>

  <app-file-manager-progress class="overflow-box doc-fullscreen" *ngIf="downloadInProgress"
    [folderName]="uploadFolderTitle" [fileName]="uploadFileName"></app-file-manager-progress>
</div>
