import { TranslateLoader } from '@ngx-translate/core';
import { Observable, from, merge } from 'rxjs';

export class TranslateLoaderFactory {

    static appAvailableLanguages: Array<string> = ['en', 'nb'];
    static defaultLanguage = 'en';

    constructor() { }

    static forModule(module: string): any {
        return class LazyTranslateLoader implements TranslateLoader {
            getTranslation(lang: string): Observable<any> {
                if (!TranslateLoaderFactory.appAvailableLanguages.includes(lang)) {
                    return merge(
                        from(import(`./../../../assets/i18n/${module}/${TranslateLoaderFactory.defaultLanguage}.json`))
                    );
                }
                return merge(
                    from(import(`./../../../assets/i18n/${module}/${lang}.json`))
                );
            }
        }
    }
}


