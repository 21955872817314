import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'decimalFormat'
})
export class DecimalFormatPipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) { }

  transform(value: any, ...args: string[]): string {
    let formattedValue = '0';
    let separator = '.';
    if (args[0] === 'nb') {
      separator = ',';
    }

    if (value) {
      let decimalFormat = this.decimalPipe.transform(value, '1.2-2');
      if (decimalFormat) {
        decimalFormat =  decimalFormat.replace(',', ' ');
        formattedValue = decimalFormat.replace('.', separator);
      }
    }
    return formattedValue;
  }
}
