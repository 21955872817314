import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { Subscription } from 'rxjs';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { ChartOptions } from 'src/app/modules/data-visualization/models/chart-options';
import { GaugeCategoryValueItem } from 'src/app/modules/data-visualization/models/gauge-category-value-item';
import { GaugeValueItem } from 'src/app/modules/data-visualization/models/gauge-value-item';
import { CapassaIndex } from 'src/app/modules/shared/models/capassa-index';
import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { CapassaIndexColorCodeConstants, CapassaIndexValueConstants, PageOrigins, ReportType, RouteLinkConstants } from 'src/app/app.constants';

@Component({
  selector: 'app-capassa-index',
  templateUrl: './capassa-index.component.html',
  styleUrls: ['./capassa-index.component.scss']
})
export class CapassaIndexComponent implements OnInit, OnChanges {

  @Input() latestCapassaIndex: CapassaIndex;
  @Input() reportMonth: Date;
  @Input() reportType = ReportType.monthly;
  @Input() quarter = '';
  @Input() showSeeMore = true;
  browserLanguage: string;
  selectedLanguageSubscription: Subscription;
  translateSubscription: Subscription;
  toggleCapassaIndex = false;
  norwegianLanguageCode = 'nb';
  chartData: GaugeValueItem;
  chartOptions: ChartOptions;

  constructor(private appStateService: AppStateService, public router: Router, private utilityService: UtilityService) { }

  ngOnInit(): void {
    this.initializeChartOptions();
    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
      this.drawCapassaIndexChart();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.latestCapassaIndex) {
      this.ngOnInit();
    }
  }

  drawCapassaIndexChart(): void {
    if (this.latestCapassaIndex) {
      this.reportMonth = this.latestCapassaIndex.date;
      this.chartData = new GaugeValueItem();
      this.chartData.categories = this.initializeCapassaIndexCategories();
      const latestQuarter = this.utilityService.getQuarterByDate(new Date(this.latestCapassaIndex.date));
      this.chartData.label = this.reportType === ReportType.quarterly ? `Q${latestQuarter}-${new Date(this.reportMonth).getFullYear()}` : this.utilityService.getLocalDate(this.latestCapassaIndex.date, this.browserLanguage, 'MMMM YYYY', true);
      this.chartData.value = this.latestCapassaIndex.value;
      this.chartData.valueText = this.utilityService.formatNumberToTwoDecimalPoints(this.latestCapassaIndex.value, this.browserLanguage);
    }
  }

  initializeChartOptions(): void {
    this.chartOptions = new ChartOptions();
    this.chartOptions.showGaugeChartCenterContent = true;
    this.chartOptions.labelTextFontSize = 20;
    this.chartOptions.locale = this.browserLanguage;
  }

  initializeCapassaIndexCategories(): GaugeCategoryValueItem[] {
    const categories = new Array<GaugeCategoryValueItem>();
    categories.push({
      category: CapassaIndexValueConstants.indexZero.toFixed(1), color: CapassaIndexColorCodeConstants.indexVeyLowColor, max: CapassaIndexValueConstants.indexLow,
      min: CapassaIndexValueConstants.indexZero
    });
    categories.push({
      category: CapassaIndexValueConstants.indexLow.toFixed(1), color: CapassaIndexColorCodeConstants.indexLowColor, max: CapassaIndexValueConstants.indexHigh,
      min: CapassaIndexValueConstants.indexLow
    });
    categories.push({
      category: CapassaIndexValueConstants.indexHigh.toFixed(1), color: CapassaIndexColorCodeConstants.indexHighColor, max: CapassaIndexValueConstants.indexVeryHigh,
      min: CapassaIndexValueConstants.indexHigh
    });
    categories.push({
      category: CapassaIndexValueConstants.indexVeryHigh.toFixed(1), color: CapassaIndexColorCodeConstants.indexVeryHighColor, max: CapassaIndexValueConstants.indexMax,
      min: CapassaIndexValueConstants.indexVeryHigh
    });
    return categories;
  }

  capassaDetailNavigation(): void {
    let navigationFrom = RouteLinkConstants.dashboard;

    if (this.router?.url?.includes('reports')) {
      navigationFrom = this.reportType === ReportType.quarterly ?
        `${RouteLinkConstants.reports}?year=${new Date(this.reportMonth).getFullYear()}&quarter=${this.quarter ? this.quarter[1] : this.quarter}` :
        RouteLinkConstants.reports;
    } else if (this.router?.url?.includes('home')) {
      navigationFrom = RouteLinkConstants.landingPage;
    } else if (this.router?.url?.includes('balance-sheet')) {
      navigationFrom = this.reportType === ReportType.quarterly ?
        `${RouteLinkConstants.balanceSheet}?year=${new Date(this.reportMonth).getFullYear()}&quarter=${this.quarter ? this.quarter[1] : this.quarter}` :
        RouteLinkConstants.balanceSheet;
    }

    this.router.navigate([RouteLinkConstants.capassaIndexDetails],
      {
        queryParams: {
          origin: PageOrigins.capassaIndexDetails,
          title: '',
          reportDate: moment(this.reportMonth).locale('en').format('DD-MMM-YYYY').toString(),
          enableBackButton: true,
          navigationFrom,
          isQuarterlyReport: this.reportType === ReportType.quarterly
        }
      });
  }
}
