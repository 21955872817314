export enum KeyFinancialEnums {
  grossProfit = 15,
  grossProfitMargin = 16,
  operatingProfitMargin = 17,
  ebitda = 24,
  ebitdaMargin = 28,
  ebit = 29,
  ebitMargin = 30,
  netProfitMargin = 18,
  assets = 0,
  cashAndCashEquivalents = 34,
  liability = 2,
  equity = 1,
  equityRatio = 19,
  nibd = 25,
  nibdEbitda = 26,
  workingCapital = 20,
  freeCashFlow = 23,
  currentRatio = 21,
  quickRatio = 22,
  undistributedProfitLoss = 27,
  burnRate = 32,
  cashRunway = 33,
  nibdCash = 35,
  debtServiceCoverageRatio = 40,
  interestCoverageRatio = 39,
  employeeCount = 36,
  roe = 38,
  roce = 37,
  roa = 41
}
