<div class="capassa-modal capassa-modal-common">
  <div class="capassa-modal-content">
    <div [ngClass]="data.iconClass" *ngIf="data.closeIconEnable"></div>
    <a *ngIf="data.closeIconEnable" class="link link-close" (click)="close()"></a>
    <h1 class="h1">{{ data.messageHeaderKey | translate }}</h1>
    <p class="app-message success-message">
      {{ data.messageContent |translate }}
    </p>
    <button *ngIf="data.buttonContent2" class="btn btn-primary" (click)="exit()">
      <span style="font-size:16px;">{{ data.buttonContent2 | translate }}</span>
    </button>
    <button *ngIf="!data.buttonContent3" class="btn btn-secondary" (click)="logout()">
      <span style="font-size:16px;">{{ data.buttonContent | translate }}</span>
    </button>
    <button *ngIf="data.buttonContent3" class="btn btn-primary" (click)="updateSuccess()">
      <span style="font-size:16px;">{{ data.buttonContent3 | translate }}</span>
    </button>

  </div>
</div>