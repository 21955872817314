import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';

import { RouteLinkConstants, ActionIds } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { SignalRMessageSubscriberService } from 'src/app/modules/core/common-services/signalr-message-subscriber.service';
import { SyncService } from 'src/app/modules/core/common-services/sync-service.service';
import { AccountSyncServiceStatus } from 'src/app/modules/core/models/account-sync-service-status';
import { AccountSyncStatus } from 'src/app/modules/core/models/account-sync-status';
import { NotificationKeys } from 'src/app/modules/core/models/notification-keys';
import { NotificationType } from 'src/app/modules/core/models/notification-type';
import { GlobalNotification } from 'src/app/modules/core/models/notification.model';
import { SyncStatus } from 'src/app/modules/core/models/sync-status';
import { UserAction, UserFlow } from 'src/app/modules/shared/globals';
import { AccountingSystemIntegrationStatus } from 'src/app/modules/shared/models/accounting-system-integration-status';
import { AccountingSystemIntegration } from 'src/app/modules/shared/models/accounting-system-status';
import { NavigationParamsService } from 'src/app/modules/shared/services/navigation-params-service';
import { AccountDataService } from 'src/app/modules/shared/services/account-user-data.service';
import { UserFlowService } from 'src/app/modules/shared/services/user-flow.service';
import { NoAccessBaseComponent } from 'src/app/modules/shared/components/no-access-base/no-access-base.component';
import { UserPermissionService } from 'src/app/modules/shared/services/user-permission.service';

@Component({
  selector: 'app-no-accounting-system-access',
  templateUrl: './no-accounting-system-access.component.html',
  styleUrls: ['./no-accounting-system-access.component.scss']
})
export class NoAccountingSystemAccessComponent extends NoAccessBaseComponent implements OnInit, OnDestroy {

  selectedLanguageSubscription: Subscription;
  language: string;
  hasAccess = false;
  showNoAccess = false;
  showNoIntegrationMsg = false;
  showNoIntegrationFailedMsg = false;
  userActionSubscription: Subscription;
  selectedAccountSubscription: Subscription;
  selectedAccountId: string;
  integrationMessageSubscription: Subscription;
  selectedAccountingSystemSubscription: Subscription;
  accountingSystemIntegrationSubscription: Subscription;
  accountingSystemReconnectionSubscription: Subscription;
  accountingSystemSyncSubscription: Subscription;
  userFlow: UserFlow;
  navigationParams: any;
  enableCustomHeader = false;
  headerTitle = '';
  isReconnected = false;
  constructor(public router: Router, private appStateService: AppStateService, private navigationParamsService: NavigationParamsService,
    private translate: TranslateService, private signalRMessageSubscriberService: SignalRMessageSubscriberService, private accountDataService: AccountDataService,
    private syncService: SyncService, private userFlowService: UserFlowService, public userPermissionService: UserPermissionService) {
    super(router, userPermissionService);
  }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.navigationParamsService.registerNavigationParams();
    this.navigationParams = this.navigationParamsService.getNavigationParam();
    this.enableCustomHeader = this.navigationParams?.title ? true : false;
    this.headerTitle = this.navigationParams?.title;
    this.userFlow = this.userFlowService.getFlow();
    this.validateAccessOnRefresh(this.userFlow, this.headerTitle);
    this.selectedAccountId = this.accountDataService.getSelectedAccount().id;

    this.accountingSystemSyncSubscription = this.syncService.getAccountingSystemSyncStatus().subscribe((accountingSystemStatus: AccountSyncServiceStatus) => {
      switch (accountingSystemStatus.syncStatus) {
        case AccountSyncStatus.Started:
        case AccountSyncStatus.Failed:
          this.showNoIntegrationMsg = true;
          this.hasAccess = false;
          break;
      }
    }, (error: any) => {
      this.showNoIntegrationMsg = false;
      this.hasAccess = !this.showNoAccess;
    });

    this.accountingSystemIntegrationSubscription = this.appStateService.subscribeAccountingSystemIntegration().subscribe((accountingSystemIntegration: AccountingSystemIntegration) => {
      if (this.selectedAccountId === accountingSystemIntegration.accountId) {
        switch (accountingSystemIntegration.status) {
          case AccountingSystemIntegrationStatus.Started:
            this.showNoIntegrationMsg = true;
            this.hasAccess = false;
            break;
        }
      }
    });

    this.accountingSystemReconnectionSubscription = this.appStateService.subscribeAccountingSystemReconnectionStatus().subscribe((isReconnected: boolean) => {
      this.isReconnected = isReconnected;
    });

    this.integrationMessageSubscription = this.signalRMessageSubscriberService.subscribeNotificationChannel().subscribe((message: GlobalNotification) => {
      if (message && message.data) {
        const notificationMessage = message.data;
        if (this.selectedAccountId && this.selectedAccountId === notificationMessage.AccountId) {
          if (message.typeId === NotificationType.TransformSyncData &&
            notificationMessage.SyncStatusId === SyncStatus.Completed) {
            switch (notificationMessage.MessageKey) {
              case NotificationKeys.TRANSFORM_ONBOARDING_ACCOUNTING_SYSTEM_COMPLETED_KEY:
                this.showNoIntegrationMsg = false;
                if (this.integrationMessageSubscription) { this.integrationMessageSubscription.unsubscribe(); }
                this.publishAccountingSystemIntegration(AccountingSystemIntegrationStatus.Completed);
                this.signalRMessageSubscriberService.stopConnection();
                this.router.navigateByUrl(this.navigationParams.redirectUrl);                
                break;
              case NotificationKeys.TRANSFORM_ONBOARDING_ACCOUNT_COMPLETED_KEY:
                this.publishAccountingSystemIntegration(AccountingSystemIntegrationStatus.Completed);
                break;
            }
          }
          else {
            switch (notificationMessage.MessageKey) {
              case NotificationKeys.TRANSFORM_ONBOARDING_ACCOUNTING_SYSTEM_DATA_INPROGRESS_KEY:
              case NotificationKeys.IMPORTING_ACCOUNTING_DATA_INPROGRESS_KEY:
              case NotificationKeys.IMPORTING_ACCOUNTING_DATA_COMPLETED_KEY:
                this.showNoIntegrationMsg = true;
                this.hasAccess = false;
                this.publishAccountingSystemIntegration(AccountingSystemIntegrationStatus.InProgress);
                break;
            }
          }
        }
      }
    });

    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.language = language;
      this.translate.setDefaultLang(this.language);
      this.translate.use(this.language);
    });

    this.userActionSubscription = this.appStateService.subscribeUserActions().subscribe(actions => {
      if (!this.showNoIntegrationMsg) {
        this.hasAccess = actions.find(action => action === UserAction.AddAccountingIntegration) ? true : false;
        this.showNoAccess = this.hasAccess ? false : true;
      }
    });
  }

  publishAccountingSystemIntegration(integrationStatus: AccountingSystemIntegrationStatus): void {
    const accountingSystemIntegration = new AccountingSystemIntegration();
    accountingSystemIntegration.accountId = this.selectedAccountId;
    accountingSystemIntegration.status = integrationStatus;
    this.appStateService.publishAccountingSystemIntegration(accountingSystemIntegration);
  }

  showAccountPopup(): void {
    this.router.navigate([{ outlets: { overlayOutlet: RouteLinkConstants.onboardingCompany } }],
      { queryParams: { userFlow: this.userFlow, title: this.headerTitle } });
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) { this.selectedLanguageSubscription.unsubscribe(); }
    if (this.userActionSubscription) { this.userActionSubscription.unsubscribe(); }
    if (this.selectedAccountSubscription) { this.selectedAccountSubscription.unsubscribe(); }
    if (this.integrationMessageSubscription) { this.integrationMessageSubscription.unsubscribe(); }
    if (this.selectedAccountingSystemSubscription) { this.selectedAccountingSystemSubscription.unsubscribe() };
    if (this.accountingSystemSyncSubscription) { this.accountingSystemSyncSubscription.unsubscribe() };
    if (this.accountingSystemIntegrationSubscription) { this.accountingSystemIntegrationSubscription.unsubscribe() };
    if (this.accountingSystemReconnectionSubscription) { this.accountingSystemReconnectionSubscription.unsubscribe() };
  }

  validateAccessOnRefresh(userFlow: UserFlow, title: string): void {
    let permissionIds: number[] = [];
    let moduleName = '';
    switch (userFlow) {
      case UserFlow.PredictionIntegration:
        permissionIds = [ActionIds.viewPredictions];
        moduleName = 'analytics';
        break;
      case UserFlow.KeyFinancialHomeIntegration:
        permissionIds = [ActionIds.viewKeyFinancial];
        moduleName = 'analytics';
        break;
      case UserFlow.ReportIntegration:
        permissionIds = [ActionIds.viewKeyFinancial, ActionIds.viewMonthlyReportCard];
        moduleName = 'analytics';
        break;
      case UserFlow.DashboardIntegration:
        permissionIds = [ActionIds.viewProfitAndLossGraph];
        moduleName = 'analytics';
        break;
      case UserFlow.BudgetIntegration:
        permissionIds = [ActionIds.viewKeyFinancial];
        moduleName = 'budget';
        break;
      case UserFlow.ChangeBudgetIntegration:
        permissionIds = [ActionIds.viewKeyFinancial];
        moduleName = 'budget';
        break;
      case UserFlow.BalanceSheetIntegration:
        permissionIds = [ActionIds.viewKeyFinancial];
        moduleName = 'analytics';
        break;
      case UserFlow.BudgetComparisonIntegration:
        permissionIds = [ActionIds.viewKeyFinancial];
        moduleName = 'budget';
        break;
      case UserFlow.LiquidityIntegration:
        permissionIds = [ActionIds.viewKeyFinancial];
        moduleName = 'analytics';
        break;
      case UserFlow.AddCompany:
        permissionIds = [ActionIds.viewDashboard];
        moduleName = 'analytics';
        break;
    }
    super.ngOnUpdate({ permissionIds: permissionIds, moduleName: moduleName, navigationParmString: `title=${title}` })
  }
}
