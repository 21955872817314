import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuccessModalComponent implements OnInit {

  @Input() messageHeaderKey: string = '';
  @Input() messageContent: string = '';
  @Input() buttonContent: string = '';
  @Input() iconEnable: boolean = false;
  @Input() iconClass: string = '';
  @Output() onClickChange = new EventEmitter();

  constructor(private appStateService: AppStateService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });
  }

  logout() {
    this.onClickChange.emit(true);
  }
}
