import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { AccountUser } from 'src/app/modules/shared/models/account-user';
import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { environment } from 'src/environments/environment';
import { AccountMonthlyData } from 'src/app/modules/shared/models/account-monthly-data';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { Account } from 'src/app/modules/shared/models/account';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AccountDataService {

  constructor(private api: CapassaApiService, private sharedStorageService: SharedStorageService, private appStateService: AppStateService) { }

  setDefaultAccount(accountUser: AccountUser): Observable<AccountUser> {
    return this.api.getJsonApiService().put(`${environment.RegistrationApi}/v1.0/role-permissions/set-default`, accountUser);
  }

  getAccountMonthlyData(): Observable<AccountMonthlyData[]> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/current-month-prediction`);
  }

  getAccountQuarterlyData(): Observable<AccountMonthlyData[]> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/quarterlyreport/current-quarter-prediction`);
  }

  updateSelectedAccount(account: Account): void {
    this.sharedStorageService.addAccountId(account.id);
    this.appStateService.publishActiveSubscription(account.activeSubscriptionHistory);
    this.sharedStorageService.addSubscriptionId(account.activeSubscriptionHistory.sbscriptionId);
    const roleId = this.sharedStorageService.getAccountRole(account.id);
    this.sharedStorageService.addRoleId(roleId);
    this.sharedStorageService.addAccount(account);
    this.appStateService.publishAccountingSystemIntegrationStatus(account.hasAccountingIntegrated);
  }

  getSelectedAccount(): Account {
    return this.sharedStorageService.getAccount();
  }

  addSelectedAccount(account: Account): void {
    this.sharedStorageService.addAccount(account);
  }

}
