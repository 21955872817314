import { Component, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { CategoryValueItem } from 'src/app/modules/data-visualization/models/category-value-item';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent extends ChartBaseComponent implements AfterViewInit, OnDestroy, OnChanges {

  @Input() chartData: CategoryValueItem[] = [];
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  renderChart(): void {
    this.browserOnly(() => {
      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.PieChart);    
      chart.data = this.chartData;
      super.chart = chart;
      super.setLocal();
      // Add and configure Series
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = this.chartOptions?.valueField;
      pieSeries.dataFields.category =  this.chartOptions?.category;
    
      pieSeries.slices.template.stroke = am4core.color("#fff");
      pieSeries.slices.template.strokeOpacity = 1;
      pieSeries.ticks.template.disabled = true;
      pieSeries.alignLabels = false;
      pieSeries.labels.template.disabled = true;

      // This creates initial animation
      pieSeries.hiddenState.properties.opacity = 1;
      pieSeries.hiddenState.properties.endAngle = -90;
      pieSeries.hiddenState.properties.startAngle = -90;

      if (pieSeries.tooltip) {
        pieSeries.tooltip.getFillFromObject = false;
        pieSeries.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
        pieSeries.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
        pieSeries.slices.template.tooltipText = "{category}: {value.percent.formatNumber('#.##')}% ({value.value})";
      }

      this.chartOptions?.radarColors.forEach(color => {
        pieSeries.colors.list.push(am4core.color(color));
      });

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.paddingTop = 20;
      chart.legend.position = this.chartOptions.legendPosition as am4charts.LegendPosition;
      chart.legend.labels.template.fill = am4core.color(this.chartOptions.labelColor);
      chart.legend.valueLabels.template.disabled = true;
      if (this.mobileDevice) {
        chart.legend.labels.template.adapter.add('textOutput', function (text) {
          if (text.length > 5) {
            return text.substr(0, 5) + '...';
          }
          return text;
        });
      }

      chart.hiddenState.properties.radius = am4core.percent(0);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      this.renderChart();
    }
  }
}
