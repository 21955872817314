import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class NavigationParamsService {
    navigationParams: Map<string, string>;

    constructor() { }

    public registerNavigationParams(): void {
        const url = window.location.href;
        this.navigationParams = new Map<string, string>();
        if (url.includes('?')) {
            const httpParams = new HttpParams({ fromString: url.split('?')[1] });
            httpParams.keys().forEach(key => {
                let paramValue = httpParams.get(key);
                this.navigationParams.set(key, paramValue!)
            });
        }
    }

    public getNavigationParam(): object {
        const navigationObject = Array.from(this.navigationParams).reduce((obj: any, [key, value]) => (
            Object.assign(obj, { [key]: value })
        ), {});
        return navigationObject;
    }

    public getNavigationQuery(queryParm: Params): string {
        return Object.keys(queryParm).map(key => key + '=' + queryParm[key]).join('&');;
    }
}

