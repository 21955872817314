export class YearlyPeriodTypeItem {
  period: YearlyPeriodType;
  reportDate: Date;
}

export enum YearlyPeriodType {
  totalYear = 0,
  monthOnMonth = 1
}

export enum YearlyPeriodTypeName {
  totalYear = 'Total year',
  monthOnMonth = 'Month on Month'
}

export enum MonthOnMonthDetailSortType {
  revenue = 'revenue',
  costOfSales = 'costOfSales',
  labourCost = 'labourCost',
  otherOperating = 'otherOperating'
}
