<div class="app-user-profile">
    <div class="user-profile-image" (click)="showUserProfile()">
        <img *ngIf="profileImageUrl" alt="{{userAlias}}" class="profile-image" src={{profileImageUrl}} width="100%">
        <span *ngIf="!profileImageUrl">{{userAlias}}</span>
    </div>
    <div class="profile-settings-panel" *ngIf="showProfile" (mouseleave)="hideUserProfile()" (mouseenter)="setFocus()">
        <div class="settings-panel" *ngIf="!(showLanguagePanel || showCurrencyPanel)">
            <div class="user-details">
                <div class="user-image">
                    <img *ngIf="profileImageUrl" alt="{{userAlias}}" class="profile-image" src={{profileImageUrl}} width="100%">
                    <span *ngIf="!profileImageUrl">{{userAlias}}</span>
                </div>

                <div class="user-info">
                    <h2 class="h2">{{userName}}</h2>
                    <a class="link" *ngIf="canViewUserProfile" (click)="goToLink(constants.myProfile)">{{'Manage your account'| translate}}</a>
                </div>
            </div>
            <div class="user-actions d-flex flex-column">
                <div class="user-action-btn btn-lang-select" (click)="showLanguageSection()">{{'Language'| translate}}:
                    {{selectedLanguageLabel}}</div>
                <div  *ngIf="showCurrency" class="user-action-btn  btn-currency-select" (click)="showCurrencySection()">{{'Currency'| translate}}:
                    {{selectedCurrencyLabel}}</div>
                <div class="btn-signout" (click)="signOut()">{{'Logout'| translate }}</div>
            </div>
        </div>
        <div class="language-panel" *ngIf="showLanguagePanel">
            <div class="lang-header" (click)="hideLanguageSection()">
                {{'Choose your language'| translate}}
            </div>
            <div class="languages d-flex flex-column">
                <div class="language" [ngClass]="{'selected': languageCode == 'en'}" (click)="selectedLanguage('en')">
                    English</div>
                <div class="language" [ngClass]="{'selected': languageCode == 'nb'}" (click)="selectedLanguage('nb')">
                    Norwegian</div>
            </div>
        </div>

        <div class="language-panel" *ngIf="showCurrencyPanel">
            <div class="lang-header" (click)="hideCurrencySection()">
                {{currencyPanelText}}
            </div>
            <div class="languages d-flex flex-column">
                <div class="language" [ngClass]="{'selected': selectedCurrencyLabel == 'NOK'}" (click)="selectCurrency('NOK')">
                    NOK</div>
                <div class="language" [ngClass]="{'selected': selectedCurrencyLabel == 'USD'}" (click)="selectCurrency('USD')">
                    USD</div>
            </div>
        </div>
    </div>
</div>
