import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { RouteLinkConstants } from 'src/app/app.constants';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Component({
  selector: 'app-back-button',
  templateUrl: './back-button.component.html',
  styleUrls: ['./back-button.component.scss']
})
export class BackButtonComponent implements OnInit, OnDestroy {

  enableBackButton: false;
  navigationLink = RouteLinkConstants.landingPage;
  selectedLanguageSubscription: Subscription;
  textBackButton: '';

  constructor(private activeRoute: ActivatedRoute, private router: Router, private appStateService: AppStateService,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });

    this.activeRoute.queryParams.subscribe(params => {
      this.enableBackButton = params.enableBackButton ? params.enableBackButton : this.enableBackButton;
      this.navigationLink = params.navigationFrom ? params.navigationFrom : this.navigationLink;
      this.textBackButton = params.textBackButton ? params.textBackButton : this.textBackButton;
    });
  }

  onBackPressed(): void {
    this.router.navigateByUrl(this.navigationLink);
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) { this.selectedLanguageSubscription.unsubscribe(); }
  }
}
