export class BudgetPopupData {
  iconClass = 'icon-update-successful';
  messageHeaderKey = '';
  messageContent = '';
  buttonContent = '';
  buttonContent2 = '';
  buttonContent3 = '';
  closeIconEnable = true;
  updateBudget = false;
}
