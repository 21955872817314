import { Component} from '@angular/core';
import { Router } from '@angular/router';

import { RouteLinkConstants } from 'src/app/app.constants';
import { NoAccessModel } from 'src/app/modules/shared/models/no-access-model';
import { UserPermissionService } from 'src/app/modules/shared/services/user-permission.service';

@Component({
  selector: 'app-no-access-base',
  templateUrl: './no-access-base.component.html',
  styleUrls: ['./no-access-base.component.scss']
})
export class NoAccessBaseComponent {

  constructor(public router: Router, public userPermissionService: UserPermissionService) { }

  ngOnUpdate(noAccessModel: NoAccessModel) {
    this.userPermissionService.hasAccessToPage(noAccessModel.permissionIds).subscribe((hasAccess: boolean) => {
      if (!hasAccess) {
        const routeNoAccess = `${noAccessModel.moduleName}/${RouteLinkConstants.noAccess}?${noAccessModel.navigationParmString}`;
        this.router.navigateByUrl(routeNoAccess);
      }
    });
  }
}
