import { Injectable } from '@angular/core';

import { LocalStorageService } from 'src/app/modules/core/common-services/local-storage.service';
import { Constants } from 'src/app/app.constants';

@Injectable({
  providedIn: 'root'
})
export class CoreStorageService extends LocalStorageService {

  constructor() {
    super();
  }

  public resetSessionInfo(): void {
    this.removeItem(Constants.accountId);
    this.removeItem(Constants.registrationUserId);
    this.removeItem(Constants.b2cUserId);
    this.removeItem(Constants.account);
    this.removeItem(Constants.isInitialB2cRedirection);
  }
}
