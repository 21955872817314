<div class="capassa-modal capassa-modal-common">
    <div class="capassa-modal-content">
        <div [ngClass]="iconClass" *ngIf="iconEnable"></div>
        <h1 class="h1">{{ messageHeaderKey | translate }}</h1>
        <p class="app-message success-message">
            {{ messageContent |translate }}
        </p>
        <button class="btn btn-primary" (click)="logout()">
            <span style="font-size:16px;">{{ buttonContent | translate }}</span>
        </button>
    </div>
</div>
