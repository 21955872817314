import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';
import { LineChartComponent } from 'src/app/modules/data-visualization/components/line-chart/line-chart.component';
import { BarChartComponent } from 'src/app/modules/data-visualization/components/bar-chart/bar-chart.component';
import { MultiChartComponent } from 'src/app/modules/data-visualization/components/multi-chart/multi-chart.component';
import { CircularChartComponent } from 'src/app/modules/data-visualization/components/circular-chart/circular-chart.component';
import { RadarChartComponent } from 'src/app/modules/data-visualization/components/radar-chart/radar-chart.component';
import { PredictionChartComponent } from 'src/app/modules/data-visualization/components/prediction-chart/prediction-chart.component';
import { GaugeChartComponent } from 'src/app/modules/data-visualization/components/gauge-chart/gauge-chart.component';
import { PieChartComponent } from 'src/app/modules/data-visualization/components/pie-chart/pie-chart.component';
import { HorizontalBarChartComponent } from 'src/app/modules/data-visualization/components/horizontal-bar-chart/horizontal-bar-chart.component';
import { GaugeChartComponentV2 } from 'src/app/modules/data-visualization/components/gauge-chart-v2/gauge-chart-v2.component';
import { ClusteredColumnChartComponent } from 'src/app/modules/data-visualization/components/clustered-column-chart/clustered-column-chart.component';

export const chartComponentList = [ChartBaseComponent, LineChartComponent, BarChartComponent, MultiChartComponent,
  CircularChartComponent, RadarChartComponent, PredictionChartComponent, GaugeChartComponent, PieChartComponent,
  HorizontalBarChartComponent, GaugeChartComponentV2, ClusteredColumnChartComponent];
  
@NgModule({
  declarations: [...chartComponentList],
  exports: [...chartComponentList],
  imports: [CommonModule]
})
export class DataVisualizationModule { }
