import { NgModule, SkipSelf, Optional, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AsyncPipe, CommonModule } from '@angular/common';

import {
  MsalGuard, MsalInterceptor, MsalBroadcastService, MsalService,
  MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG
} from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from 'src/app/modules/core/auth/auth.factory';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { CapassaApiInterceptorService } from 'src/app/modules/core/common-services/capassa-api-interceptor.service';
import { AuthService } from 'src/app/modules/core/auth/auth.service';
import { CoreStorageService } from 'src/app/modules/core/common-services/core-storage.service';
import { DeviceService } from 'src/app/modules/core/common-services/device.service';
import { QueryParamService } from 'src/app/modules/core/common-services/query-param.service';
import { FirebaseMessagingService } from 'src/app/modules/core/common-services/firebase-message.service';
import { SyncService } from 'src/app/modules/core/common-services/sync-service.service';
import { AccountingSystemGuardService } from 'src/app/modules/core/common-services/accounting-system-guard.service';
import { DefaultPageGuardService } from 'src/app/modules/core/common-services/default-page-guard.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  exports: [],
  declarations: []
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CapassaApiInterceptorService,
          multi: true
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        CapassaApiService,
        UserDataService,
        AuthService,
        CoreStorageService,
        DeviceService,
        QueryParamService,
        FirebaseMessagingService,
        AccountingSystemGuardService,
        DefaultPageGuardService,
        AsyncPipe,
        SyncService,
      ]
    };
  }
}
