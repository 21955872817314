<div class="information-center-panel">
  <header class="information-center-header">
    <h2 class="information-center-heading">
      {{ "Notifications" | translate }}
    </h2>
    <span class="close-icon-outline information-center-header-close" (click)="changeVisibility()"></span>
  </header>

  <div class="information-center-body">
    <div class="information-center-tabs-wrapper">
      <ul class="information-center-tabs-list">
        <li class="information-center-tab" [ngClass]="{ selected: selectedTab === notificationStatus.UNREAD }"
          (click)="selectTab('Unread')">
          <a class="information-center-tab-link" [ngClass]="{
              'unread-notifications':
              isAvailableUnreadNotifications
            }">{{ "Unread" | translate }}</a>
        </li>
        <li class="information-center-tab" [ngClass]="{ selected: selectedTab === notificationStatus.READ }"
          (click)="selectTab('Read')">
          <a class="information-center-tab-link">{{ "Read" | translate }}</a>
        </li>
        <li class="information-center-tab" [ngClass]="{ selected: selectedTab === notificationStatus.ARCHIVED }"
          (click)="selectTab('Archived')">
          <a class="information-center-tab-link">{{
            "Archived" | translate
            }}</a>
        </li>
      </ul>
      <div class="information-center-action-icons">
        <span *ngIf="showReadButton" [ngClass]="{
          'read-icon-outline': !readButtonClicked,
          'read-icon-solid': readButtonClicked
        }" class="information-center-action-icon" [ngbTooltip]="readTooltip" (click)="setButtonLabel('read')"></span>
        <span *ngIf="showArchiveButton" [ngClass]="{
          'archive-icon-outline': !archiveButtonClicked,
          'archive-icon-solid': archiveButtonClicked
        }" class="information-center-action-icon archive-icon-outline"  [ngbTooltip]="archivetooltip"  (click)="setButtonLabel('archive')"></span>
      </div>
    </div>
    <div *ngIf="buttonsVisible" class="information-center-buttons-wrapper">
      <button class="btn btn-outlined btn-outlined--critical" (click)="clearAllNotifications()">
        {{ "Uncheck all" | translate }}
      </button>
      <button class="btn btn-outlined" (click)="checkAllNotifications()">
        {{ "Check all" | translate }}
      </button>
      <button class="btn btn-outlined" (click)="updateMultipleNotificationStatus()">
        {{ buttonLabel | translate }}
      </button>
    </div>
    <div *ngIf="this.notifications.length === 0 " class="no-notifications">
      {{ noNewNotificationsMessage | translate }}
    </div>
    <ul class="notifications-list notifications-list-unread" #notificationsList>
      <li class="notification" *ngFor="let notification of notifications" [ngClass]="{
        'read-notification': selectedTab === notificationStatus.READ,
        'archived-notification': selectedTab === notificationStatus.ARCHIVED
      }">
        <div class="notification-inner-wrapper" (click)="updateNotificationStatus(notification.id)">
          <div [ngClass]="{
            'doc-with-charts-icon-solid': 
              notification.subType === cfoRecommendationSubType.MONTHLY_REPORT,
            'exclamation-circle-icon-solid': 
              notification.subType === cfoRecommendationSubType.HOLIDAY_PAY || 
              notification.subType === cfoRecommendationSubType.HOLIDAY_PAY_NO_ACCOUNT_SYS ||
              notification.subType === cfoRecommendationSubType.OTP || 
              notification.subType === cfoRecommendationSubType.NOTIFICATION,
            'welcome-icon-solid': 
              notification.subType === cfoRecommendationSubType.WELCOME,
            'calendar-icon-solid': 
              notification.subType === cfoRecommendationSubType.COMPENSATION_TAX || 
              notification.subType === cfoRecommendationSubType.WITHHOLDING_TAX || 
              notification.subType === cfoRecommendationSubType.ADVANCE_TAX_FIRST_INSTALLMENT || 
              notification.subType === cfoRecommendationSubType.ADVANCE_TAX_SECOND_INSTALLMENT ||
              notification.subType === cfoRecommendationSubType.COMPENSATION_WITHHOLDING_NO_ACCOUNT_SYSTEM || 
              notification.subType === cfoRecommendationSubType.ADVANCE_TAX_FIRST_INSTALLMENT_NO_ACCOUNT_SYS || 
              notification.subType === cfoRecommendationSubType.ADVANCE_TAX_SECOND_INSTALLMENT_NO_ACCOUNT_SYS,
            'advice-icon-solid': 
              notification.subType === cfoRecommendationSubType.ADVICE || 
              notification.subType === cfoRecommendationSubType.TRIGGER_BASED_ADVICE,
            'warning-icon-solid': 
              notification.subType === cfoRecommendationSubType.NO_ACCOUNTING_SYSTEM || 
              notification.subType === cfoRecommendationSubType.EQUITY_REACHING_ZERO || 
              notification.subType === cfoRecommendationSubType.EQUITY_WARNING,
            'new-feature-icon-solid': 
              notification.subType === cfoRecommendationSubType.CHALLENGE || 
              notification.subType === cfoRecommendationSubType.NO_BUDGETING
          }" class="notification-icon">
          </div>
          <h3 class="notification-heading">{{ notification.title }}</h3>
          <time class="notification-date">{{
            getLocalDate(notification.from)
            }}</time>
          <div class="notification-body">
            <label class="notification-message" for="notification-1">{{
              initiateNotificationMessage(notification)
              }}</label>
            <a *ngIf="
                notification.aditionalInfo != null &&
                notification.subType ===
                  cfoRecommendationSubType.NO_BUDGETING &&
                shouldShowLink(notification)
              " class="notification-link" (click)="navigateToBudgeting()">
              {{ "More information" | translate }}
            </a>
            <a *ngIf="
                notification.subType ===
                  cfoRecommendationSubType.MONTHLY_REPORT &&
                shouldShowLink(notification)
              " class="notification-link" (click)="navigateToReport(notification.date)">
              {{ "More information" | translate }}
            </a>
            <a *ngIf="
                notification.aditionalInfo === null &&
                notification.subType ===
                  cfoRecommendationSubType.NOTIFICATION &&
                shouldShowLink(notification)
              " class="notification-link" [href]="notificationUrl" target="_blank">
              {{ "More information" | translate }}
            </a>
            <a *ngIf="
                isRelevantRecommendation(notification) &&
                shouldShowLink(notification)
              " class="notification-link" [href]="navigateToLink(notification)" target="_blank">
              {{ "More information" | translate }}
            </a>
            <input *ngIf="this.buttonsVisible" type="checkbox" id="{{ notification.id }}" class="notification-checkbox"
              name="notification-{{ notification.id }}" [value]="notification.id"
              (change)="onCheckboxChange($event, notification.id)" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>