import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ValueInfo } from 'src/app/modules/shared/models/value-info';
import { ValueType } from 'src/app/modules/shared/models/value-type.enum';
import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { DefaultCurrency, Constants } from 'src/app/app.constants';
import { Currency } from 'src/app/modules/shared/models/currency';

@Component({
  selector: 'app-value-trend',
  templateUrl: './value-trend.component.html',
  styleUrls: ['./value-trend.component.scss']
})
export class ValueTrendComponent implements DoCheck, OnInit, OnDestroy {

  @Input() valueInfo: ValueInfo | undefined;
  @Input() valueType: ValueType;
  @Input() trendType = 'default';
  valueTypeEnum = ValueType;
  trend = '';
  valueLabel = '';
  showTrend = false;
  browserLanguage = '';
  selectedLanguageSubscription: Subscription;
  currencySubscription: Subscription;
  selectedCurrency: Currency = DefaultCurrency;

  constructor(private utilityService: UtilityService, private appStateService: AppStateService) { }

  ngOnInit(): void {
    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
    });

    this.currencySubscription = this.appStateService.subscribeSelectedCurrency().subscribe(currency =>{
      this.selectedCurrency = currency;
      this.showValueTrend();
    });
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) {this.selectedLanguageSubscription.unsubscribe();}
    if (this.currencySubscription) { this.currencySubscription.unsubscribe(); }
  }

  ngDoCheck(): void {
    this.showValueTrend();
  }

  showValueTrend(): void {
    this.valueLabel = '';
    if (this.valueInfo) {
      const diff = this.valueInfo.value - this.valueInfo.previousMonthValue;
      if (diff > 0) {
        switch (this.trendType) {
          case Constants.default:
            this.trend = 'up-green';
            break;
          case Constants.budget:
            this.trend = 'down-red';
            break;
          default:
            this.trend = 'up-red';
            break;
        }
      } else if (diff < 0) {
        switch (this.trendType) {
          case Constants.default:
            this.trend = 'down-red';
            break;
          case Constants.budget:
            this.trend = 'up-green';
            break;
          default:
            this.trend = 'down-green';
            break;
        }
      } else {
        this.trend = '';
      }

      if (!isNaN(diff)) {
        this.valueLabel = this.formatValue(Math.abs(diff), Math.abs(this.valueInfo.value));
        this.showTrend = true;
      } else {
        this.showTrend = false;
      }

    } else {
      this.showTrend = false;
    }
  }

  formatValue(difference: number, baseValue: number): string {
    let formattedValue = '';

    switch (this.valueType) {
      case ValueType.NumberWithSuffix:
        formattedValue = this.utilityService.formatNumberWithSuffixes(difference, this.browserLanguage, this.selectedCurrency);
        break;
      case ValueType.Number:
        formattedValue = this.utilityService.formatNumber(difference, this.selectedCurrency, this.browserLanguage);
        break;
      case ValueType.Percentage:
        if (baseValue !== 0) {
          const calculatedValue = Math.round(difference * 1000 / baseValue) / 10;
          formattedValue = `${this.utilityService.formatNumberToDecimal(calculatedValue, this.browserLanguage)}%`;
        } else {
          this.trend = '';
          formattedValue = '0%';
        }
        break;
      default:
        formattedValue = difference.toString();
        break;
    }

    return formattedValue;
  }
}
