import { DataRoomData } from 'src/app/modules/data-room/models/data-room-data';

export class FileManagerModel {
  id: string;
  capassaAccountId: string;
  name: string;
  dataRoomId: string;
  parentId: string | null;
  path: string;
  type: number;
  createdBy: string;
  modifiedBy: string;
  createdOn: string;
  modifiedOn: string;
  isArchived: boolean;
  file: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileExtension: string;
  modifiedByUserName: string;
  dataRoom: DataRoomData;
  isReplace: boolean;
}

export class FileManagerPopupData {
  iconClass = 'icon-update-successful';
  messageHeaderKey = '';
  messageContent = '';
  buttonContent = '';
  buttonContent2 = '';
  buttonContent3 = '';
  closeIconEnable = true;
  type = '';
  parentId: string | null = null;
  key: string | null = null;
}

export class FileManagerDocumentFormData {
  title = '';
  type = '';
  parentId: string | null = null;
  key: string | null = null;
  selected: string;
}

export class FileManagerDocumentResponse {
  dataRoomDocuments: FileManagerModel[] = [];
  filesNotCreated = false;
  dataRoomName = '';
  totalFileSize: number;
  dataRoomList: DataRoomData[] = [];
}

export class FileManagerShareBudget {
  dataRoomId: string | null = null;
  key: string | null = null;
  title = '';
}

export class FileManagerDocumentContent {
  key: string;
  title: string;
  folder: boolean;
  children: any[];
  parentId: string | null;
  type: number;
  createdDate: string;
  fileSize: string;
  modifiedByUserName: string;
  dataRoomId: string;

  constructor(key: string = '', Title: string, Folder: boolean, Type: number, CreatedDate: string, ModifiedByUserName: string,
              FileSize: string = '', Children: any[] = [], ParentId: string | null = null, DataRoomId: string)
  {
    this.key = key;
    this.title = Title;
    this.folder = Folder;
    this.children = Children;
    this.parentId = ParentId;
    this.type = Type;
    this.createdDate = CreatedDate;
    this.fileSize = FileSize;
    this.modifiedByUserName = ModifiedByUserName;
    this.dataRoomId = DataRoomId;
  }
}
