<div class="capassa-modal capassa-modal-common">
  <div class="invite-another-company">
    <div class="form-header">
      <h1 class="h1">{{"Invite another company to Capassa" | translate }}</h1>
      <a class="link link-close" (click)="hidePopup()"></a>
    </div>
    <div class="form-body">

      <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
        <div class="form-group m-0">
          <div class="note">{{"Search by the company name or the organization number to send the invitation." | translate }}</div>
          <div *ngIf="showSuccessMessage" class="app-message success-message ">
            <span>{{"CEO success message" | translate }}</span>
          </div>
          <div *ngIf="showErrorMessage" class="app-message error-message ">
            <span>{{"This invitation cannot be sent due to a technical error. Please try sending the invitation again." | translate }}</span>
          </div>
          <div class="select-custom-company">
            <label class="form-lbl">{{"Company name" | translate }}</label>
          </div>
          <input id="companySearch" type="textarea" #companyNameSearch formControlName="company"
                 class="form-control search-input" (focus)="this.stopSearchByName = false;" [(ngModel)]="companyName"
                 [ngClass]="{'loading':showLoader,'search-input' : !showLoader }"
                 [ngClass]="{ 'is-invalid': submitted && formFields.company.errors }">
          <div *ngIf="submitted && formFields.company.errors" class="invalid-feedback">
            <div *ngIf="formFields.company.errors.required">
              {{"Company name is mandatory." | translate }}</div>
          </div>
          <div class="p-r-c">
            <div *ngIf="showList && companyName && companyName.length > 2" class="select-company">
              <ul>
                <li class="removeBullets" *ngFor="let company of filteredCompanies">
                  <div class="company-name" (click)="goToSelectedCompany(company.organisasjonsnummer )">{{
                    company.navn }} </div>
                </li>
              </ul>
              <div *ngIf="totalPages > pageCount+1" class="nav-link" (click)="showMore()">{{"Show more" |
                translate }}
                <b>...</b>
              </div>
            </div>
          </div>

          <label class="form-lbl">{{"Company number" | translate }}</label>
          <input type="textarea" formControlName="companyNumber" (ngModelChange)="searchCompanyByNumber($event)"
                 class=" form-control search-input" maxlength="9" (keypress)="keyPressAlphanumeric($event)"
                 (paste)="onPaste($event)" [(ngModel)]="organizationNumber">
          <div class="info">{{"Enter 9 digit company number." | translate }}
          </div>

          <label class="form-lbl">{{"Name of CEO/Chairperson" | translate }}</label>
          <input type="text" formControlName="ceoName" class="form-control " [(ngModel)]="chairman">
          <label class="form-lbl">{{"Email for CEO/Chairperson" | translate }}</label>
          <input type="text" formControlName="email" class="form-control " [(ngModel)]="email"
                 [ngClass]="{ 'is-invalid': submitted && formFields.email.errors }" />
          <div *ngIf="submitted && formFields.email.errors" class="invalid-feedback">
            <div *ngIf="formFields.email.errors.required">
              {{"Email address field is mandatory." | translate }}</div>
            <div *ngIf="formFields.email.errors.email || formFields.email.errors.pattern">
              {{"Please enter a valid email." | translate }}</div>
          </div>
          <button [ngClass]="{'loading-on': showLoaderPrimary}"
                  class="btn btn-primary btn-lg btn-block text-center border-0">{{'Send invitation' | translate }}</button>

        </div>
      </form>
      <div class="form-group m-0">
        <button [ngClass]="{'green-loading-on': showLoaderSecondary}" class="btn btn-secondary"
                (click)="hidePopup()">{{'Send later' | translate }}</button>
      </div>
    </div>
  </div>
</div>
