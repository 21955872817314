import { Injectable } from '@angular/core';

import { Device } from 'src/app/modules/core/models/models';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor() { }

  public getDevice() {
    var userDevice: Device = Device.DesktopWindows;
    var userAgent = navigator.userAgent;

    if (/Windows;/i.test(userAgent)) {
      userDevice = Device.DesktopWindows;
    }
    else if (/iPhone;|iPad;|iPod;/i.test(userAgent)) {
      userDevice = Device.IOS;
    }
    else if (/Macintosh;| Mac/i.test(userAgent)) {
      userDevice = Device.DesktopMac;
    }
    else if (/Linux;|Android/i.test(userAgent)) {
      userDevice = Device.Android;
    }

    return userDevice;
  }

}
