import { Injectable } from '@angular/core';

//import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject, Observable } from 'rxjs';

import { GlobalNotification } from 'src/app/modules/core/models/notification.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessagingService {

  private firebaseMessage = new BehaviorSubject<GlobalNotification>(new GlobalNotification());
  private firebaseMessageToken = new BehaviorSubject<string>('');


  constructor(/*private angularFireMessaging: AngularFireMessaging*/) {
  }

  requestPermission(): void {
    // this.angularFireMessaging.requestToken.subscribe(
    //   (token: any) => {
    //     this.firebaseMessageToken.next(token);
    //   },
    //   (err: any) => {
    //     console.error(err);
    //   }
    // );
  }

  receiveMessage(): void {
    // this.angularFireMessaging.onMessage((payload: any) => {
    //   this.firebaseMessage.next(payload.data);
    // });
  }

  receiveBackGroundMessage(): void {
    // this.angularFireMessaging.messages.subscribe(
    //   (messaging: any) => {
    //     this.firebaseMessage.next(messaging.data);
    //   }
    // );
  }


  subscribeFirebaseMessage(): Observable<GlobalNotification> {
    return this.firebaseMessage.asObservable();
  }

  subscribeFirebaseTokenData(): Observable<string> {
    return this.firebaseMessageToken.asObservable();
  }
}