import { Pipe, PipeTransform } from '@angular/core';

import { UtilityService } from 'src/app/modules/shared/services/utility.service';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

  constructor(private utilityService: UtilityService) { }

  transform(value: any, ...args: any[]): string {
    const language = args[0];
    const format = args[1] ? args[1] : 'MMMM, YYYY';

    return this.utilityService.getLocalDate(value, language, format);
  }

}
