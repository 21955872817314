import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { Guid } from 'guid-typescript';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { Constants, PageOrigins, RouteLinkConstants } from 'src/app/app.constants';
import {
  FileManagerModel,
  FileManagerDocumentFormData,
  FileManagerPopupData,
  FileManagerDocumentContent,
  FileManagerDocumentResponse
} from 'src/app/modules/shared/models/capassa-file-manager.model';
import { BreadcrumbNode, BreadcrumbNodeData } from 'src/app/modules/shared/models/breadcrumb-data';
import { UserInterfaceService } from 'src/app/modules/shared/services/user-interface.service';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { UtilityService } from 'src/app/modules/shared/services/utility.service';
import { LoadingBarStatus, UserAction } from 'src/app/modules/shared/globals';
import { CapassaFileManagerModalComponent } from 'src/app/modules/shared/components/capassa-file-manager-modal/capassa-file-manager-modal.component';
import { FileManagerChangeEventType, FileManagerDocumentType, FileManagerReportType } from 'src/app/modules/shared/models/capassa-file-manager.enum';
import { B2CUser } from 'src/app/modules/core/user-service/user.model';
import { SharedStorageService } from 'src/app/modules/shared/services/shared-storage.service';
import { CapassaFileManagerDataService } from 'src/app/modules/shared/services/capassa-file-manager-data.service';
import { environment } from 'src/environments/environment';

declare function initializeFileManager(): void;
declare function changeSelection(key: string): void;
declare function updateFolderStructureTable(source: FileManagerDocumentContent[], key: string, lang: string): void;
declare function updateFolderContentTable(source: FileManagerDocumentContent[]): void;
declare function setPermissions(canUploadFiles: boolean, canRenameFiles: boolean, canDownloadFiles: boolean, canDeleteFiles: boolean): void;

@Component({
  selector: 'app-capassa-file-manager',
  templateUrl: './capassa-file-manager.component.html',
  styleUrls: ['./capassa-file-manager.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CapassaFileManagerComponent implements OnInit, OnDestroy {
  loggedInB2CUserSubscription: Subscription;
  selectedLanguageSubscription: Subscription;
  accountingSystemIntegrationStatusSubscription: Subscription;
  userActionSubscription: Subscription;
  registrationB2cUser: B2CUser;
  accountId = '';
  dataRoomId = '';
  selectedDocumentId = '';
  breadcrumbDocumentList: BreadcrumbNodeData[] = [];
  constants = Constants;
  documentData: FileManagerDocumentResponse = new FileManagerDocumentResponse();
  documentTransformList: FileManagerDocumentContent[] = [];
  browserLanguage: string;
  translations: any;
  overlayRef: OverlayRef;
  uploadFileId: string | null = '';
  uploadFolderTitle = '';
  uploadFileName = '';
  uploadFileKey: string | null = '';
  downloadInProgress = false;
  base64ConversionPrefix = 'data:application/octet-stream;base64';
  isReplaceFile = false;
  accountingIntegrationStatus = false;
  showUploadCapassaButton = false;
  canUploadFiles = false;
  canRenameFiles = false;
  canDownloadFiles = false;
  canDeleteFiles = false;

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;

  constructor(private translate: TranslateService, private fileManagerDataService: CapassaFileManagerDataService,
              private userInterfaceService: UserInterfaceService, private appStateService: AppStateService, private utilityService: UtilityService,
              private router: Router, private overlay: Overlay, private sharedStorageService: SharedStorageService, private activeRoute: ActivatedRoute,
              private location: Location, private renderer: Renderer2) {
    (window as any).onChangeEvent = this.onChangeEvent.bind(this);
  }

  ngOnInit(): void {
    initializeFileManager();
    this.accountId = this.sharedStorageService.getAccountId();
    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe((b2cUser: B2CUser) => {
      this.registrationB2cUser = b2cUser;
    });

    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.browserLanguage = language;
      this.translate.use(language);
      this.translate.getTranslation(this.browserLanguage).subscribe((translationObj) => {
        this.translations = translationObj;
        this.getDocumentList();
      });
    });

    this.activeRoute.queryParams.subscribe(params => {
      this.dataRoomId = params.id ? params.id : '';
      this.getDocumentList();
    });

    this.accountingSystemIntegrationStatusSubscription = this.appStateService.subscribeAccountingSystemIntegrationStatus().subscribe((accountingIntegrationStatus: boolean) => {
      this.accountingIntegrationStatus = accountingIntegrationStatus;
      if (accountingIntegrationStatus) {
        this.showUploadCapassaButton = true;
      } else {
        this.showUploadCapassaButton = false;
      }
    });

    this.userActionSubscription = this.appStateService.subscribeUserActions().subscribe(actions => {
      this.canUploadFiles = actions.find(action => action === UserAction.UploadFilesDataRoom) ? true : false;
      this.canRenameFiles = actions.find(action => action === UserAction.RenameFilesDataRoom) ? true : false;
      this.canDownloadFiles = actions.find(action => action === UserAction.DownloadFilesDataRoom) ? true : false;
      this.canDeleteFiles = actions.find(action => action === UserAction.DeleteFilesDataRoom) ? true : false;
      setPermissions(this.canUploadFiles, this.canRenameFiles, this.canDownloadFiles, this.canDeleteFiles);
    });
  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) { this.selectedLanguageSubscription.unsubscribe(); }
    if (this.loggedInB2CUserSubscription) { this.loggedInB2CUserSubscription.unsubscribe(); }
    if (this.accountingSystemIntegrationStatusSubscription) { this.accountingSystemIntegrationStatusSubscription.unsubscribe(); }
    if (this.userActionSubscription) { this.userActionSubscription.unsubscribe(); }
  }

  loadData(source: FileManagerDocumentContent[]): void {
    updateFolderContentTable(source.length && source[0].children && source[0].children.length ? source[0].children : []);
    updateFolderStructureTable(source, this.selectedDocumentId ?? null,  this.browserLanguage);
  }

  getDocumentList(): void {
    if (!this.dataRoomId) { return; }
    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
    this.fileManagerDataService.getDocumentList(this.dataRoomId).subscribe(data => {
      this.documentData = data;
      this.documentTransformList = !data.filesNotCreated ? this.transformData(data.dataRoomDocuments) : this.transformUnCreatedData(data.dataRoomName);
      this.loadData(this.documentTransformList);
      this.setDocumentSelection(this.selectedDocumentId);
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    }, error => {
      this.userInterfaceService.showCommonErrorMessage();
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    });
  }

  setDataRoomId(data: FileManagerModel): void {
    if (!data || !data.dataRoomId || data.dataRoomId === Guid.EMPTY || this.dataRoomId !== Guid.EMPTY) { return; }
    this.dataRoomId = data.dataRoomId;
    this.location.replaceState(`${RouteLinkConstants.dataRoomDetails}?id=${this.dataRoomId}&origin=${PageOrigins.dataRoom}&enableBackButton=true&navigationFrom=${RouteLinkConstants.dataRoom}`);
  }

  transformUnCreatedData(name: string): FileManagerDocumentContent[] {
    const result: FileManagerDocumentContent[] = [];
    result.push(new FileManagerDocumentContent('root', name, true,
      FileManagerDocumentType.Folder, '', '', '', [], null, ''));
    return result;
  }

  transformData(documents: FileManagerModel[]): FileManagerDocumentContent[] {
    if (!documents || !documents.length) { return []; }

    const result: FileManagerDocumentContent[] = [];
    const documentChildren: FileManagerDocumentContent[] = [];
    const documentDict: any = {};
    const rootId = 'root';

    documents.forEach(document => {
      const month = document.modifiedOn ? this.utilityService.getLocalDate(new Date(document.modifiedOn), this.browserLanguage, 'MMM') :
        this.utilityService.getLocalDate(new Date(), this.browserLanguage, 'MMM');
      const day = document.modifiedOn ? this.utilityService.getLocalDate(new Date(document.modifiedOn), this.browserLanguage, 'DD') :
        this.utilityService.getLocalDate(new Date(), this.browserLanguage, 'DD');
      const year = document.modifiedOn ? new Date(document.modifiedOn).getFullYear() : new Date().getFullYear();
      const createdMonth = month ? month.replace('.', '') : month;
      const time = document.modifiedOn ? this.utilityService.getLocalDate(new Date(document.modifiedOn), this.browserLanguage, 'HH:mm') :
        this.utilityService.getLocalDate(new Date(), this.browserLanguage, 'HH:mm');
      const createdDate = `${createdMonth} ${day} ${year}, ${time}`;

      const fileSize = this.utilityService.getFileSize(document.fileSize);
      const parentId = document.id === this.dataRoomId ? null : document.parentId ? document.parentId : rootId;
      documentDict[document.id] = new FileManagerDocumentContent(document.id, document.name, (document.type === 1),
        document.type, createdDate, document.modifiedByUserName, fileSize, [], parentId, document.dataRoomId);
    });

    documents.forEach(document => {
      if (document.parentId === null) {
        documentChildren.push(documentDict[document.id]);
      } else {
        documentDict[document.parentId]?.children?.push(documentDict[document.id]);
      }
    });

    result.push(new FileManagerDocumentContent(rootId, documents[0].dataRoom.name, true,
      FileManagerDocumentType.Folder, '', '', '', documentChildren, null, ''));
    return result;
  }

  createDocumentData(documentData: FileManagerModel): void {
    this.fileManagerDataService.createDocumentData(documentData).subscribe((data: FileManagerModel) => {
      this.setDataRoomId(data);
      if (!FileManagerChangeEventType.AddFolder) {
         this.userInterfaceService.showCommonSuccessMessage('', this.translations['Save changes'],
         this.translations['Updated successfully!']);
      }
      this.getDocumentList();
    }, error => {
      this.userInterfaceService.showCommonErrorMessage();
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    });
  }
  updateDocumentData(documentData: FileManagerModel): void {

    this.fileManagerDataService.updateDocumentData(documentData).subscribe((data: FileManagerModel) => {
      if (!FileManagerChangeEventType.Rename) {
        this.userInterfaceService.showCommonSuccessMessage('', this.translations['Save changes'],
        this.translations['Updated successfully!']);
      }
      this.getDocumentList();
    }, error => {
      this.userInterfaceService.showCommonErrorMessage();
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    });
  }

  deleteDocument(id: string): void {
    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
    this.fileManagerDataService.deleteDocument(id).subscribe(() => {
      this.getDocumentList();
    }, error => {
      this.userInterfaceService.showCommonErrorMessage();
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
    });
  }

  getBreadcrumbDocumentList(data: BreadcrumbNode[], key: string): BreadcrumbNodeData[] {
    const parents: BreadcrumbNodeData[] = [];

    const recursiveSearch = (node: BreadcrumbNode) => {
      if (!node) { return; }

      parents.push({ key: node.key, title: node.title });

      if (node.parentId) {
        const parent = this.findDocumentByKey(data, node.parentId);
        if (parent) {
          recursiveSearch(parent);
        }
      }
    };
    const targetNode = this.findDocumentByKey(data, key);
    if (targetNode) {
      recursiveSearch(targetNode);
    }
    return parents.reverse();
  }

  setDocumentSelection(key: string): void {
    if (!key) { key = 'root'; }
    this.selectedDocumentId = key;
    this.breadcrumbDocumentList = this.getBreadcrumbDocumentList(this.documentTransformList, key);
  }

  onChangeEvent(type: string, data: any): void {
    switch (type) {
      case FileManagerChangeEventType.SelectedDocument:
        this.setDocumentSelection(data?.key);
        break;
      case FileManagerChangeEventType.Rename:
       if(data.type === 1){
        this.showRenamePopup(data?.title ?? '', FileManagerChangeEventType.Rename, data?.parentId, data?.key);
       }
       else{
        this.showRenameFilePopup(data?.title ?? '', FileManagerChangeEventType.Rename, data?.parentId, data?.key);
      }
        break;
      case FileManagerChangeEventType.Download:
        this.downloadDocument(data?.key, data?.title);
        break;
      case FileManagerChangeEventType.AddFolder:
        const key = data.key === 'root' ? null : data.key;
        this.showAddAndEditPopup('', FileManagerChangeEventType.AddFolder, key);
        break;
      case FileManagerChangeEventType.Delete:
        this.setDocumentSelection(data?.selectedDocumentId ?? null);
        this.showDeleteConfirmationPopup(data?.title ?? '', FileManagerChangeEventType.Delete, data?.key);
        break;
      case FileManagerChangeEventType.UploadFile:
        this.uploadFileId = data.key === 'root' ? null : data.key;
        this.uploadFolderTitle = data.key === 'root' ? this.documentData.dataRoomName : data.title;
        this.isReplaceFile = false;
        this.renderer.selectRootElement(this.fileInput.nativeElement).click();
        break;
      case FileManagerChangeEventType.ReplaceFile:
        this.uploadFileId = data.parentId === 'root' ? null : data.parentId;
        this.uploadFileKey = data.key === 'root' ? null : data.key;
        const title = this.documentData.dataRoomDocuments?.find(y => y.id === this.uploadFileId)?.name ?? '';
        this.uploadFolderTitle = data.parentId === 'root' ? this.documentData.dataRoomName : title;
        this.isReplaceFile = true;
        this.fileInput.nativeElement.value = '';
        this.renderer.selectRootElement(this.fileInput.nativeElement).click();
        break;
      case FileManagerChangeEventType.DragDrop:
        this.uploadFileId = data.key === 'root' ? null : data.key;
        this.uploadFolderTitle = data.title;
        this.uploadFile(null, data.file);
        break;
      case FileManagerChangeEventType.Move:
        this.setDocumentSelection(data?.selectedDocumentId ?? null);
        this.moveDocuments(data?.key, data?.parentId);
        break;
      default:
        break;
    }
  }

  downloadDocument(id: string, fileName: string): void {
    this.fileManagerDataService.downloadDocument(id).subscribe((data: any) => {
      this.utilityService.downloadAllFileResources(`${this.base64ConversionPrefix},${data}`, fileName);
    }, error => {
      this.userInterfaceService.showCommonErrorMessage();
    });
  }

  moveDocuments(key: string, parentId: string): void {
    const documentData = this.documentData.dataRoomDocuments.find(x => x.id === key ?? Guid.EMPTY) ?? new FileManagerModel();
    const document = { ...documentData, parentId };
    this.updateDocumentData(document);
  }

  showDeleteConfirmationPopup(title: string, type: FileManagerChangeEventType, key = null): void {
    this.showPopup({
      messageHeaderKey: this.translations['Are you sure?'],
      messageContent: `${this.translations['Are you sure you want to delete']} ${title}?`,
      buttonContent: this.translations.Delete,
      buttonContent2: this.translations['Don\'t delete'],
      closeIconEnable: true,
      buttonContent3: '',
      iconClass: '',
      key,
      type: FileManagerChangeEventType.Delete,
      parentId: ''
    });
  }

  showAddAndEditPopup(title: string, type: FileManagerChangeEventType, parentId = null, key = null): void {
    this.showPopup({
      messageHeaderKey: this.translations['Create a folder'],
      messageContent: title,
      buttonContent: this.translations.Create,
      buttonContent2: '',
      closeIconEnable: true,
      buttonContent3: '',
      iconClass: '',
      parentId,
      type,
      key
    });
  }

  showChooseCapassaFilePopup(): void {
    this.showPopup({
      messageHeaderKey: this.translations['Choose file'],
      messageContent: '',
      buttonContent: '',
      buttonContent2: '',
      closeIconEnable: true,
      buttonContent3: '',
      iconClass: '',
      parentId: '',
      type: FileManagerChangeEventType.ChooseCapassaFile,
      key: '',
    });
  }

  showRenamePopup(title: string, type: FileManagerChangeEventType, parentId = null, key = null): void {
    this.showPopup({
      messageHeaderKey: this.translations['Rename folder'],
      messageContent: title,
      buttonContent: this.translations.Rename,
      buttonContent2: '',
      closeIconEnable: true,
      buttonContent3: '',
      iconClass: '',
      parentId,
      type,
      key
    });
  }

  showRenameFilePopup(title: string, type: FileManagerChangeEventType, parentId = null, key = null): void {
    this.showPopup({
      messageHeaderKey: this.translations['Rename file'],
      messageContent: title,
      buttonContent: this.translations.Rename,
      buttonContent2: '',
      closeIconEnable: true,
      buttonContent3: '',
      iconClass: '',
      parentId,
      type,
      key
    });
  }

  showPopup(data: FileManagerPopupData): void {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-dark-backdrop',
      panelClass: 'tm-dialog-panel',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });
    this.initOverlay(overlayConfig);
    this.appStateService.publishOverlayState(true);
    const commonSuccessComponent = new ComponentPortal(CapassaFileManagerModalComponent);
    const component = this.overlayRef.attach(commonSuccessComponent);
    component.instance.data = data;
    component.instance.clickSubmit.subscribe((formData: FileManagerDocumentFormData) => {
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
      switch (formData.type) {
        case FileManagerChangeEventType.Rename:
          const documents = this.documentData.dataRoomDocuments.find(x => x.id === formData?.key ?? Guid.EMPTY) ?? new FileManagerModel();
          const document = {
            ...documents,
            name: formData.title, modifiedBy: this.registrationB2cUser?.registrationUserId ?? Guid.EMPTY,
            id: formData?.key ?? Guid.EMPTY
          };
          this.updateDocumentData(document);
          break;
        case FileManagerChangeEventType.AddFolder:
          const documentData = {
            ...new FileManagerModel(),
            name: formData.title, dataRoomId: this.dataRoomId, path: '', fileSize: 0,
            createdBy: this.registrationB2cUser?.registrationUserId ?? Guid.EMPTY, modifiedBy: this.registrationB2cUser?.registrationUserId ?? Guid.EMPTY,
            type: FileManagerDocumentType.Folder, capassaAccountId: this.accountId, parentId: this.dataRoomId !== Guid.EMPTY ? formData.parentId : null,
          };
          this.createDocumentData(documentData);
          break;
        default:
          this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
          break;
      }
      this.hidePopup();
      this.appStateService.publishOverlayState(false);
    });
    component.instance.clickChange.subscribe((formData: FileManagerDocumentFormData) => {
      this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
      switch (formData.type) {
        case FileManagerChangeEventType.Delete:
          this.deleteDocument(formData.key ?? '');
          break;
        case FileManagerChangeEventType.ChooseCapassaFile:
          this.goToLink(formData.selected);
          break;
        default:
          this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
          break;
      }
      this.hidePopup();
      this.appStateService.publishOverlayState(false);
    });
    component.instance.clickExit.subscribe((formData: FileManagerDocumentFormData | null) => {
      switch (formData?.type) {
        case FileManagerChangeEventType.MaximumStorageWarning:
          this.renderer.selectRootElement(this.fileInput.nativeElement).click();
          break;
        default:
          break;
      }
      this.hidePopup();
      this.appStateService.publishOverlayState(false);
    });
    component.instance.clickClose.subscribe(() => {
      this.hidePopup();
      this.appStateService.publishOverlayState(false);
    });
  }

  hidePopup(): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  initOverlay(config: any = null): void {
    if (this.overlayRef) {
      this.overlayRef.detach();
    } else {
      this.overlayRef = config ? this.overlay.create(config) : this.overlay.create();
    }
  }

  goToLink(url: string): void {
    const params = {
      origin: PageOrigins.dataRoom,
      enableBackButton: true,
      navigationFrom: `${RouteLinkConstants.dataRoomDetails}?id=${this.dataRoomId}&origin=data-room&enableBackButton=true&navigationFrom=data-room`,
      selected: this.selectedDocumentId === 'root' ? this.dataRoomId : this.selectedDocumentId
    };
    switch (url) {
      case FileManagerReportType.PnLReport:
        this.router.navigate([RouteLinkConstants.reports], { queryParams: params });
        break;
      case FileManagerReportType.BalanceReport:
        this.router.navigate([RouteLinkConstants.balanceSheet], { queryParams: params });
        break;
      case FileManagerReportType.LiquidityBudget:
        this.router.navigate([RouteLinkConstants.budgeting], { queryParams: { ...params, liquidityBudget: true } });
        break;
      default:
        this.router.navigate([RouteLinkConstants.budgeting], { queryParams: params });
        break;
    }
  }

  uploadFile(fileInput: any, fileInputDragDrop: any = null): void {
    if (fileInputDragDrop === null && (!fileInput.files || !fileInput.files.length || !fileInput.files[0])) {
      return;
    }

    const file = fileInputDragDrop === null ? fileInput.files[0] : fileInputDragDrop;
    this.uploadFileName = file.name;
    const extension = file.name.split('.').pop()
    if (!this.isReplaceFile && !this.showSameFileNameWarning(file.name)) { return; }
    if (!this.showMaximumStorageWarning()) { return; }
    if (!environment.FileExtensionAllowed.includes(extension)) {this.invalidFileTypeWarning(); return; }
    if ( file.size > environment.MaxFileSizeAllowed) {this.showMaximumFileSizeWarning(); return; }
    this.downloadInProgress = true;
    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
    const uploadFileId = this.uploadFileId;
    const chunkSize = 1024 * 1024;
    let offset = 0;

    const uploadChunk = () => {
      const reader = new FileReader();
      const blob = file.slice(offset, offset + chunkSize);

      reader.onload = (event: any) => {
        if (event.target.result) {
          const fileData = event.target.result;
          const isLastChunk = offset + chunkSize >= file.size;
          let fileName = file.name;
          let fileExtension = file.type;
          const lastDotIndex = file.name.lastIndexOf('.');
          if (lastDotIndex !== -1) {
            fileName = file.name.slice(0, lastDotIndex);
            fileExtension = file.name.slice(lastDotIndex + 1);
          }

          const type = this.getFileType(file.type);
          const fileSize = Number(this.utilityService.formatNumberWithDecimal((file.size / 1024), 2));
          const parentId = !uploadFileId || uploadFileId === 'root' ? null : uploadFileId;
          const uploadFileName = this.documentData.dataRoomDocuments?.find(y => y.id === this.uploadFileKey)?.name ?? '';
          const name = this.isReplaceFile ? uploadFileName : file.name;
          const documentData = {
            ...new FileManagerModel(),
            name, dataRoomId: this.dataRoomId, parentId, path: '',
            id: this.isReplaceFile ? this.uploadFileKey : Guid.EMPTY, createdBy: this.registrationB2cUser?.registrationUserId ?? Guid.EMPTY,
            modifiedBy: this.registrationB2cUser?.registrationUserId ?? Guid.EMPTY, type, fileType: file.type, fileName: fileName ?? '',
            fileSize, fileExtension, capassaAccountId: this.accountId, isReplace: this.isReplaceFile
          };
          this.uploadFileId = '';
          this.isReplaceFile = false;

          const data = new FormData();
          data.append('file', new Blob([fileData]));
          data.append('offset', offset.toString());
          data.append('isLastChunk', isLastChunk.toString());
          data.append('fileName', file.name);
          data.append('documentContent', JSON.stringify(documentData));

          this.fileManagerDataService.uploadDocument(data).subscribe((result: FileManagerModel) => {
            offset += chunkSize;
            if (!isLastChunk) {
              uploadChunk();
            } else {
              const title = (this.uploadFolderTitle ? this.uploadFolderTitle : result.dataRoom.name) ?? '';
              this.uploadFolderTitle = '';
              this.userInterfaceService.showCommonSuccessMessage('icon-sharing-successful', this.translations['Uploaded successfully'], `${name} ${this.translations['has been successfully uploaded to']} ${title}`);
              this.setDataRoomId(result);
              this.getDocumentList();
              this.resetFileInput();
            }
          }, (error: any) => {
            this.resetFileInput();
            this.userInterfaceService.showCommonErrorMessage();
            this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide);
          });
        }
      };
      reader.readAsArrayBuffer(blob);
    };
    uploadChunk();
  }

  resetFileInput(): void {
    this.downloadInProgress = false;
    if (this.fileInput && this.fileInput.nativeElement) {
      this.fileInput.nativeElement.value = '';
    }
  }

  invalidFileTypeWarning():void {
    this.userInterfaceService.showCommonErrorMessage('', this.translations['Invalid file type'], this.translations['Close']);
  }

  showSameFileNameWarning(fileName: string): boolean {
    if (this.documentData.dataRoomDocuments.length && this.documentData.dataRoomDocuments.filter(x => x.parentId === this.uploadFileId).some(y => y.name === fileName)) {
      this.resetFileInput();
      this.userInterfaceService.showCommonErrorMessage( this.translations['Upload failed!'],  this.translations['File with the same name already exists.'], this.translations['Upload new file'], 'upload-error-icon-solid');
      return false;
    }
    return true;
  }

  showMaximumStorageWarning(): boolean {
    if (this.documentData.totalFileSize > 5242880) {
      this.resetFileInput();
      this.showPopup({
        messageHeaderKey: this.translations['Exceeds storage capacity'],
        messageContent: this.translations['Kindly note that you have exceeded the maximum storage capacity of your Dataroom (5GB). Please delete existing files and replace.'],
        buttonContent: this.translations['Try again later'],
        buttonContent2: this.translations['Upload new file'],
        closeIconEnable: true,
        buttonContent3: '',
        iconClass: '',
        parentId: '',
        type: FileManagerChangeEventType.MaximumStorageWarning,
        key: '',
      });
      return false;
    }
    return true;
  }

  showMaximumFileSizeWarning(): void {
      this.showPopup({
        messageHeaderKey: this.translations['Exceeds storage capacity'],
        messageContent: this.translations['Kindly note that you have exceeded the maximum file size (25MB).'],
        buttonContent: this.translations['Try again later'],
        buttonContent2: this.translations['Upload new file'],
        closeIconEnable: true,
        buttonContent3: '',
        iconClass: '',
        parentId: '',
        type: FileManagerChangeEventType.MaximumStorageWarning,
        key: '',
      });
  }


  findDocumentByKey(list: BreadcrumbNode[], targetKey: string): BreadcrumbNode | undefined {
    for (const node of list) {
      if (node.key === targetKey) {
        return node;
      }
      if (node.children) {
        const foundInChildren = this.findDocumentByKey(node.children, targetKey);
        if (foundInChildren) {
          return foundInChildren;
        }
      }
    }
    return undefined;
  }

  getFileType(fileType: string): FileManagerDocumentType {
    let type = FileManagerDocumentType.Folder;
    switch (fileType) {
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        type = FileManagerDocumentType.Image;
        break;
      case 'application/pdf':
        type = FileManagerDocumentType.Pdf;
        break;
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        type = FileManagerDocumentType.Excel;
        break;
      case 'text/plain':
        type = FileManagerDocumentType.Text;
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
        type = FileManagerDocumentType.Doc;
        break;
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
      case 'application/vnd.ms-powerpoint':
        type = FileManagerDocumentType.PowerPoint;
        break;
    }
    return type;
  }

  changeSelection(key: string): void {
    changeSelection(key);
  }
}
