import { Injectable } from '@angular/core';
import { PhoneNumberUtil } from 'google-libphonenumber';

import * as moment from 'moment';
import { MonthlyReportConstants } from 'src/app/app.constants';
import { Currency } from 'src/app/modules/shared/models/currency';
declare let showdown: any;

@Injectable({
  providedIn: 'root',
})

export class UtilityService {
  htmlConverter = new showdown.Converter();

  constructor() { }

  public getRegionCode(dialCode: number): string {
    const phoneUtil = PhoneNumberUtil.getInstance();
    return phoneUtil.getRegionCodeForCountryCode(dialCode);
  }

  public concatPhoneNumber(phoneNumber: string, dialCode: string): string {
    return dialCode.replace('+', '').concat('-').concat(phoneNumber.replace(dialCode, ''));
  }

  public formatPhoneNumber(phoneNumber: string): string[] {
    let localNumber = '';
    let dialCode = '';
    if (phoneNumber) {
      localNumber = phoneNumber.includes('-') ? phoneNumber.split('-')[1] : phoneNumber;
      dialCode = phoneNumber.includes('-') ? phoneNumber.split('-')[0] : '';
    }
    return [localNumber, dialCode];
  }

  public getLocalDate(date: Date, languageCode: string, dateFormat = 'MMMM DD, YYYY', capsFirstLetter = false): string {
    return capsFirstLetter ? this.capitalizeFirstLetter(moment(date).locale(languageCode).format(dateFormat).toString()) :
      moment(date).locale(languageCode).format(dateFormat).toString();
  }

  public getLocalTime(date: Date, languageCode: string): string {
    return moment(date).locale(languageCode).format('HH.mm').toString();
  }

  public getMonthYearLabel(date: Date, languageCode: string): string { // February 2024
    const dateFormat = languageCode === 'nb' ? 'MMMM YYYY' : 'MMMM YYYY';
    return moment(date).locale(languageCode).format(dateFormat).toString();
  }

  public getMonthYearShortLabel(date: Date, languageCode: string): string { // Feb 2024
    const dateFormat = languageCode === 'nb' ? 'MMM YYYY' : 'MMM YYYY';
    return moment(date).locale(languageCode).format(dateFormat).toString();
  }

  public formatLocalDate(date: Date, languageCode: string): string {
    const dateFormat = languageCode === 'nb' ? 'DD. MMMM' : 'MMMM DD';
    return moment(date).locale(languageCode).format(dateFormat).toString();
  }

  public formatNumberThousandsAndMillions(value: number, currency: Currency, isThousands = false, languageCode: string = 'en'): string {
    let formattedNumber = 0;
    const sign = value < 0 ? '-' : '';

    const absValue = Math.abs(currency ? value / currency.rate : value);

    if (isThousands) {
      formattedNumber = parseFloat((absValue / 1000).toFixed(2));
    }
    else if (absValue <= 999) {
      formattedNumber = 0;
    }
    // millions
    else {
      formattedNumber = parseFloat((absValue / 1000000).toFixed(2));
    }

    let formattedValue = String(formattedNumber.toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

    let separator = '.';
    if (languageCode === 'nb') {
      separator = ',';
    }

    formattedValue = formattedValue.replace('.', separator);

    const formattedString = value < 0 && formattedValue !== '0' ? '-' + formattedValue : sign + formattedValue;
    return formattedString === '-0' ? formattedValue : formattedString;
  }

  public formatNumberWithSuffixes(value: number, languageCode: string, currency: Currency | null, richFormat = false, signFormat = false): string {
    const suffixes = this.getNumberSuffixes(languageCode);
    let suffix = '';
    let formattedNumber = 0;
    const sign = value < 0 ? '-' : '';

    const absValue = Math.abs(currency ? value / currency.rate : value);
    if (absValue <= 999) {
      formattedNumber = Math.round(absValue);
    }
    // thousands
    else if (absValue >= 1000 && absValue <= 999999) {
      suffix = suffixes[0];
      formattedNumber = Math.round((absValue / 100)) / 10;
    }
    // millions
    else if (absValue >= 1000000 && absValue <= 999999999) {
      suffix = suffixes[1];
      formattedNumber = Math.round((absValue / 100000)) / 10;
    }
    // billions
    else {
      suffix = suffixes[2];
      formattedNumber = Math.round((absValue / 100000000)) / 10;
    }

    if (suffix !== '' && richFormat) {
      suffix = '<span>' + suffix + '</span>';
    }

    const formattedString = signFormat && value < 0 ? '-' + formattedNumber + suffix : sign + formattedNumber + suffix;
    let separator = '.';
    if (languageCode === 'nb') {
      separator = ',';
    }

    return formattedString.replace('.', separator);
  }

  public formatNumber(value: number, currency: Currency | null, languageCode = 'nb'): string {
    let separator = ',';
    let formattedNumber = value;

    if (languageCode === 'nb') {
      separator = ' ';
    }

    if (currency) {
      formattedNumber = formattedNumber / currency.rate;
    }

    const sign = Math.sign(Math.round(formattedNumber));
    let formatString = String(Math.round(Math.abs(formattedNumber))).replace(/(?!^)(?=(?:\d{3})+$)/g, separator);
    if (sign === -1) {
      formatString = '-' + formatString;
    }

    return formatString;
  }

  public getNumberSuffixes(languageCode: string): string[] {
    let suffixes = ['k', 'm', 'b'];
    if (languageCode === 'nb') {
      suffixes = ['t', 'm', 'b'];
    }

    return suffixes;
  }

  public getMonthsFromDateRange(fromDate: Date, toDate: Date): Date[] {
    const dateArray = [];
    const currentDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
    const endDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
    while (currentDate <= endDate) {
      dateArray.push(new Date(currentDate));
      currentDate.setMonth(currentDate.getMonth() + 1);
    }
    return dateArray;
  }

  public getMonthlyReportDate(): Date {
    const currentDate = new Date();
    const dayInMonth = currentDate.getDate();
    currentDate.setDate(1);
    if (MonthlyReportConstants.reportReleaseDate <= dayInMonth) {
      const previousMonth = moment(currentDate).subtract(1, 'months');
      return previousMonth.toDate();
    }
    const lastPreviousMonth = moment(currentDate).subtract(2, 'months');
    return lastPreviousMonth.toDate();
  }

  public formatNumberToDecimal(value: number, languageCode = 'nb'): string {
    let separator = '.';
    if (languageCode === 'nb') {
      separator = ',';
    }

    return value.toString().replace('.', separator);
  }

  public getUserAlias(firstName: string, lastName: string): string {
    let alias = '';
    if (firstName) {
      alias = firstName.substring(0, 1).toUpperCase();
      if (lastName) {
        alias = alias + lastName.substring(0, 1).toUpperCase();
      }
    } else {
      if (lastName) {
        alias = alias + ' ' + lastName.substring(0, 1).toUpperCase();
      }
    }
    return alias;
  }

  public downloadResource(blob: any, fileName: string): void {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public downloadAllFileResources(blob: any, fileName: string): void {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      link.setAttribute('href', blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public formatNumberToTwoDecimalPoints(value: number, languageCode = 'nb'): string {
    let separator = '.';
    if (languageCode === 'nb') {
      separator = ',';
    }

    return Math.abs(value).toFixed(2).toString().replace('.', separator);
  }

  public getLanguageCode(browserLanguage: string): string {
    let languageCode = 'en-US';
    switch (browserLanguage) {
      case 'en':
      case 'en-us':
        languageCode = 'en-US';
        break;
      case 'nb':
      case 'no':
      case 'nb-no':
        languageCode = 'nb-NO';
        break;
    }
    return languageCode;
  }

  public convertMarkdownToHtml(markdown: string): string {
    let html = this.htmlConverter.makeHtml(markdown);
    if (html === undefined) {
      html = '';
    }

    return html;
  }

  public capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  public setRecordPrefixUnit(value: number, unit: string, expectedUnit: string): string {
    if (value === 1) {
      unit = expectedUnit;
    }
    return unit;
  }

  public getQuarterByDate(date: Date): number {
    return Math.floor((date.getMonth() + 3) / 3);
  }

  public getPreviousQuarter(quarter: number): number {
    return quarter === 1 ? 4 : quarter - 1;
  }

  public toCamel(object: any): any {
    let data: any;
    let origKey: any;
    let newKey: any;
    let value: any
    if (object instanceof Array) {
      return object.map((value: any) => {
        if (typeof value === "object") {
          value = this.toCamel(value)
        }
        return value
      })
    } else {
      data = {}
      for (origKey in object) {
        if (object.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = object[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = this.toCamel(value)
          }
          data[newKey] = value
        }
      }
    }
    return data
  }

  public getQuarterFromLatestReportDate(reportDate: Date): any {
    let latestReportDate = this.getMonthlyReportDate();
    reportDate = new Date(reportDate.getFullYear(), reportDate.getMonth(), 1) > new Date(latestReportDate.getFullYear(), latestReportDate.getMonth(), 1)
      ? latestReportDate : reportDate;

    let monthSegment = Math.floor(reportDate.getMonth() / 3);
    let reportYear = reportDate.getFullYear();
    //Latest quarter is set only if all 3 months finance reports are available - check whether current month belongs to latest quarter
    if (latestReportDate.getFullYear() === reportDate.getFullYear() &&
      monthSegment === Math.floor(latestReportDate.getMonth() / 3)) {
      //to get one out of month indexs of 2,5,8,11
      const cutOffMonthIndex = (monthSegment * 3) + 2;

      if (latestReportDate.getMonth() === cutOffMonthIndex) {
        monthSegment++;
      }
      if (monthSegment == 0) {
        monthSegment = monthSegment + 4;
        reportYear--;
      }
    } else {
      monthSegment++;
    }

    return { year: reportYear, quarter: monthSegment };
  }

  getNextQuarter(quarter: number, quarterYear: number): any {
    let nextQuarter = 0;
    if (quarter == 4) {
      nextQuarter = 1;
      quarterYear = quarterYear + 1;
    } else {
      nextQuarter = quarter + 1;
    }
    return { year: quarterYear, quarter: nextQuarter };
  }

  getPreviousQuarterAndYear(quarter: number, quarterYear: number): any {
    let previousQuarter = 0;
    if (quarter == 1) {
      previousQuarter = 4;
      quarterYear = quarterYear - 1;
    } else {
      previousQuarter = quarter - 1;
    }
    return { year: quarterYear, quarter: previousQuarter };
  }

  getLastThreePreviousQuarters(reportMonth: Date): any[] {
    let currentReportMonth = reportMonth;
    let reportsDates: any[] = [];
    const currentPredictionQuarter = this.getQuarterByDate(reportMonth);
    reportsDates.push({ year: currentReportMonth.getFullYear(), quarter: currentPredictionQuarter });
    currentReportMonth.setMonth(currentReportMonth.getMonth() - 3);
    const firstQuarter = this.getQuarterByDate(reportMonth);
    reportsDates.push({ year: currentReportMonth.getFullYear(), quarter: firstQuarter });
    currentReportMonth.setMonth(currentReportMonth.getMonth() - 3);
    const secondQuarter = this.getQuarterByDate(reportMonth);
    reportsDates.push({ year: currentReportMonth.getFullYear(), quarter: secondQuarter });
    currentReportMonth.setMonth(currentReportMonth.getMonth() - 3);
    const thirdQuarter = this.getQuarterByDate(reportMonth);
    reportsDates.push({ year: currentReportMonth.getFullYear(), quarter: thirdQuarter });
    currentReportMonth.setMonth(currentReportMonth.getMonth() - 3);
    const fourthQuarter = this.getQuarterByDate(reportMonth);
    reportsDates.push({ year: currentReportMonth.getFullYear(), quarter: fourthQuarter });
    return reportsDates;
  }

  public getQuarterDates(reportDate: Date): any {
    const quarter = Math.floor((reportDate.getMonth() + 3) / 3);
    let quarterEnd = new Date(reportDate.getFullYear(), reportDate.getMonth() + 2, 1);
    let quarterStart = new Date(reportDate.getFullYear(), reportDate.getMonth(), 1);
    switch (quarter) {
      case 1:
        quarterStart = new Date(reportDate.getFullYear(), 0, 1);
        quarterEnd = new Date(reportDate.getFullYear(), 2, 1);
        break;
      case 2:
        quarterStart = new Date(reportDate.getFullYear(), 3, 1);
        quarterEnd = new Date(reportDate.getFullYear(), 5, 1);
        break;
      case 3:
        quarterStart = new Date(reportDate.getFullYear(), 6, 1);
        quarterEnd = new Date(reportDate.getFullYear(), 8, 1);
        break;
      case 4:
        quarterStart = new Date(reportDate.getFullYear(), 9, 1);
        quarterEnd = new Date(reportDate.getFullYear(), 11, 1);
    }
    return { quarterStartDate: quarterStart, quarterEndDate: quarterEnd };
  }

  public formatNumberWithDecimal(value: number, decimal: number = 1): string {
    const formattedNumber = value.toFixed(decimal);

    if (formattedNumber.endsWith('.0')) {
      return formattedNumber.slice(0, -2);
    }
    return formattedNumber;
  }

  public getFileSize(fileSize: number): string {
    if (fileSize > 1023) {
      return `${this.formatNumberWithDecimal(fileSize / 1024)} MB`;
    }
    else if (fileSize > 1048575) {
      return `${this.formatNumberWithDecimal(fileSize / 1048576)} GB`;
    }
    return `${this.formatNumberWithDecimal(fileSize)} KB`;
  }

  public roundNumbers(num: number): number {
    return Math.round(num * 100) / 100;
  }
}

