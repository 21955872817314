import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { BudgetPopupData } from 'src/app/modules/shared/models/budget-popup-data';

@Component({
  selector: 'app-budget-save-modal',
  templateUrl: './budget-save-modal.component.html',
  styleUrls: ['./budget-save-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetSaveModalComponent implements OnInit {

  @Input() data: BudgetPopupData = new BudgetPopupData();
  @Output() clickChange = new EventEmitter();
  @Output() clickExit = new EventEmitter();
  @Output() clickClose = new EventEmitter();
  @Output() clickUpdateSuccess = new EventEmitter();

  constructor(private appStateService: AppStateService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });
  }

  logout(): void {
    this.clickChange.emit(true);
  }

  exit(): void {
    this.clickExit.emit(true);
  }

  close(): void {
    this.clickClose.emit(true);
  }

  updateSuccess(): void {
    this.clickUpdateSuccess.emit(true);
  }
}
