import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-dot-check-progress',
  templateUrl: './dot-check-progress.component.html',
  styleUrls: ['./dot-check-progress.component.scss']
})
export class DotCheckProgressComponent implements OnInit, OnChanges {

  @Input() dotIndex = 0;
  @Input() dotCount = 0;
  dots: boolean[] = [];
  constructor() { }

  ngOnInit(): void {
    this.setDots();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setDots();
  }

  setDots(): void {
    this.dots = [];
    for (let i = 1; i <= this.dotCount; i++) {
      this.dotIndex >= i ? this.dots.push(true) : this.dots.push(false);
    }
  }
}
