import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import * as moment from 'moment';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { environment } from 'src/environments/environment';
import { MonthlyReportListItem } from 'src/app/modules/shared/models/monthly-report-list-item';

@Injectable({
  providedIn: 'root'
})
export class ReportDataService {

  constructor(private api: CapassaApiService) { }

  getMonthlyReportList(fromDate: Date): Observable<MonthlyReportListItem> {
    const from = moment(fromDate).format('YYYY-MM-DD');
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreports?fromDate=${from}`);
  }

  getCapassaIndexDateList(): Observable<MonthlyReportListItem> {
    return this.api.getJsonApiService().get(`${environment.clientFinanceApi}/v1.0/monthlyreport/capassa-index-date-list`);
  }
}
