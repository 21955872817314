import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateLoaderFactory } from 'src/app/modules/localization/translate-loader-factory';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})

export class LocalizationModule {
  public static forChild(module: string): ModuleWithProviders<LocalizationModule> {
    return TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderFactory.forModule(module),
        deps: [HttpClient]
      },
      isolate: true
    });
  }

  public static forRoot(): ModuleWithProviders<LocalizationModule> {
    return TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: TranslateLoaderFactory.forModule('app'),
        deps: [HttpClient]
      }
    });
  }
}
