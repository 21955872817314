<h1 class="h1" *ngIf="enableCustomHeader">
    {{ headerTitle | translate }}
</h1>
<div class="no-accounts no-accounting-system" *ngIf="hasAccess">
    <div class="no-accounts-img"></div>
    <h2 class="h2">
        {{ 'Add your accounting system'| translate}}
    </h2>
    <p class="p">
        {{'Add your accounting system to Capassa today, so we can present financial information, charts and graphs so
        you can make sound financial decisions.'| translate}}
    </p>
    <button class="btn btn-primary" (click)='showAccountPopup()'>{{ 'Connect now' | translate}}</button>
</div>

<div class="no-accounts no-access-accounting-system" *ngIf="showNoAccess && !showNoIntegrationMsg">
    <div class="no-accounts-img"></div>
    <h2 class="h2">
        {{ 'We are sorry!'| translate}}
    </h2>
    <p class="p">{{'You don’t have access to add the accounting system. Please contact one that has access to add
        accounting
        system to get the most out of Your Digital CFO!'| translate}}</p>
</div>

<div class="no-accounts no-integration" *ngIf="showNoIntegrationMsg">
    <div class="no-accounts-img" *ngIf="!showNoIntegrationFailedMsg">
        <div class="cog-1"></div>
        <div class="cog-2"></div>
    </div>
    <h2 class="h2" *ngIf="!showNoIntegrationFailedMsg && !isReconnected">
        {{ 'Integration is in process'| translate}}
    </h2>
    <h2 class="h2" *ngIf="!showNoIntegrationFailedMsg && isReconnected">
        {{ 'Data synchronization is in process'| translate}}
    </h2>
    <p class="p" *ngIf="!showNoIntegrationFailedMsg && !isReconnected">{{'Having your accounting system connected with
        Capassa will get you access to detailed financial information, so you can make sound financial decisions.'|
        translate}}</p>
    <p class="p" *ngIf="isReconnected">{{'Please wait while we synchronize your data. This process may take a few
        moments to complete.'| translate}}</p>
    <p class="p" *ngIf="showNoIntegrationFailedMsg">{{'No response. Try again'| translate}}</p>
</div>