import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { Constants, RouteLinkConstants } from 'src/app/app.constants';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { UserDataService } from 'src/app/modules/core/user-service/user-data.service';
import { B2CUser, RegistrationUser } from 'src/app/modules/core/user-service/user.model';
import { UserInterfaceService } from 'src/app/modules/shared/services/user-interface.service';
import { LoadingBarStatus, UserAction } from 'src/app/modules/shared/globals';

@Component({
  selector: 'app-profile-image-edit',
  templateUrl: './profile-image-edit.component.html',
  styleUrls: ['./profile-image-edit.component.scss'],
  host: {
    '(document:click)': 'onClick($event)',
  },
})
export class ProfileImageEditComponent implements OnInit, OnDestroy {

  @Input() isMenuCollapsed = false;
  @Input() location = Constants.myProfile;
  @Output() menuCollapsed: EventEmitter<boolean> = new EventEmitter();

  userName = '';
  userAlias = '';
  profileImageUrl = '';
  selectedLanguageLabel: string;
  languageCode: string;
  registrationUser: RegistrationUser;
  registrationB2cUser: B2CUser;
  loggedInB2CUserSubscription: Subscription;
  profileImageUrlSubscription: Subscription;
  registrationUserSubscription: Subscription;
  userActionSubscription: Subscription;
  showProfileImageOptions = false;
  chooseImageOption = false;
  isReadonly = true;
  showResponsiveLeftMenu = false;
  selectedSubMenuItem = '';
  selectedMenuItem = '';

  @ViewChild('imageInput') photoInput: ElementRef;

  constructor(private appStateService: AppStateService, private userService: UserDataService,
    private uiService: UserInterfaceService, private eref: ElementRef, private router: Router
  ) { }

  ngOnInit(): void {
    this.loggedInB2CUserSubscription = this.appStateService.subscribeLoggedInB2CUser().subscribe((user: B2CUser) => {

        if (user && user.id) {
          this.registrationUserSubscription = this.appStateService.subscribeRegistrationUser().subscribe((registrationUser: RegistrationUser) => {
              if (registrationUser && registrationUser.id) {
                this.registrationUser = registrationUser;
                this.registrationB2cUser = user;
                this.setDisplayName(registrationUser.firstName!, registrationUser.lastName!, registrationUser.profileImageUrl);

              } else {
                this.registrationB2cUser = user;
                this.setDisplayName(user.firstName!, user.lastName!);
              }
            });
        }
      });

      this.profileImageUrlSubscription = this.appStateService.subscribeProfileImage().subscribe((profileImageUrl: string) => {

        this.profileImageUrl = profileImageUrl;
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide)
      });

      this.userActionSubscription = this.appStateService.subscribeUserActions().subscribe(actions => {
        this.isReadonly = actions.find(action => action === UserAction.ModifyUserProfile) ? false : true;
      });
  }

  ngOnDestroy(): void {
    if (this.loggedInB2CUserSubscription) { this.loggedInB2CUserSubscription.unsubscribe(); }
    if (this.registrationUserSubscription) { this.registrationUserSubscription.unsubscribe(); }
    if (this.profileImageUrlSubscription) { this.profileImageUrlSubscription.unsubscribe(); }
    if (this.userActionSubscription) { this.userActionSubscription.unsubscribe(); }

  }

  goToLink(): void {
    this.hideResponsiveMenu();
    this.appStateService.publishMenuState(false);
    this.selectedSubMenuItem = RouteLinkConstants.landingPage;
    this.appStateService.publishSearchTextReset('');
    this.router.navigate([RouteLinkConstants.landingPage]);
  }

  hideResponsiveMenu(): void {
    if (this.showResponsiveLeftMenu) {
      this.appStateService.publishMenuState(false);
    }
  }

  setDisplayName(firstName: string, lastName: string, profileImage = ''): void {
    if (profileImage && profileImage !== '') {
      this.profileImageUrl = profileImage;
    }

    if (firstName) {
      this.userName = firstName + ' ';
      this.userAlias = firstName.substring(0, 1).toUpperCase();
    }

    if (lastName) {
      this.userName += lastName;
      this.userAlias += lastName.substring(0, 1).toUpperCase();
    }
  }

  hideLeftMenu(): void {
    this.appStateService.publishMenuState(false);
  }

  uploadProfileImage(imageInput: any): void {

    if (imageInput.files && imageInput.files[0]) {
      const profileImage = imageInput.files[0];
      if (profileImage.type.indexOf('image') === -1) {
        this.uiService.showCommonErrorMessage();
        this.photoInput.nativeElement.value = '';
      } else {
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);

        const reader = new FileReader();
        reader.addEventListener('load', (event: any) => {
          const imageData = event.target.result;
          this.registrationUser.uploadedImage = btoa(imageData);
          this.registrationUser.updatedBy = this.registrationUser.id;
          this.registrationUser.b2CUserId = this.registrationB2cUser.id;
          this.userService.updateRegistrationUser(this.registrationUser).subscribe(

              (user: RegistrationUser) => {
                this.chooseImageOption = false;
                this.registrationUser = user;
                this.profileImageUrl = this.registrationUser.profileImageUrl;
                this.appStateService.publishProfileImage(this.registrationUser.profileImageUrl);

                this.chooseImageOption = false;
              },
              error => {
                this.uiService.showCommonErrorMessage();
              });
        });
        reader.addEventListener('error', () => {
          this.uiService.showCommonErrorMessage();
        });
        reader.readAsBinaryString(profileImage);
      }
    }
  }

  showUserProfileOptions(event: any): void {
    if (this.location === Constants.leftPanel && this.isMenuCollapsed) {
      this.menuCollapsed.emit(false);
    }

    if (this.isReadonly) {
      this.chooseImageOption = false;
      event.preventDefault();
      event.stopPropagation();
      return;
    }

    if (this.chooseImageOption) {
      this.chooseImageOption = false;
      return;
    }

    if (this.profileImageUrl) {
      this.showProfileImageOptions = true;
      event.preventDefault();
      event.stopPropagation();
    }
  }

  hideUserProfileOptions(): void {
    this.showProfileImageOptions = false;
  }

  deleteImage(): void {
    this.appStateService.publishLoadingBarStatus(LoadingBarStatus.ShowProgressBar);
    this.userService.deleteUserProfileImage(this.registrationUser.id).subscribe(() => {
        this.profileImageUrl = '';
        this.appStateService.publishProfileImage(this.profileImageUrl);
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide)
      },
      error => {
        this.uiService.showCommonErrorMessage();
        this.appStateService.publishLoadingBarStatus(LoadingBarStatus.Hide)
      });
  }

  chooseImage(event: any): void {
    this.chooseImageOption = true;
    event.returnValue = true;
    this.photoInput.nativeElement.click();
  }

  onClick = (event: any): void => {
    this.showProfileImageOptions = false;
    this.chooseImageOption = false;
  }
}
