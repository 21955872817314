import { Component, Inject, NgZone, PLATFORM_ID, AfterViewInit, OnDestroy, Input, SimpleChanges } from '@angular/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { ChartBaseComponent } from 'src/app/modules/data-visualization/components/chart-base/chart-base.component';
import { ClusteredDataItem } from 'src/app/modules/data-visualization/models/clustered-data-item';

@Component({
  selector: 'app-clustered-column-chart',
  templateUrl: './../chart-base/chart-base.component.html',
  styleUrls: ['./clustered-column-chart.component.scss']
})
export class ClusteredColumnChartComponent extends ChartBaseComponent implements AfterViewInit, OnDestroy {

  @Input() chartData: ClusteredDataItem[] = [];
  constructor(@Inject(PLATFORM_ID) private platformId: string, private zone: NgZone) {
    super(platformId, zone);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  ngAfterViewInit(): void {
    this.renderChart();
  }

  renderChart(): void {
    this.browserOnly(() => {

      am4core.useTheme(am4themes_animated);
      const chart = am4core.create(this.chartId, am4charts.XYChart);

      chart.paddingRight = this.chartOptions.paddingRight;
      chart.paddingLeft = this.chartOptions.paddingLeft;
      chart.paddingBottom = this.chartOptions.paddingBottom;
      chart.paddingTop = this.chartOptions.paddingTop;
      
      super.chart = chart;
      super.setLocal();
      this.setData(chart);
      this.setChartColors(chart);

      this.setupChartAxes(chart);
      this.createSeries(chart);

      if (this.chartOptions.areaSelection) {
        chart.cursor = new am4charts.XYCursor();
      }
      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.paddingTop = 20;
      chart.legend.position = this.chartOptions.legendPosition as am4charts.LegendPosition;
      chart.legend.labels.template.fill = am4core.color(this.chartOptions.labelColor);
      chart.legend.maxColumns = this.chartOptions.maxColumns;
      chart.legend.useDefaultMarker = true;
      chart.legend.contentAlign = 'center';
      if (this.chartOptions.circularMarkers) {
        const marker: any = chart.legend.markers.template.children.getIndex(0);
        marker.cornerRadius(12, 12, 12, 12);
      }


      if (this.mobileDevice) {
        chart.legend.labels.template.adapter.add('textOutput', function (text) {
          if (text.length > 5) {
            return text.substr(0, 5) + '...';
          }
          return text;
        });
      }

      chart.zoomOutButton.background.fill = chart.colors.getIndex(0);
      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 14;
      markerTemplate.height = 14;
    });
  }

  private setupChartAxes(chart: am4charts.XYChart): void {
    // X axis
    const chartAxis = chart.xAxes.push(new am4charts.DateAxis());
    chartAxis.renderer.minGridDistance = 30;
    chartAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);
    chartAxis.renderer.cellStartLocation = 0.1;
    chartAxis.renderer.cellEndLocation = 0.9;
    if (!this.chartOptions.showGrid) {
      chartAxis.renderer.grid.template.disabled = true;
    }

    if (chartAxis.tooltip) {
      chartAxis.tooltipDateFormat = "MMM";
    }

    const that = this;
    chartAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
      if (text && that.chartOptions.locale === 'nb') {
        text = text.toLowerCase().replace('.', '');
      }

      if (text && text.match(/^[a-zA-Z]+..[0-9]*$/)) {
        text = text.replace(/\d+/g, '');
      }

      return text;
    });

    if (this.mobileDevice) {
      chartAxis.renderer.maxLabelPosition = 0.98;
    }

    // Left Y axis
    const primaryAxis = chart.yAxes.push(new am4charts.ValueAxis());
    primaryAxis.title.text = this.chartOptions.primaryValueAxisTitle;
    if (this.chartOptions.valueAxisTicks) {
      primaryAxis.renderer.labels.template.disabled = true;
    }
    if (!this.chartOptions.showGrid) {
      primaryAxis.renderer.grid.template.disabled = true;
    }

    primaryAxis.renderer.line.strokeOpacity = 0.8;
    primaryAxis.renderer.line.strokeWidth = 0.5;
    primaryAxis.renderer.line.stroke = am4core.color(this.chartOptions.labelColor);
    primaryAxis.renderer.labels.template.fill = am4core.color(this.chartOptions.labelColor);

    primaryAxis.numberFormatter = new am4core.NumberFormatter();
    primaryAxis.numberFormatter.numberFormat = '#a';
    super.setNumberFormatSuffixes(primaryAxis);

    primaryAxis.renderer.labels.template.adapter.add('textOutput', function (text) {
      if (text) {
        text = text.match(/m$/) ? '' : text;
      }
      return text;
    });
  }

  private setChartColors(chart: am4charts.XYChart): void {
    this.chartOptions.columnColors.forEach(color => {
      chart.colors.list.push(am4core.color(color));
    });
  }

  // Create series
  private createSeries(chart: am4charts.XYChart) {
    this.chartOptions.columnFields.forEach((column, i) => {
      // Set up series
      const columnSeries = chart.series.push(new am4charts.ColumnSeries());
      columnSeries.name = column.title;
      columnSeries.dataFields.valueY = column.field;
      columnSeries.dataFields.dateX = this.chartOptions.keyField;
      //columnSeries.sequencedInterpolation = true;

      columnSeries.tooltipText = this.chartOptions.toolTipTextFormat;

      if (columnSeries.tooltip) {
        columnSeries.tooltip.getFillFromObject = false;
        columnSeries.tooltip.background.fill = am4core.color(this.chartOptions.tooltipFillColor);
        columnSeries.tooltip.label.fill = am4core.color(this.chartOptions.tooltipLabelColor);
        columnSeries.tooltipY = am4core.percent(50);
      }

      columnSeries.strokeWidth = 0;
      columnSeries.stacked = this.chartOptions.isStacked;

      let radius = 0;
      if (this.chartOptions.percentageWidth) {
        columnSeries.columns.template.width = this.mobileDevice ? am4core.percent(80) : am4core.percent(90);
        radius =  columnSeries.columns.template.width.value / 2;
      } else {
        columnSeries.columns.template.width = this.mobileDevice ? this.chartOptions.minColumnWidth : this.chartOptions.columnWidth;
        radius = columnSeries.columns.template.width / 2;
      }

      const topRadius = this.getRadiusFunction(radius, true);
      const bottomRadius = this.getRadiusFunction(radius, false);

      columnSeries.columns.template.column.adapter.add("cornerRadiusTopLeft", topRadius);
      columnSeries.columns.template.column.adapter.add("cornerRadiusTopRight", topRadius);
      columnSeries.columns.template.column.adapter.add("cornerRadiusBottomLeft", bottomRadius);
      columnSeries.columns.template.column.adapter.add("cornerRadiusBottomRight", bottomRadius);
    });
  }

  private getRadiusFunction(radiusWidth: number, isTop: boolean) {
    if (isTop) {
      return function (radius: any, target: any) {
        return (target.dataItem && (target.dataItem.valueY < 0)) ? 0 : radiusWidth;
      }
    } else {
      return function (radius: any, target: any) {
        return (target.dataItem && (target.dataItem.valueY > 0)) ? 0 : radiusWidth;
      }
    }
  }

  private setData(chart: am4charts.XYChart): void {
    chart.data = JSON.parse(JSON.stringify(this.chartData)) as ClusteredDataItem[];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.dispose();
      this.renderChart();
    }
  }
}

