import { Injectable } from '@angular/core';

import * as moment from 'moment';

import { LocalStorageService } from 'src/app/modules/core/common-services/local-storage.service';
import { Constants } from 'src/app/app.constants';
import { UserFlow } from 'src/app/modules/shared/globals';
import { Account } from 'src/app/modules/shared/models/account';

@Injectable({
  providedIn: 'root'
})

export class SharedStorageService extends LocalStorageService {

  constructor() {
    super();
  }

  public setUserFlow(userFlow: UserFlow): void {
    this.addItem(Constants.userFlow, userFlow);
  }

  public getUserFlow(): UserFlow {
    const userFlow = this.getItem(Constants.userFlow);
    if (userFlow !== 'null') {
      return userFlow as UserFlow;
    }
    return UserFlow.SignIn;
  }

  public getUserActions(accountId: string): number[] | null {
    const storageKey = Constants.userActions + '-' + accountId;
    return this.getTypedItem<number[]>(storageKey);
  }

  public addUserActions(accountId: string, actions: number[]): void {
    const storageKey = Constants.userActions + '-' + accountId;
    this.addTypedItem(storageKey, actions);
  }

  public clearActionHistory(accountId: string): void {
    const storageKey = Constants.userActions + '-' + accountId;
    this.removeItem(storageKey);
  }

  public addLanguage(language: string): void {
    this.addItem(Constants.selectedLanguage, language);
  }

  public getLanguage(): string {
    return this.getItem(Constants.selectedLanguage);
  }

  public addAccountId(accountId: string): void {
    this.addItem(Constants.accountId, accountId);
  }

  public getAccountId(): string {
    return this.getItem(Constants.accountId)!;
  }

  public addAccount(account: Account): void {
    this.addItem(Constants.account, JSON.stringify(account));
  }

  public getAccount(): Account {
    return JSON.parse(this.getItem(Constants.account)!);
  }

  public addB2CUserRegistrationId(userRegistrationId: string): void {
    this.addItem(Constants.registrationUserId, userRegistrationId);
  }

  public getB2CUserRegistrationId(): string {
    return this.getItem(Constants.registrationUserId);
  }

  public addB2CUserId(b2cUserId: string): void {
    this.addItem(Constants.b2cUserId, b2cUserId);
  }

  public getB2CUserId(): string {
    return this.getItem(Constants.b2cUserId);
  }

  public clearStorage(): void {
    super.clearStorage();
  }

  public removeItem(key: string): void {
    super.removeItem(key);
  }

  public setDeviceTokenData(userId: string, accountId: string, tokenData: string): void {
    const storageKey = Constants.deviceToken + '-' + userId + '-' + accountId;
    this.addItem(storageKey, tokenData);
  }

  public getDeviceTokenData(userId: string, accountId: string): string {
    const storageKey = Constants.deviceToken + '-' + userId + '-' + accountId;
    return this.getItem(storageKey);
  }

  public addInitialB2cRedirection(initialB2cRedirectionStatus: string): void {
    this.addItem(Constants.isInitialB2cRedirection, initialB2cRedirectionStatus);
  }

  public getInitialB2cRedirection(): string {
    return this.getItem(Constants.isInitialB2cRedirection);
  }

  public addBudgetData(data: string, name = Constants.budget): void {
    this.addItem(name, data);
  }

  public getBudgetData(name: string = Constants.budget): string {
    return this.getItem(name);
  }

  public addBudgetAccountData(data: string): void {
    this.addItem(Constants.budgetAccount, data);
  }

  public getBudgetAccountData(): string {
    return this.getItem(Constants.budgetAccount);
  }

  public AddExitingBudgetingStatus(status: boolean): void {
    this.addItem(Constants.exitingBudgeting, status.toString());
  }

  public getExitingBudgetingStatus(): string {
    return this.getItem(Constants.exitingBudgeting);
  }

  public setDashboardData(key: string, date: Date, accountId: string, data: string): void {
    this.addItem(key + '-' + accountId + '-' + (moment(date)).format('MM-YYYY'), data);
  }

  public getDashboardData(key: string, date: Date, accountId: string): string {
    return this.getItem(key + '-' + accountId + '-' + (moment(date)).format('MM-YYYY'));
  }

  public removeDashboardData(accountId: string): void {
    let currentMonth = new Date();
    currentMonth = currentMonth.getDate() < 5 ? new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0) : currentMonth;
    const lastMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 0);
    const dates = [(moment(currentMonth)).format('MM-YYYY'), (moment(lastMonth)).format('MM-YYYY')];
    const dashboards = [Constants.dashboardOverview, Constants.dashboardProfitability, Constants.dashboardBalanceSheet, Constants.dashboardLiquidity];

    dates.forEach(date => {
      dashboards.forEach(dashboard => {
        this.removeItem(dashboard + '-' + accountId + '-' + date);
      });
    });
  }

  public setMonthlySummaryData(accountId: string, date: Date, data: string): void {
    this.addItem(Constants.keyFinancial + '-' + accountId + '-' + (moment(date)).format('MM-YYYY'), data);
  }

  public getMonthlySummaryData(accountId: string, date: Date): string {
    return this.getItem(Constants.keyFinancial + '-' + accountId + '-' + (moment(date)).format('MM-YYYY'));
  }

  public setQuarterlyPredictionData(accountId: string, quarterRange: string, data: string): void {
    this.addItem(Constants.prediction + '-' + accountId + '-' + quarterRange, data);
  }

  public getQuarterlyPredictionData(accountId: string, quarterRange: string): string {
    return this.getItem(Constants.prediction + '-' + accountId + '-' + quarterRange);
  }

  public setLatestMonthlyReportData(accountId: string, languageCode: string, data: string): void {
    this.addItem(Constants.monthlyReport + '-' + languageCode + '-' + accountId, data);
  }

  public getLatestMonthlyReportData(accountId: string, languageCode: string): string {
    return this.getItem(Constants.monthlyReport + '-' + languageCode + '-' + accountId);
  }

  public removeLatestMonthlyReportData(accountId: string): void {
    this.removeItem(Constants.monthlyReport + '-' + accountId);
  }

  public removeMonthlySummaryData(accountId: string): void {
    const keys = this.getStorageKeys(Constants.keyFinancial + '-' + accountId);
    keys.forEach(key => {
      this.removeItem(key);
    });
  }

  public removeQuarterlyPredictionData(accountId: string): void {
    const keys = this.getStorageKeys(Constants.prediction + '-' + accountId);
    keys.forEach(key => {
      this.removeItem(key);
    });
  }

  public removeAnalyticsData(accountId: string): void {
    this.removeLatestMonthlyReportData(accountId);
    this.removeMonthlySummaryData(accountId);
    this.removeQuarterlyPredictionData(accountId);
  }

  private getStorageKeys(prefix: string): string[] {
    const keys: string[] = [];
    for (var i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.includes(prefix)) {
        keys.push(key);
      }
    }

    return keys;
  }

  public addTrialBannerVisibility(visibility: boolean, accountId: string): void {
    const expiryTime = this.addDays(new Date(), 1).getTime();
    const item = {
      value: visibility,
      expiry: expiryTime
    };
    this.addItem(Constants.trialBannerVisibility + '-' + accountId, JSON.stringify(item));
  }

  public getTrialBannerVisibility(accountId: string): string {
    return this.getItem(Constants.trialBannerVisibility + '-' + accountId)!;
  }

  public removeTrialBannerVisibility(accountId: string): void {
    this.removeItem(Constants.trialBannerVisibility + '-' + accountId);
  }

  public addTrialExpiryMessageVisibility(visibility: boolean, accountId: string): void {
    const expiryTime = this.addDays(new Date(), 1).getTime();
    const item = {
      value: visibility,
      expiry: expiryTime
    };
    this.addItem(Constants.trialExpiryMessageVisibility + '-' + accountId, JSON.stringify(item));
  }

  public removeTrialExpiryMessageVisibility(accountId: string): void {
    this.removeItem(Constants.trialExpiryMessageVisibility + '-' + accountId);
  }

  public getTrialExpiryMessageVisibility(accountId: string): string {
    return this.getItem(Constants.trialExpiryMessageVisibility + '-' + accountId)!;
  }

  private addDays(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public addSubscriptionId(subscriptionId: string): void {
    this.addItem(Constants.subscriptionId, subscriptionId);
  }

  public getSubscriptionId(): string {
    return this.getItem(Constants.subscriptionId)!;
  }

  public addRoleId(roleId: string): void {
    this.addItem(Constants.roleId, roleId);
  }

  public getRoleId(): string {
    return this.getItem(Constants.roleId)!;
  }

  public getAccountRole(accountId: string): string {
    return this.getItem(Constants.accountRole + '-' + accountId)!;
  }

  public addAccountRole(accountId: string, roleId: string): void {
    this.addItem(Constants.accountRole + '-' + accountId, roleId);
  }
}
