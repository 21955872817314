import { environment } from 'src/environments/environment';

// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const authConfig = {
    clientId: "995d4839-c454-48c2-bb07-345af183163f",
    apiConfig: {
        scopes: ["https://capassamvpb2c.onmicrosoft.com/api/read"],
        uri: environment.ApiBaseUrl
    },
    b2cPolicies: {
        names: {
            phoneSignUpSignIn: 'B2C_1_PhoneSignIn',
            signUpSignIn: 'B2C_1_signUpAndSignIn',
            resetPassword: 'B2C_1_passwordReset',
            editProfile: 'B2C_1_editProfile',
            phoneSignUp: 'B2C_1_PhoneSignUp',
            signUp: 'B2C_1_signUp'
        },
        authorities: {
            phoneSignUpSignIn: {
                authority: 'https://capassamvpb2c.b2clogin.com/capassamvpb2c.onmicrosoft.com/B2C_1_PhoneSignIn'
            },
            signUpSignIn: {
                authority: 'https://capassamvpb2c.b2clogin.com/capassamvpb2c.onmicrosoft.com/B2C_1_signUpAndSignIn'
            },
            resetPassword: {
                authority: 'https://capassamvpb2c.b2clogin.com/capassamvpb2c.onmicrosoft.com/B2C_1_passwordReset'
            },
            editProfile: {
                authority: 'https://capassamvpb2c.b2clogin.com/capassamvpb2c.onmicrosoft.com/B2C_1_editProfile'
            },
            phoneSignUp: {
                authority: 'https://capassamvpb2c.b2clogin.com/capassamvpb2c.onmicrosoft.com/B2C_1_PhoneSignUp'
            },
            signUp: {
                authority: 'https://capassamvpb2c.b2clogin.com/capassamvpb2c.onmicrosoft.com/B2C_1_signUp'
            }
        },
        authorityDomain: "capassamvpb2c.b2clogin.com"
    }
};

