import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AccountingSystem } from 'src/app/modules/shared/models/accounting-system';
import { Agreement } from 'src/app/modules/shared/models/agreement'
import { AccountingSystemNavigationService } from 'src/app/modules/shared/services/accounting-system-navigation.service';
import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';

@Component({
  selector: 'app-lost-connection-pop-up',
  templateUrl: './lost-connection-pop-up.component.html',
  styleUrls: ['./lost-connection-pop-up.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LostConnectionPopUpComponent implements OnInit, OnDestroy {
  @Input() messageHeaderKey: string = '';
  @Input() messageContent: string = '';
  @Input() buttonContent1: string = '';
  @Input() buttonContent2: string = '';
  @Input() iSButton1Enable: boolean = true;
  @Input() iconEnable: boolean = false;
  @Input() iconClass: string = '';
  @Input() accountingSystem: AccountingSystem;
  @Output() onClickChange = new EventEmitter();
  showLoader = false;
  accountingTermCondition: Agreement;
  selectedLanguageSubscription: Subscription;

  constructor(private appStateService: AppStateService, private translate: TranslateService,
    private accountingSystemNavigationService: AccountingSystemNavigationService, private router: Router) { }

  ngOnInit(): void {
    this.selectedLanguageSubscription = this.appStateService.subscribeSelectedLanguage().subscribe((language: string) => {
      this.translate.setDefaultLang(language);
      this.translate.use(language);
    });

  }

  ngOnDestroy(): void {
    if (this.selectedLanguageSubscription) { this.selectedLanguageSubscription.unsubscribe(); }
  }

  skip(): void {
    this.onClickChange.emit(true);
  }

  reconnect(): void {
    this.onClickChange.emit(true);
    this.accountingSystemNavigationService.integrateAccountingSystem(this.accountingSystem);
  }
}
