import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from "@azure/msal-browser";
import {MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';;

import { authConfig } from "src/app/modules/core/auth/auth.config";
import { environment } from "src/environments/environment";

export function loggerCallback(logLevel: LogLevel, message: string) {
    console.log(message);
  }
  
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: authConfig.clientId,
      authority: authConfig.b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: `${environment.customerWebPortalUrl}/home`,
      postLogoutRedirectUri: environment.capassaHomePage,
      knownAuthorities: [authConfig.b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Error,
        piiLoggingEnabled: false
      }
    }
  });
}
  
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(authConfig.apiConfig.uri, authConfig.apiConfig.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
  
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...authConfig.apiConfig.scopes],
    },
    loginFailedRoute: '/login-failed'
  };
}
  