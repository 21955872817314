<div class="capassa-modal capassa-modal-common">
  <div class="capassa-modal-content">
    <div class="block-header">
      <!-- <div [ngClass]="data.iconClass" *ngIf="data.closeIconEnable"></div>     -->
      <h2 class="h2">{{ data.messageHeaderKey | translate }}</h2>
      <a *ngIf="data.closeIconEnable" class="link link-close" (click)="close()"></a>
    </div>

    <div class="block-body">
      <p class="app-message success-message"
        *ngIf="!data.buttonContent3 && data.type !== changeEventType.Rename && data.type !== changeEventType.EditDataRoom &&
      data.type !== changeEventType.AddFolder && data.type !== changeEventType.ChooseCapassaFile && data.type !== changeEventType.MaximumStorageWarning">
        {{ data.messageContent |translate }}
      </p>

      <form *ngIf="data.type !== changeEventType.EditDataRoom" [formGroup]="form" (ngSubmit)="onFormSubmit()">
        <div *ngIf="data.type === changeEventType.Rename || data.type === changeEventType.AddFolder"
          class="data-room-pop-up-form">
          <input type="text" formControlName="title" placeholder="{{'Enter your folder name' | translate}}" class="form-control"
            [ngClass]="{ 'is-invalid': submitted && formFields.title.errors }" />
          <div *ngIf="submitted && formFields.title.errors" class="invalid-feedback error-m">
            <span>{{'Name field is mandatory' | translate }}</span>
          </div>
        </div>

        <div *ngIf="data.type === changeEventType.ChooseCapassaFile" class="data-room-pop-choose-file">
          <ul>
            <li><a class="report-type" href="javascript:void(0);"
                (click)="selectOption(fileManagerReportType.PnLReport)">{{ 'Profit and loss' |
                translate }}</a></li>
            <li><a class="report-type" href="javascript:void(0);"
                (click)="selectOption(fileManagerReportType.BalanceReport)">{{ 'Balance sheet' |
                translate }}</a></li>
            <li><a class="report-type" href="javascript:void(0);"
                (click)="selectOption(fileManagerReportType.PnLBudget)">{{ 'P&L Budget' |
                translate }}</a></li>
            <li><a class="report-type" href="javascript:void(0);"
                (click)="selectOption(fileManagerReportType.LiquidityBudget)">{{
                'Liquidity Budget' | translate }}</a></li>
          </ul>
        </div>

        <div *ngIf="data.type === changeEventType.MaximumStorageWarning" class="data-room-pop-maximum-storage-warning">
          <div><span>{{ data.messageContent |translate }}</span></div>
        </div>

        <button *ngIf="data.buttonContent2" class="btn btn-primary" (click)="exit()">
          <span style="font-size:16px;">{{ data.buttonContent2 | translate }}</span>
        </button>
        <button *ngIf="data.type === changeEventType.Rename || data.type === changeEventType.AddFolder"
          class="btn btn-primary" (click)="onFormSubmit()">
          <span style="font-size:16px;">{{ data.buttonContent | translate }}</span>
        </button>

        <button *ngIf="data.type === changeEventType.DeleteDataRoom || data.type === changeEventType.Delete"
                class="btn btn-secondary" (click)="save()">
          <span style="font-size:16px;">{{ data.buttonContent | translate }}</span>
        </button>

        <button *ngIf="data.buttonContent3" class="btn btn-primary" (click)="updateSuccess()">
          <span style="font-size:16px;">{{ data.buttonContent3 | translate }}</span>
        </button>
      </form>

      <div *ngIf="data.type === changeEventType.EditDataRoom" [formGroup]="editForm" (ngSubmit)="onEditFormSubmit()">
        <input type="text" formControlName="dataRoomTitle" placeholder="{{'Enter your data room name' | translate}}" class="form-control"
               [ngClass]="{ 'is-invalid': submitted && editFormFields.dataRoomTitle.errors }" />
        <div *ngIf="submitted && editFormFields.dataRoomTitle.errors" class="invalid-feedback error-m">
          <span>{{'Name field is mandatory' | translate }}</span>
        </div>
        <div class="block-email-body">
          <textarea formControlName="dataRoomDescription" placeholder="{{'Description' | translate}}" class="form-control" class="email-text" cols="30" rows="4"></textarea>
        </div>

        <button class="btn btn-primary" (click)="onEditFormSubmit()">
          <span style="font-size:16px;">{{ data.buttonContent | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
