import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';

import { AppStateService } from 'src/app/modules/core/common-services/app-state.service';
import { Account } from 'src/app/modules/shared/models/account';

@Component({
  selector: 'app-capassa-base',
  templateUrl: './capassa-base.component.html',
  styleUrls: ['./capassa-base.component.scss']
})
export class CapassaBaseComponent implements OnInit, OnDestroy {

  private activeAccountSubscription: Subscription;

  constructor(public router: Router, public appStateService: AppStateService) { }

  ngOnInit(): void {
    this.activeAccountSubscription = this.appStateService.subscribeActiveCompanyAccount().subscribe((account: Account) => {
      this.reloadCurrentRoute();
      this.updateActiveAccount(account);
    });

  }
  reloadCurrentRoute(): void {
    this.router.navigateByUrl(this.router.url);
  }

  updateActiveAccount(account: Account): void { }

  ngOnDestroy(): void {
    if (this.activeAccountSubscription) { this.activeAccountSubscription.unsubscribe(); };
  }
}
