import { RegistrationUser } from 'src/app/modules/core/user-service/user.model';

export class DataRoomData {
  id: string;
  capassaAccountId: string;
  name: string;
  description: string;
  createdByFirstName: string;
  createdByLastName: string;
  createdBy: string;
  modifiedBy: string;
  createdOn: string;
  createdDate: string;
  modifiedOn: string;
  isArchived: boolean;
  createdByUser: RegistrationUser;
  profileImageUrl: string;
  totalFileSize: number;
  fileSizeLabel: string;
}
