import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { CapassaApiService } from 'src/app/modules/core/api-service/capassa-api.service';
import { environment } from 'src/environments/environment';
import { FileManagerModel, FileManagerDocumentResponse } from 'src/app/modules/shared/models/capassa-file-manager.model';

@Injectable({
  providedIn: 'root'
})
export class CapassaFileManagerDataService {

  constructor(private api: CapassaApiService) { }

  getDocumentList(id: string): Observable<FileManagerDocumentResponse> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/data-room/documents/${id}`);
  }

  getDocumentData(): Observable<FileManagerDocumentResponse> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/data-room/documents`);
  }

  createDocumentData(documentData: FileManagerModel): Observable<FileManagerModel> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/data-room/documents`, documentData);
  }

  updateDocumentData(documentData: FileManagerModel): Observable<FileManagerModel> {
    return this.api.getJsonApiService().put(`${environment.RegistrationApi}/v1.0/data-room/documents`, documentData);
  }

  uploadDocument(data: FormData): Observable<FileManagerModel> {
    return this.api.getJsonApiService().post(`${environment.RegistrationApi}/v1.0/data-room/upload-document`, data);
  }

  deleteDocument(id: string): Observable<number> {
    return this.api.getJsonApiService().delete(`${environment.RegistrationApi}/v1.0/data-room/documents/${id}`);
  }

  downloadDocument(id: string): Observable<any> {
    return this.api.getJsonApiService().get(`${environment.RegistrationApi}/v1.0/data-room/download-document/${id}`);
  }
}

