import { Injectable } from '@angular/core';

export abstract class LocalStorageService {

  constructor() { }

  protected addItem(key: string, value: string): void {
    localStorage.setItem(key, value);
  }

  protected getItem(key: string): string {
    return String(localStorage.getItem(key));
  }

  protected removeItem(key: string): void {
    localStorage.removeItem(key);
  }

  protected clearStorage(): void {
    localStorage.clear();
  }

  protected getTypedItem<T>(key: string): T | null {
    const item = this.getItem(key);
    if (item !== null || item !== '') {
      const storedObject: T = JSON.parse(item);
      return storedObject;
    }
    return null;
  }

  protected addTypedItem(key: string, object: any): void {
    this.addItem(key, JSON.stringify(object));
  }

}
